import { Accessor, Component, Context, Setter, Show, createEffect, createSignal, on, onCleanup, onMount, useContext } from "solid-js";
import { Dynamic } from "solid-js/web";
import { HookContext } from "~/context/HookContext";

export type CombineEventsIfArr<
	T extends keyof WindowEventMap | (keyof WindowEventMap)[]
> = T extends (infer U)[]
	? U extends keyof WindowEventMap
		? WindowEventMap[U]
		: never
	: T extends keyof WindowEventMap
		? WindowEventMap[T]
		: never

let resizeListeners: ((e: Event) => void)[] = []

window.addEventListener("resize", (e) => {
	for (let i = 0; i < resizeListeners.length; i++) {
		resizeListeners[i](e)
	}
})

export const createEventListener = <
	E extends keyof WindowEventMap | (keyof WindowEventMap)[]
>(
	ref: Element | undefined | Window,
	events: E,
	callback: (e: CombineEventsIfArr<E>) => void,
	options?: boolean | AddEventListenerOptions
) => {
	const addEvent = (event: keyof WindowEventMap, callback: (e: CombineEventsIfArr<E>) => void) => {
		if (ref !== window || event !== "resize") {
			ref?.addEventListener(event, callback as any, options)
		} else {
			resizeListeners.push(callback as any)
		}
	}

	const removeEvent = (event: keyof WindowEventMap, callback: (e: CombineEventsIfArr<E>) => void) => {
		if (ref !== window || event !== "resize") {
			ref?.removeEventListener(event, callback as any, options)
		} else {
			const index = resizeListeners.indexOf(callback as any);
			if (index < 0) return;
			resizeListeners.splice(callback as any, 1)
		}
		
	}

	createEffect(() => {
		if (ref === undefined) return;
		let eventArr = Array.isArray(events) ? events : [events]
		eventArr.forEach((event) => {
			addEvent(event, callback)
			ref?.addEventListener(event, callback as any, options)
		})
		onCleanup(() => {
			eventArr.forEach((event) => {
				removeEvent(event, callback as any)
			})
		})
	})
}

export const onElementResize = (ref: Element | null | (Element | null)[], callback: () => void) => {
	const elArr = Array.isArray(ref) ? ref : [ref]

	const resizeObserver = new ResizeObserver(() => callback())
	elArr.forEach((el) => el && resizeObserver.observe(el))
	onCleanup(() => resizeObserver.disconnect())
}

export const createInterval = (callback: Function, timeMS: number) => {
	let interval = setInterval(callback, timeMS)
	let cleaned = false
	onCleanup(() => {
		cleaned = true
		clearInterval(interval)
	})

	const reset = () => {
		clearInterval(interval)
		if (cleaned) return;
		interval = setInterval(callback, timeMS)
	}

	return { reset }
}

export const useCurrentDate = () => {
	const [ date, setDate ] = createSignal(new Date());

	createInterval(() => {
		setDate(new Date())
	}, 500)

	return date;
}

export const useCSSVariable = (
	name: string,
	value: Accessor<string>,
	parent?: Accessor<HTMLElement | null>
) => {
	createEffect(() => {
		const parentEl = parent ? parent() : document.documentElement
		if (!parentEl) return
		parentEl.style.setProperty(`--${name}`, value())
		onCleanup(() => {
			parentEl.style.removeProperty(`--${name}`)
		})
	})
}

export const onElementIntersectChange = (
	ref: Element | null | (Element | null)[] | (() => Element | null | (Element | null)[]),
	callback: IntersectionObserverCallback,
	options?: IntersectionObserverInit
) => {
	createEffect(() => {
		const el = typeof(ref) === "function" ? ref() : ref
		const elArr = Array.isArray(el) ? el : [el]

		const intersectionObserver = new IntersectionObserver(callback, options)
		elArr.forEach((el) => el && intersectionObserver.observe(el))
		onCleanup(() => intersectionObserver.disconnect())
	})
}

export const onElementScrolledAway = (ref: Element, callbackArg: Function) => {
	const [ called, setCalled ] = createSignal(true)

	const callback = (entries: IntersectionObserverEntry[]) => {
		if (entries[0].isIntersecting) {
			setCalled(false)
		} else if (!called()) {
			callbackArg()
			setCalled(true);
		}
	}

	const intersectionObserver = new IntersectionObserver(callback, {
		root: null,
		rootMargin: "0px",
		threshold: 0
	})
	intersectionObserver.observe(ref)
	onCleanup(() => intersectionObserver.disconnect())
}

export const useOrientation = (): Accessor<"portrait" | "landscape"> => {
	const getOrientation = () => {
		if (Math.abs(window.innerWidth - window.innerHeight) / window.innerWidth < 0.2) return "landscape"
		return window.matchMedia("(orientation: portrait)").matches
			? "portrait"
			: "landscape"
	}

	const [ orientation, setOrientation ] = createSignal<"portrait" | "landscape">(getOrientation())

	createEventListener(window, "resize", () => setOrientation(getOrientation()))

	return orientation
}

export interface IUseBoundsOptions {
	onElementResize: boolean,
	dependencies: Accessor<unknown[]>
}

export const onPropertyChange = <E extends Element>(
	containerEl: E | (() => E | null),
	property: string,
	callback: (el: E) => void
) => {
	const observe = (el: E) => {
		const observer = new MutationObserver(() => {
			callback(el)
		})
		observer.observe(el, { attributes: true, attributeFilter: [property] })
		onCleanup(() => observer.disconnect())
	}

	if (typeof(containerEl) === "function") {
		createEffect(() => {
			const el = containerEl()
			if (!el) return
			observe(el)
		})
	} else observe(containerEl)
}

export const useLazyComponent = <P,Key extends string = "default">(
	promise: () => Promise<{[key in Key]: Component<P>}>,
	key: Key,
	callback?: () => void
) => {
	const [ Comp, setComp ] = createSignal<Component<P> | null>(null)

	onMount(async () => {
		const newComp = (await promise())[key]
		if (callback) callback()
		setComp(() => newComp)
	})

	return (props: P) => (
		<Show when={Comp()}>
			<Dynamic component={Comp()!} {...props} />
		</Show>
	)
}

export const useTimeout = (callback: () => void, timeoutMs: number) => {
	createEffect(() => {
		const timeout = setTimeout(callback, timeoutMs)
		onCleanup(() => clearTimeout(timeout))
	})
}

export const useIsMobile = (): Accessor<boolean> => {
	const [ state ] = useContext(HookContext)
	return () => state.isMobile
}

export const useWindowWidth = (): Accessor<number> => {
	const [ state ] = useContext(HookContext)
	return () => state.windowWidth
}

export interface IUseBoundsOptions {
	onElementResize: boolean,
	onScroll?: boolean,
	dependencies: Accessor<unknown[]>
}

export const useBounds = (
	ref: Accessor<Element | null | undefined> | Element | null | undefined,
	_options?: Partial<IUseBoundsOptions> | Accessor<Partial<IUseBoundsOptions>>
): Accessor<DOMRect> => {
	const options: Accessor<IUseBoundsOptions> = () => {
		const propOptions = typeof _options === "function" ? _options() : _options
		return {
			onElementResize: true,
			dependencies: () => [],
			...propOptions
		}
	}

	const [ bounds, setBounds ] = createSignal(new DOMRect())

	const calculateBounds =  () => {
		const el = typeof(ref) === "function" ? ref() : ref
		if (!el || !document.contains(el)) return;
		const bounds = el.getBoundingClientRect()
		setBounds(bounds)
	}

	createEffect(() => {
		const el = typeof(ref) === "function" ? ref() : ref
		if (!el) return;
		calculateBounds()
		if (options().onElementResize) onElementResize(el, calculateBounds)
		createEventListener(window, "resize", calculateBounds)
		if (options().onScroll) createEventListener(window, "scroll", calculateBounds)
	})

	createEffect(on(
		() => options().dependencies(),
		calculateBounds
	))

	return bounds
}

export const useSize = (
	ref: Accessor<Element | null | undefined> | Element | null | undefined
): Accessor<{width: number, height: number}> => {
	const [ size, setSize ] = createSignal({width: 0, height: 0})

	const calculateBounds =  () => {
		const el = typeof(ref) === "function" ? ref() : ref
		if (!el || !document.contains(el)) return;
		const bounds = el.getBoundingClientRect()
		setSize({width: bounds.width, height: bounds.height})
	}

	createEffect(() => {
		const el = typeof(ref) === "function" ? ref() : ref
		if (!el) return;
		calculateBounds()
		onElementResize(el, calculateBounds)
	})

	return size
}

export const useClickAway = (
	ref: HTMLElement | null | (() => HTMLElement | null),
	callback: (e: MouseEvent) => void,
	ignoreRefs?: () => (HTMLElement | null)[]
) => {
	createEffect(() => {
		const el = typeof(ref) === "function" ? ref() : ref
		if (!el) return;
		createEventListener(window, "click", (e) => {
			if (!el.contains(e.target as Node) && !el.isEqualNode(e.target as Node)) {
				if (ignoreRefs?.()?.some((ref) => ref?.contains(e.target as Node) || ref?.isEqualNode(e.target as Node))) return;
				callback(e)
			}
		})
	})
}

export const createRandoms = (amount: number, min: number, max: number): Accessor<number[]> => {
	const [ randoms, setRandoms ] = createSignal<number[]>([])

	createEffect(() => {
		setRandoms((oldRands) => {
			let diff = amount - oldRands.length;
			if (diff < 0) return [...oldRands].splice(0, oldRands.length - diff)
			let newRands = [...oldRands]
			for (let i = 0; i < diff; i++) {
				newRands.push(Math.random() * (max - min) + min)
			}
			return newRands;
		})
	})

	return randoms;
}

export const createRandom = (min: number, max: number): Accessor<number> => {
	let rands = createRandoms(1, min, max)
	return () => rands()[0]
}

export const useScrolled = () => {
	const [ scrolled, setScrolled ] = createSignal(false)

	createEffect(() => {
		const onScroll = () => setScrolled(window.scrollY > 0)
		onScroll()
		createEventListener(window, "scroll", onScroll)
	})

	return scrolled
}