import { JSX, mergeProps, splitProps } from "solid-js"
import type { ChildrenType, Component, ComponentType, GetPropsFromComponent } from "~/types"

import clsx from "clsx"

import classes from "./Button.module.css"
import { Dynamic } from "solid-js/web"


export type IButtonProps<
	Comp extends ComponentType
> = GetPropsFromComponent<Comp> & {
	component?: Comp,
	children?: ChildrenType,
	color?: "primary" | "secondary" | "tertiary" | "white",
	textColor?: "primary" | "secondary" | "tertiary" | "default"
	size?: "small" | "default" | "large" | "square",
	variant?: "styless" | "contained"
}

const Button = <Comp extends ComponentType = "button">(__props: IButtonProps<Comp>) => {
	const _props = mergeProps({
		color: "primary",
		size: "default",
		variant: "contained",
		textColor: "default"
	}, __props)

	const [ props, others ] = splitProps(_props, [
		"component", "children", "color",
		"size", "variant", "textColor"
	])

	return (
		<Dynamic
			component={props.component ?? "button"}
			{...others}
			class={clsx(
				classes["button"],
				others.class,
				classes[`color-${props.color}`],
				classes[`text-color-${props.textColor}`],
				classes[`size-${props.size}`],
				classes[`variant-${props.variant}`]
			)}
			type={others.type ?? "button"}
		>
			{props.children}
		</Dynamic>
	)
}

export default Button