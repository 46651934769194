import { Component } from "~/types"
import { HookContextWrapper } from "./HookContext"
import { Web3ContextWrapper } from "./Web3Context"
import { UserContextWrapper } from "./UserContext"
import { ShareContextWrapper } from "./ShareContext"
import { StageContextWrapper } from "./StageContext"
import { PriceContextWrapper } from "./PriceContext"
import { BuyContextWrapper } from "./BuyContext"
import { NavContextWrapper } from "./NavContext"
import { SettingsContextWrapper } from "./SettingsContext"

export const GlobalWrapper: Component = (props) => {
	return (
		<HookContextWrapper>
			<Web3ContextWrapper>
				<BuyContextWrapper>
					<NavContextWrapper>
						<SettingsContextWrapper>
							<ShareContextWrapper>
								<PriceContextWrapper>
									<UserContextWrapper>
										<StageContextWrapper>
											{props.children}
										</StageContextWrapper>
									</UserContextWrapper>
								</PriceContextWrapper>
							</ShareContextWrapper>
						</SettingsContextWrapper>
					</NavContextWrapper>
				</BuyContextWrapper>
			</Web3ContextWrapper>
		</HookContextWrapper>
	)
}