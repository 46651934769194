import { createContext, onMount } from "solid-js"
import type { API, Component } from "~/types"
import { createStore } from "solid-js/store"

import * as api from "~/util/api.util"

export type IStageContextValue = {
	stage: API.Stage | null
}

export interface IStageContextFunctions {

}

const defaultValue: IStageContextValue = {
	stage: null
}

const defaultFunctions: IStageContextFunctions = {}

export const StageContext = createContext<[IStageContextValue, IStageContextFunctions]>([
	defaultValue, defaultFunctions
])

export const StageContextWrapper: Component = (props) => {
	const [ state, setState ] = createStore<IStageContextValue>(defaultValue)

	const StageValue: [IStageContextValue, IStageContextFunctions] = [
		state, defaultFunctions
	]

	onMount(async () => {
		const stage = await api.getActiveStage()
		setState("stage", stage)
	})

	return (
		<StageContext.Provider value={StageValue}>
			{props.children}
		</StageContext.Provider>
	)
}