import { For, JSX, createSignal, splitProps } from "solid-js"
import type { Component } from "~/types"

import clsx from "clsx"

import classes from "./Footer.module.css"
import { Link } from "@solidjs/router"
import OptimizedImage from "../OptimizedImage/OptimizedImage"
import { useBounds, useCSSVariable } from "~/util"


export type IFooterProps = JSX.HTMLAttributes<HTMLElement> & {

}

const imgList: {url: string, imgSrc: string, label: string, width: number, height: number}[] = [
	{
		url: "https://cloudflare.com",
		imgSrc: "/img/cloudflare-logo.png",
		label: "Cloudflare",
		width: 402,
		height: 62
	}, {
		url: "https://railway.app",
		imgSrc: "/img/railway-logo.png",
		label: "Railway",
		width: 351,
		height: 81
	}, {
		url: "https://www.analyticsinsight.net/",
		imgSrc: "/img/analytics-insight-logo.png",
		label: "Analytics Insight",
		width: 1150,
		height: 152
	}, {
		url: "https://coinsniper.net/coin/51120",
		imgSrc: "/img/coin-sniper-logo.png",
		label: "Coinsniper",
		width: 288,
		height: 64
	},
]

const Footer: Component<IFooterProps> = (_props) => {
	const [ props, others ] = splitProps(_props, [])
	const [ footerRef, setFooterRef ] = createSignal<HTMLElement | null>()
	const bounds = useBounds(footerRef)
	useCSSVariable("footer-height", () => bounds().height + "px")

	return (
		<footer
			{...others}
			class={clsx(classes["footer"], others.class)}
			ref={(el) => setFooterRef(el)}
		>
			<div class={classes["footer-inner"]}>
				<p class={classes["footer-text"]}>
				</p>
				<div class={classes["footer-imgs"]}>
					<For each={imgList}>
						{(item) => (
							<Link
								href={item.url}
								class={classes["img-link"]}
								target="_blank"
								rel="noreferrer"
							>
								<OptimizedImage
									src={item.imgSrc}
									alt={item.label}
									width={item.width}
									height={item.height}
								/>
							</Link>
						)}
					</For>
				</div>
			</div>
		</footer>
	)
}

export default Footer