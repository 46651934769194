import type { Component } from "~/types"

import classes from "./HeaderSection.module.css"
import Section from "~/components/Section/Section"
import OptimizedImage from "~/components/OptimizedImage/OptimizedImage"
import { Show } from "solid-js"
import { useIsMobile } from "~/util"

const HeaderSection: Component = () => {
	const isMobile = useIsMobile()

	return (
		<Section class={classes["section"]} innerClass={classes["section-inner"]}>
			<div class={classes["left"]}>
				<OptimizedImage
					src="/img/logo.png"
					alt="Doge Uprising"
					class={classes["logo"]}
					width={506}
					height={152}
					applyAspect
				/>
				<Show when={!isMobile()}>
					<p class={classes["left-label"]}>Legal Information</p>
				</Show>
			</div>
			<OptimizedImage
				src="/img/face-zoom.jpg"
				alt="Zoomed in face with japanese characters"
				class={classes["right"]}
				width={798}
				height={445}
				applyAspect
			/>
			<Show when={isMobile()}>
				<p class={classes["left-label"]}>Legal Information</p>
			</Show>
		</Section>
	)
}

export default HeaderSection