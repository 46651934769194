import { createContext, createEffect } from "solid-js"
import type { Component } from "~/types"
import { createStore } from "solid-js/store"
import ShareModal from "~/components/ShareModal/ShareModal"
import { isMobileDevice } from "~/util"

export interface ShareItem {
	shareText: string | ({text: string, lowercase: boolean}[]),
	shareUrl?: string,
	title: string,
	subheading?: string,
	copyText?: string,
	copyLabel?: string
}

export type IShareContextValue = {
	currentShare: ShareItem | null
	shareModalOpen: boolean
}

export interface IShareContextFunctions {
	setCurrentShare: (share: ShareItem) => void
	setShareModalOpen: (open: boolean) => void,
	share: (share: ShareItem) => void
}

const defaultValue: IShareContextValue = {
	currentShare: null,
	shareModalOpen: false
}

export const getShareText = (share: ShareItem): string => {
	if (typeof share.shareText === "string") return share.shareText
	return share.shareText.map(({text}) => text).join("")
}

const defaultFunctions: IShareContextFunctions = {
	setCurrentShare: () => {},
	setShareModalOpen: () => {},
	share: () => {}
}

export const ShareContext = createContext<[IShareContextValue, IShareContextFunctions]>([
	defaultValue, defaultFunctions
])

export const ShareContextWrapper: Component = (props) => {
	const [ state, setState ] = createStore<IShareContextValue>(defaultValue)

	const shareDate = (item: ShareItem) => {
		if (navigator.canShare?.(item) && isMobileDevice()) {
			navigator.share({
				text: getShareText(item),
				url: item.shareUrl
			})
		}
		setState("currentShare", item)
		setState("shareModalOpen", true)
	}

	const ShareValue: [IShareContextValue, IShareContextFunctions] = [
		state, {
			setCurrentShare: (share) => setState("currentShare", share),
			setShareModalOpen: (open) => setState("shareModalOpen", open),
			share: shareDate
		}
	]

	return (
		<ShareContext.Provider value={ShareValue}>
			<ShareModal
				onClose={() => setState("shareModalOpen", false)}
				open={state.shareModalOpen}
			/>
			{props.children}
		</ShareContext.Provider>
	)
}