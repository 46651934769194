import { For, JSX, createContext, createEffect, createSignal, onMount, splitProps, useContext } from "solid-js"
import type { Component } from "~/types"

import type { KeenSliderInstance } from "keen-slider"
import clsx from "clsx"

import classes from "./Carousel.module.css"
import { createStore } from "solid-js/store"
import { createEventListener, createInterval, minMax, onPropertyChange } from "~/util"

import LeftArrow from "~/svg/arrow-left.svg?component-solid"
import RightArrow from "~/svg/arrow-right.svg?component-solid"

import { Slider } from "solid-slider"
import Button from "../Button/Button"

export interface ICarouselClasses {
	root?: string,
	childContainer?: string,
	controls?: string
}

export type ICarouselProps = JSX.HTMLAttributes<HTMLDivElement> & {
	classes?: ICarouselClasses,
	selectedSlide?: number,
	onSlideChange?: (slide: number) => void,
	autoPlay?: boolean,
	autoPlayDurationMs?: number,
	min?: number,
	max?: number,
	spacing?: number,
	slides?: number
}

export interface ICarouselContext {
	slider: KeenSliderInstance | null,
	currentSlide: number,
}

export interface ICarouselFuncs {
	registerSlide: () => number
}

const CarouselContext = createContext<[ICarouselContext, ICarouselFuncs]>([
	{slider: null,currentSlide: 0},
	{registerSlide: () => 0}
])

const Carousel: Component<ICarouselProps> = (_props) => {
	const [ props, others ] = splitProps(_props, [
		"children", "selectedSlide", "onSlideChange",
		"autoPlay", "autoPlayDurationMs", "min", "max",
		"classes", "spacing", "slides"
	])

	const numSlides = () => props.slides ?? (Array.isArray(props.children)
		? props.children.length
		: !props.children
			? 0 : 1)

	const [ slider, setSlider ] = createSignal<KeenSliderInstance | null>(null)
	const [ currentSlide, setCurrentSlide ] = createSignal(props.selectedSlide ?? 0)

	const changeSlide = (current: number) => {
		setCurrentSlide(current)
		resetTimer()
		props.onSlideChange?.(current)
	}

	createEffect(() => {
		const s = slider()
		if (!s) return
		const newSlideIndex = props.selectedSlide ?? currentSlide()
		if (newSlideIndex !== s.track.details.abs) s.moveToIdx(newSlideIndex)
	})

	const [ state, setState ] = createStore<ICarouselContext>({
		slider: null,
		currentSlide: props.selectedSlide ?? 0
	})

	createEffect(() => {
		setState({
			slider: slider(),
			currentSlide: props.selectedSlide ?? currentSlide()
		})
	})

	const [ ref, setRef ] = createSignal<HTMLElement | null>(null)
	createEffect(() => {
		const el = ref()
		if (!el) return
		createEventListener(el, "slideChange" as any, (e: Event & {detail: {index: number}}) => {
			changeSlide(e.detail.index)
		})
	})

	let resetTimer = () => {}

	const [ dragging, setDragging ] = createSignal(false)
	createEffect(() => {
		if (!props.autoPlay || dragging()) return
		const args = createInterval(() => {
			if (!props.autoPlay) return
			changeSlide((currentSlide() + 1) % numSlides())
		}, props.autoPlayDurationMs ?? 5000)		
		resetTimer = args.reset
	})

	const [ currSlideIndex, setCurrSlideIndex ] = createSignal(0)
	
	const value: [ICarouselContext, ICarouselFuncs] = [
		state, {
			registerSlide: () => {
				const index = currSlideIndex()
				setCurrSlideIndex((i) => i+1)
				return index
			}
		}
	]

	return (
		<CarouselContext.Provider value={value}>
			<div
				{...others}
				class={clsx(classes["carousel-container"], others.class, props.classes?.root)}
				ref={(el: HTMLElement) => setRef(el)}
			>
				<Slider
					options={{
						renderMode: "performance",
						range: {
							min: props.min,
							max: props.max
						},
						created: (slider) => setSlider(slider),
						slideChanged: (slider) => {
							const current = slider.track.details.abs
							changeSlide(current)
						},
						trackConfig: [{spacing: 50}],
						slides: {
							spacing: props.spacing ?? 20,
							perView: "auto",
							origin: "center"
						},
						dragStarted: () => setDragging(true),
						dragEnded: () => {
							setDragging(false)
							resetTimer()
						}
					}}
				>
					{props.children}
				</Slider>
				<div class={clsx(classes["carousel-indicators"])}>
					<Button
						variant="styless"
						size="square"
						class={classes["carousel-control"]}
						disabled={currentSlide() === 0}
						onClick={() => slider()?.prev()}
						aria-label="Previous slide"
					>
						<LeftArrow />
					</Button>
					<For each={Array.from({length: numSlides()})}>
						{(_, i) => (
							<div
								class={classes["carousel-indicator"]}
								classList={{[classes["active"]]: i() === currentSlide()}}
							/>
						)}
					</For>
					<Button
						variant="styless"
						size="square"
						class={classes["carousel-control"]}
						disabled={currentSlide() === (props.max ?? numSlides()) - 1}
						onClick={() => changeSlide(currentSlide() +1)}
						aria-label="Next slide"
					>
						<RightArrow />
					</Button>
				</div>
			</div>
		</CarouselContext.Provider>
	)
}

export default Carousel


export interface ICarouselSlideClasses {
	root?: string,
	container?: string
}

export type ICarouselSlideProps = JSX.IntrinsicElements["div"] & {
	classes?: ICarouselSlideClasses,
	fixLength?: number,
	index?: number
}

let globalId = 0;

export const CarouselSlide: Component<ICarouselSlideProps> = (_props) => {

	let id = globalId++
	const [ fixed, setFixed ] = createSignal(false)
	const [ slideIndex, setSlideIndex ] = createSignal<number | null>(null)

	const [ context, funcs ] = useContext(CarouselContext)
	const [ transform, setTransform ] = createSignal<string | null>(null)
	const [ covered, setCovered ] = createSignal(false)

	onMount(() => {
		setSlideIndex(funcs.registerSlide())
	})

	const ref = (): HTMLElement | null => {
		if (slideIndex() === null) return null
		return context.slider?.slides[slideIndex()!] ?? null
	}

	createEffect(() => {
		const el = ref()
		if (!context.slider || !el) return
		const index = slideIndex()
		if (index === null || props.fixLength === undefined) return

		if (context.currentSlide >= index) setFixed(true)
		else setFixed(false)

		if (context.currentSlide > index) setCovered(true)
		else setCovered(false)
	})
	
	createEffect(() => {
		if (!context.slider) return
		const currentSlideEl = context.slider.slides[context.currentSlide]
		onPropertyChange(currentSlideEl, "style", () => {
			if (fixed()) {
				onPropertyChange(currentSlideEl, "style", (el) => {
					const transformNums = el.style.transform.match(/-?\d+/g)
					const nums = (transformNums?.map((n) => parseInt(n)) ?? [0,0,0,0]).slice(1)
			
					setTransform(`translate3d(${minMax(-nums[0], 0, context.slider?.size ?? 0)}px, 0px, 0px)`)
				})
			} else setTransform("translate3d(0,0,0)")
		})
	})

	const [ props, others ] = splitProps(_props, [
		"classes", "children", "fixLength",
		"index"
	])	
	return (
		<div
			{...others}
			class={clsx(
				classes["slide"],
				{[classes["covered"]]: covered()},
				props.classes?.root,
				others.class,
				`id-${id}`,
			)}
		>
			<div
				style={{transform: transform() ?? undefined}}
				class={clsx(
					classes["slide-inner"],
					props.classes?.container
				)}
			>
				{props.children}
			</div>
		</div>
	)
}