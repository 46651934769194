import type { WalletConnectModalSign } from "@walletconnect/modal-sign-html"
import type { InterfaceAbi } from "ethers";
import { toHex } from "~/util";

export type TransactionArgs = {
	to: string,
	value: string,
	chainId: number,
	decimals?: number
}

export type TransferToContractArgs = {
	contractAddress: string,
	abi: InterfaceAbi,
	data: Omit<TransactionArgs, "chainId">,
	decimals: number,
	chainId: number
}

export abstract class CrossProvider {
	abstract disconnect(): Promise<void>;
	abstract sendNativeTransaction(tx: TransactionArgs): Promise<void>
	abstract onDisconnect(callback: () => void): Promise<void>
	abstract onAccountsChanged(callback: (accounts: string[]) => void): Promise<void>
	abstract onChainChanged(callback: (chainId: number) => void): Promise<void>
	abstract switchChain(chainId: number): Promise<void>
	// Null for multichain wallets
	abstract getChainId(): Promise<number | null>
	abstract transferToContract(args: TransferToContractArgs): Promise<void>
	abstract signMessage(data: string): Promise<string>
	abstract getAccount(): Promise<string>
}

type BrowserProviderType = Exclude<typeof window.ethereum, undefined>

export class BrowserCrossProvider extends CrossProvider {
	protected provider: BrowserProviderType;
	constructor(provider: BrowserProviderType, manualCall = true) {
		super()
		if (manualCall) throw "Can't call the constructor directly, use CrossProvider.from() instead"
		this.provider = provider
	}

	public static async from(provider: BrowserProviderType) {
		return new BrowserCrossProvider(provider, false)
	}

	async disconnect() {
		await this.provider.removeAllListeners()
	}

	async sendNativeTransaction(tx: TransactionArgs): Promise<void> {
		const { ethers } = await import("ethers")
		const account = await this.getAccount()
		if (!account) throw "No account selected"
		const res = await this.provider.request({
			method: "eth_sendTransaction",
			params: [{
				to: tx.to,
				from: account,
				value: toHex(ethers.parseUnits(tx.value, tx.decimals ?? 18))
			}]
		})
	}
	async onDisconnect(callback: () => void): Promise<void> {
		this.provider.on("accountsChanged", (accounts) => {
			if (accounts.length === 0) callback()
		})
	}
	async onAccountsChanged(callback: (accounts: string[]) => void): Promise<void> {
		this.provider.on("accountsChanged", callback)
	}
	async onChainChanged(callback: (chainId: number) => void): Promise<void> {
		this.provider.on("chainChanged", (chain) => {
			return callback(Number.parseInt(chain, 16))
		})
	}
	async switchChain(chainId: number): Promise<void> {
		await this.provider.request({
			method: "wallet_switchEthereumChain",
			params: [{ chainId: toHex(chainId) }]
		})
	}
	async getChainId(): Promise<number> {
		return Number.parseInt(await this.provider.request({
			method: "eth_chainId",
		}), 16)
	}

	async transferToContract(args: TransferToContractArgs): Promise<void> {
		const { ethers } = await import("ethers")
		const contract = new ethers.Contract(args.contractAddress, args.abi, this.provider)
		const { data } = args

		const newData = contract.interface.encodeFunctionData("transfer", [data.to, ethers.parseUnits(data.value, args.decimals).toString()])

		const account = await this.getAccount()
		const tx = {
			to: args.contractAddress,
			from: account,
			value: ethers.parseEther("0.000").toString(),
			data: newData
		}
		await this.provider.request({
			method: "eth_sendTransaction",
			params: [tx]
		})
	}
	async signMessage(data: string): Promise<string> {
		const { ethers } = await import("ethers")
		const account = await this.getAccount()

		data = ethers.hexlify(ethers.toUtf8Bytes(data))

		const signedMessage = await this.provider.request({
			method: "personal_sign",
			params: [data, account]
		})

		return signedMessage
	}

	async getAccount(): Promise<string> {
		const accounts = await this.provider.request({
			method: "eth_requestAccounts",
			params: []
		})
		return accounts[0]
	}
}

export class WalletConnectCrossProvider extends CrossProvider {
	protected provider: WalletConnectModalSign;
	protected currentChainId: number | null = null;
	constructor(provider: WalletConnectModalSign, manualCall = true) {
		super()
		if (manualCall) throw "Can't call the constructor directly, use CrossProvider.from() instead"
		this.provider = provider
		// this.getChainId().then((id) => {
		// 	console.log("SETTING CURRENT CHAIN ID", id)
		// 	this.currentChainId = id
		// })
		this.provider.onSessionEvent((data: unknown) => {
			if (data && typeof(data) === "object") {
				const event = (data as any)?.params?.event ?? (data as any)?.event
				if (event.name === "chainChanged") {
					this.currentChainId = event.data
				}
			}
		})
		this.isMetamask().then((isMetamask) => {
			this.currentChainId = isMetamask ? 1 : null
		})
	}

	public static async from(provider: WalletConnectModalSign) {
		return new WalletConnectCrossProvider(provider, false)
	}

	async isMetamask() {
		const session = await this.provider.getSession()
		return session?.peer.metadata.name.toLowerCase().includes("metamask") ?? false
	}

	async disconnect() {
		const session = await this.provider.getSession()
		if (!session) throw "No session"

		const { getSdkError } = await import("@walletconnect/utils")

		await this.provider.disconnect({
			reason: getSdkError("USER_DISCONNECTED"),
			topic: session.topic
		})
	}

	async sendNativeTransaction(tx: TransactionArgs): Promise<void> {
		const { ethers } = await import("ethers")
		const account = await this.getAccount()
		if (!account) throw "No account selected"

		const session = await this.provider.getSession()
		if (!session) throw "No session"
		try {
			await this.provider.request({
				topic: session.topic,
				request: {
					method: "eth_sendTransaction",
					params: [{
						to: tx.to,
						from: account,
						value: toHex(ethers.parseUnits(tx.value, tx.decimals ?? 18)),
						data: ""
					}]
				},
				chainId: `eip155:${tx.chainId}`
			})
		} catch(e) {
			console.error(e)
			throw e
			
		}
	}
	async onDisconnect(callback: () => void): Promise<void> {
		this.provider.onSessionDelete(callback)
	}
	async onAccountsChanged(callback: (accounts: string[]) => void): Promise<void> {
	}
	async onChainChanged(callback: (chainId: number) => void): Promise<void> {
		this.provider.onSessionEvent((data: unknown) => {
			if (data && typeof(data) === "object") {
				const event = (data as any)?.params?.event ?? (data as any)?.event
				if (event.name === "chainChanged") {
					callback(event.data)
				}
			}
		})
	}
	async switchChain(chainId: number): Promise<void> {
		if (!this.isMetamask()) return;
		return new Promise(async (resolve, reject) => {
			const session = await this.provider.getSession()
			if (!session) throw new Error("No session");
			const listener = (data: unknown) => {
				if (data && typeof(data) === "object") {
					const event = (data as any)?.params?.event ?? (data as any)?.event
					if (event.name === "chainChanged" && event.data === chainId) {
						resolve()
						this.provider.offSessionEvent(listener)
					} else if (event.data !== chainId) {
						reject()
						this.provider.offSessionEvent(listener)
					}
				}
			}
			try {
				this.provider.onSessionEvent(listener)
				this.provider.request({
					chainId: `eip155:${this.currentChainId}`,
					request: {
						method: "wallet_switchEthereumChain",
						params: [{ chainId: toHex(chainId) }]
					},
					topic: session.topic
				})
			} catch(e) {
				console.error(e)
				reject(e)
				this.provider.offSessionEvent(listener)
			}
		})
	}
	async getChainId(): Promise<number | null> {
		return this.currentChainId
	}

	async transferToContract(args: TransferToContractArgs): Promise<void> {
		const account = await this.getAccount()
		const session = await this.provider.getSession()
		if (!session) throw new Error("No session");

		const { ethers } = await import("ethers")
		const contract = new ethers.Contract(args.contractAddress, args.abi)
		const { data } = args

		const newData = contract.interface.encodeFunctionData("transfer", [data.to, ethers.parseUnits(data.value, args.decimals).toString()])

		const tx = {
			to: args.contractAddress,
			from: account,
			value: ethers.parseEther("0.000").toString(),
			data: newData,
		}
		try {
			await this.provider.request({
				topic: session.topic,
				request: {
					method: "eth_sendTransaction",
					params: [tx]
				},
				chainId: `eip155:${args.chainId}`
			})
		} catch(e) {
			console.error(e)
			throw e
		}
	}
	async signMessage(data: string): Promise<string> {
		const { ethers } = await import("ethers")
		const account = await this.getAccount()

		const session = await this.provider.getSession()
		if (!session) throw new Error("No session")

		data = ethers.hexlify(ethers.toUtf8Bytes(data))

		const signedMessage: string = await this.provider.request({
			request: {
				method: "personal_sign",
				params: [data, account]
			},
			chainId: `eip155:1`,
			topic: session.topic
		})

		return signedMessage
	}
	async getAccount(): Promise<string> {
		const session = await this.provider.getSession()
		if (!session) throw new Error("No session")
		return session.namespaces["eip155"].accounts[0].replace(/^eip155:\d+:/, "")
	}
}