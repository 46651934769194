import { Component, JSX } from "solid-js";

import DiscordIcon from "~/svg/discord-outlined.svg?component-solid"
import TwitterIcon from "~/svg/twitter-outlined.svg?component-solid"
import TelegramIcon from "~/svg/telegram-outlined.svg?component-solid"

export const socials: {
	link: string,
	label: string,
	icon: Component<JSX.SvgSVGAttributes<SVGSVGElement>>
}[] = [
	{
		label: "Discord",
		link: "https://discord.gg/Bezbu3Uzdz",
		icon: DiscordIcon
	},
	{
		label: "Twitter",
		link: "https://twitter.com/Doge_Uprising",
		icon: TwitterIcon
	},
	{
		label: "Telegram",
		link: "https://t.me/+JfnjZpCD9b4wNTlk",
		icon: TelegramIcon,
	}
]