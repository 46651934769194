import type { Component } from "~/types"
import Page from "~/components/Page"
import HeaderSection from "./sections/HeaderSection"
import TermsSection from "./sections/TermsSection/TermsSection"
import ContactSection from "~/sections/ContactSection/ContactSection"
import FooterSection from "~/sections/FooterSection/FooterSection"

import classes from "./TermsConditionsPage.module.css"
import OptimizedImage from "~/components/OptimizedImage/OptimizedImage"

const TermsConditionsPage: Component = () => {

	return (
		<Page path="/terms-conditions" title="Terms and Conditions" class={classes["terms-page"]}>
			<OptimizedImage
				src="/img/grainy-background.png"
				alt="Grainy background"
				class={classes["bg-img"]}
				aria-hidden
				width={1440}
				height={980}
			/>
			<HeaderSection />
			<TermsSection />
		</Page>
	)
}

export default TermsConditionsPage