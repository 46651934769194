import { JSX, createEffect, createSignal, mergeProps, onMount, splitProps, useContext } from "solid-js"
import type { Component } from "~/types"

import clsx from "clsx"

import classes from "./PhoneInput.module.css"
import Input, { IInputProps } from "../Input"
import Select from "../Select"

import type { Country } from "country-list-with-dial-code-and-flag"
import { UserContext } from "~/context/UserContext"

export type PhoneValue = {
	country: Country,
	number: string
}

export type IPhoneInputProps = Omit<IInputProps, "onChange" | "value"> & {
	onChange: (value: PhoneValue) => void,
	value: PhoneValue | null
}

const PhoneInput: Component<IPhoneInputProps> = (__props) => {
	const _props = mergeProps({
		placeholder: "07872 972832"
	}, __props)
	const [ props, others ] = splitProps(_props, ["onChange", "value"])
	const [ countries, setCountries ] = createSignal<Country[]>([])
	const [ user ] = useContext(UserContext)
	const [ addedIpCountry, setAddedIpCountry ] = createSignal(false)

	createEffect(async () => {
		const userLookup = user.ipLookup
		const { default: country } = await import("country-list-with-dial-code-and-flag")

		let topList = [
			...(userLookup ? country.findByCountryCode(userLookup.alpha2) : []),
			...country.findByCountryCode("US"),
			...country.findByCountryCode("GB"),
		]
		topList = topList.filter((country, i) => i == 0 || topList.findIndex(c => c.name === country.name) === i)

		const list = [
			...topList,
			...country.getAll()
		]
		setAddedIpCountry(true)
		setCountries(list)
	})

	const [ countryChanged, setCountryChanged ] = createSignal(false)

	createEffect(() => {
		const list = countries()
		if (list.length === 0) return
		if (!props.value?.country) {
			props.onChange?.({country: countries()[0], number: props.value?.number ?? ""})
		}
		if (!countryChanged() && addedIpCountry()) {
			const country = countries().find((country) => country.code === user.ipLookup?.alpha2)
			if (!country) return;
			props.onChange?.({country, number: props.value?.number ?? ""})
			setCountryChanged(true)
		}
	})

	return (
		<Input
			{...others}
			value={props.value?.number ?? ""}
			onInput={(e) => props.onChange?.({country: props.value?.country ?? countries()[0], number: e.currentTarget.value})}
			autocomplete="tel-national"
			outerChildren={
				<Select
					class={classes["dial-code"]}
					options={countries()}
					value={props.value?.country ?? countries()[0]}
					onChange={(country) => {
						props.onChange?.({country, number: props.value?.number ?? ""})
						if (!countryChanged()) setCountryChanged(true)
					}}
					valueFormatter={(country) => `${country.flag} ${country.dialCode}`}
					valueEquator={(a, b) => a.code === b.code}
					buttonValueFormatter={(country) => `${country.flag} ${country.name} (${country.dialCode})`}
				/>
			}
		/>
	)
}

export default PhoneInput