import type { Component } from "~/types"

import classes from "./FAQSection.module.css"
import Section from "~/components/Section"
import Heading from "~/components/Heading"
import Carousel, { CarouselSlide } from "~/components/Carousel/Carousel"
import { For, JSX, createEffect, createSignal, splitProps } from "solid-js"
import clsx from "clsx"
import OptimizedImage from "~/components/OptimizedImage/OptimizedImage"
import { createEventListener, useBounds } from "~/util"
import OptimizedVideo from "~/components/OptimizedVideo/OptimizedVideo"

const faqs: {question: string, answer: string}[] = [
	{
		question: "What is Doge Uprising?",
		answer: "Doge Uprising is an innovative blockchain-based project that weaves together cryptocurrency, digital art, and storytelling. The story revolves around Doge Mechas, depicted as unique NFTs, rising against a dystopian world, all portrayed in a manga-style comic series."
	},
	{
		question: "How can I buy the $DUP token?", 
		answer: "Buying $DUP is a simple process. Visit the 'Buy' section on our website, connect your wallet using the Connect Wallet Widget, and follow the instructions to purchase $DUP. We accept various forms of cryptocurrency. Make sure you have enough funds for the transaction fee. After the transaction is confirmed, refresh the page and connect your wallet to view your balance."
	},
	{
		question: "How many $DUP tokens will there be?",
		answer: "The total supply of $DUP tokens is capped at 450 billion. They will be allocated to exchanges, presale, marketing, development, and airdrops."
	},
	{
		question: "How can I get $DUP tokens?",
		answer: "You can get your hands on $DUP tokens during the presale. We accept Ethereum (ETH ERC20), Tether (USDT ERC20), and Binance Coin (BNB BEP20). After the presale and the listing, you can buy $DUP directly on Uniswap.",
	},
	{
		question: "On what network will Doge Uprising launch?",
		answer: "Doge Uprising will launch on the Ethereum network."
	},
	{
		question: "When will I receive my tokens in my wallet?",
		answer: "You will receive your tokens after the presale."
	}
]

const FAQSection: Component = () => {
	const [ ref, setRef ] = createSignal<HTMLElement | null>(null)
	const [ bounds, setBounds ] = createSignal<DOMRect>(new DOMRect())

	const updateBounds = () => {
		const el = ref()
		if (!el) return
		setBounds(el.getBoundingClientRect())
	}

	createEffect(() => {
		updateBounds()
		createEventListener(window, ["resize", "scroll"], updateBounds)
	})

	return (
		<Section class={classes["section"]} noInner id="faq-section" ref={(el) => setRef(el)}>
			<OptimizedImage
				src="/img/grainy-background-cropped-mobile.png"
				aria-hidden
				alt="Grainy background"
				width={400}
				height={544}
				class={classes["bg-video"]}
			/>
			<OptimizedImage
				src="/img/question-exclamation.png"
				width={152}
				height={179}
				alt="?!"
				class={classes["question-decal"]}
				style={{
					top: `${bounds().top}px`,
				}}
			/>
			<Heading heading="FAQ" headingColor="white" />
			<Carousel spacing={60} class={classes["carousel"]} slides={faqs.length}>
				<For each={faqs}>
					{(faq) => (
						<CarouselSlide class={classes["slide"]}>
							<FAQCard
								question={faq.question}
								answer={faq.answer}
							/>
						</CarouselSlide>
					)}
				</For>
			</Carousel>
		</Section>
	)
}

export default FAQSection

export type IFAQCardProps = JSX.HTMLAttributes<HTMLDivElement> & {
	question: string,
	answer: string
}

export const FAQCard: Component<IFAQCardProps> = (_props) => {
	const [ props, others ] = splitProps(_props, [
		"question", "answer"
	])

	return (
		<div {...others} class={clsx(classes["faq-card"], others.class)}>
			<div class={classes["heading-box"]}>
				{props.question}
			</div>
			<div class={classes["answer-box"]}>
				{props.answer}
			</div>
		</div>
	)
}