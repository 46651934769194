import { JSX, Show, splitProps } from "solid-js"
import type { Component } from "~/types"

import clsx from "clsx"

import classes from "./Heading.module.css"
import TextStroke from "../TextStroke/TextStroke"
import { useIsMobile } from "~/util"

export interface IHeadingClasses {
	heading?: string
	topLabel?: string
	bottomLabel?: string
	container?: string
}

export type IHeadingProps = JSX.HTMLAttributes<HTMLDivElement> & {
	topLabel?: string | JSX.Element,
	heading?: string | JSX.Element,
	bottomLabel?: string | JSX.Element,
	headingColor?: "white" | "black" | "primary",
	headingStroke?: number
	classes?: IHeadingClasses,
	shadow?: boolean
}

const Heading: Component<IHeadingProps> = (_props) => {
	const [ props, others ] = splitProps(_props, [
		"topLabel", "heading", "bottomLabel",
		"headingColor", "classes", "shadow",
		"headingStroke"
	])

	const isMobile = useIsMobile()

	return (
		<div
			{...others}
			class={clsx(
				classes["heading-container"],
				{[classes[`heading-col-${props.headingColor}`]]: props.headingColor},
				others.class,
				props.classes?.container
			)}>
			<Show when={props.topLabel}>
				<p class={clsx(classes["top-label"], props.classes?.topLabel)}>{props.topLabel}</p>
			</Show>
			<Show when={props.heading}>
				<TextStroke
					component="h1"
					class={clsx(classes["heading"], props.classes?.heading)}
					shadow={props.shadow ?? !isMobile()}
					strokeWidth={props.headingStroke}
				>{props.heading}</TextStroke>
			</Show>
			<Show when={props.bottomLabel}>
				<p class={clsx(classes["bottom-label"], props.classes?.bottomLabel)}>{props.bottomLabel}</p>
			</Show>
		</div>
	)
}

export default Heading