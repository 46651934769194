import { createContext, createEffect, onMount, useContext } from "solid-js"
import type { API, Component } from "~/types"
import { createStore } from "solid-js/store"
import { getUserIPLookup } from "~/util"
import { Web3Context } from "./Web3Context"

import * as api from "~/util/api.util"
import toast from "solid-toast"

export type IUserContextValue = {
	ipLookup: API.IPLookup | null,
	token: API.Token | null,
	user: API.User | null,
	bonuses: API.ReferralBonuses | null
}

export interface IUserContextFunctions {
	setToken: (token: API.Token) => void,
	getToken: () => Promise<API.Token>,
	updateUser: () => Promise<void>,
	setBonuses: (bonuses: API.ReferralBonuses | null) => void
}

const defaultValue: IUserContextValue = {
	ipLookup: null,
	token: null,
	user: null,
	bonuses: null
}

const defaultFunctions: IUserContextFunctions = {
	setToken: () => {},
	getToken: () => Promise.reject("No context provided"),
	updateUser: () => Promise.reject("No context provided"),
	setBonuses: () => {}
}

export const UserContext = createContext<[IUserContextValue, IUserContextFunctions]>([
	defaultValue, defaultFunctions
])

export const UserContextWrapper: Component = (props) => {
	const [ state, setState ] = createStore<IUserContextValue>(defaultValue)

	onMount(async () => {
		const lookup = await getUserIPLookup().catch(() => {})
		if (lookup) setState("ipLookup", lookup)
	})

	createEffect(async () => {
		if (!web3.account) return setState("bonuses", null);
		const bonuses = await api.getReferralBonuses(web3.account)
		setState("bonuses", bonuses)
	})

	const [ web3, web3Funcs ] = useContext(Web3Context)

	const getUser = async () => {
		if (!web3.account || !web3.connected) {
			setState("user", null)
			setState("token", null)
			return
		};
		let acc = web3.account
		let value = ""
		if(acc.includes(":")) {
			value = acc.split(":")[2]
		} else {
			value = acc
		}
		const user = await api.getUser(value)
		setState("user", user)
	}

	const getToken = async (): Promise<API.Token> => {
		if (!web3.connected || !web3.account) {
			throw "Please connect your wallet"
		}
		const nonceRes = await api.getNonce(web3.account)
		toast("Confirm the message signature in your wallet")
		const signedMessage = await web3Funcs.signMessage(`Sign this message to activate your referral code! ID: ${nonceRes.nonce}`)
		const res = await api.postSignedMessage(web3.account, signedMessage)
		setState("token", res.access)
		return res.access
	}

	createEffect(getUser)

	const UserValue: [IUserContextValue, IUserContextFunctions] = [
		state, {
			getToken,
			setToken: (token) => setState("token", token),
			updateUser: getUser,
			setBonuses: (bonuses) => setState("bonuses", bonuses)
		}
	]

	return (
		<UserContext.Provider value={UserValue}>
			{props.children}
		</UserContext.Provider>
	)
}