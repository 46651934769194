export default (props = {}) => <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg"{...props}>
<g clip-path="url(#clip0_21_7068)">
<path d="M5.54064 3.08287C4.93324 2.38822 4.34064 1.67836 3.73324 0.97864C3.61966 0.851879 3.51101 0.689626 3.29867 0.725119C2.42459 0.872161 1.55052 1.01413 0.676446 1.16118C0.656693 1.1916 0.63694 1.22202 0.617187 1.25244C0.706076 1.32343 0.804842 1.38934 0.888792 1.47554C2.31595 2.97639 3.74805 4.47723 5.17027 5.98822C5.81225 6.66765 6.42953 7.37244 7.05176 8.07216C7.21472 8.2547 7.34311 8.26484 7.4962 8.07216C7.66904 7.8592 7.85176 7.65639 8.02953 7.45357C9.84682 5.42033 11.7629 3.48343 13.6295 1.49582C13.7184 1.39948 13.8567 1.3285 13.8764 1.16117C12.9925 1.01413 12.1184 0.872161 11.2443 0.725119C11.0962 0.699766 10.9974 0.780893 10.9135 0.877231C10.6369 1.18653 10.3653 1.50089 10.0937 1.81019C9.23447 2.78878 8.37521 3.77244 7.51101 4.74596C7.40237 4.86765 7.28385 5.0147 7.11101 4.82202C6.58756 4.23892 6.05422 3.66596 5.54064 3.07779L5.54064 3.08287Z" fill="white"/>
<path d="M7.83235 3.40195C7.43235 2.90505 7.00765 2.42843 6.60766 1.92139C6.19778 1.39913 5.69407 0.968149 5.3237 0.410402C5.13605 0.131529 4.87926 -0.0966398 4.49901 0.040262C3.02247 0.0301207 1.60025 0.314065 0.217532 0.836318C-0.0491349 0.937726 -0.0639497 1.03913 0.148396 1.27237C1.76321 2.99125 3.37309 4.71519 4.9879 6.42899C5.58049 7.05772 6.17803 7.68139 6.78049 8.30505C6.94346 8.47744 7.13605 8.66505 7.3879 8.56364C7.61506 8.4673 7.73852 8.54336 7.89654 8.70054C8.29654 9.10618 8.50395 9.09603 8.89901 8.70054C9.00766 8.59406 9.1163 8.48251 9.22 8.37096C11.3928 6.06899 13.5607 3.76702 15.7336 1.47012C16.1385 1.0442 16.0842 0.729839 15.536 0.471247C15.4422 0.425614 15.3385 0.410403 15.2348 0.38505C14.341 0.156881 13.4225 0.106177 12.5089 0.0301203C12.1435 -0.000301892 11.7583 -0.0560766 11.5064 0.349557C11.4077 0.50674 11.2299 0.430685 11.0867 0.440825C10.9435 0.445896 10.7854 0.38505 10.6669 0.527023C9.8521 1.48533 9.02741 2.4335 8.23235 3.40195C8.05457 3.61491 7.98543 3.59463 7.82741 3.40195L7.83235 3.40195ZM7.5163 4.75068C8.38049 3.77209 9.23975 2.7935 10.099 1.81491C10.3706 1.50561 10.6422 1.19125 10.9188 0.881952C11.0077 0.780543 11.1015 0.704487 11.2496 0.729839C12.1237 0.876881 12.9978 1.02392 13.8817 1.16589C13.857 1.33322 13.7237 1.4042 13.6348 1.50054C11.7681 3.48308 9.8521 5.42505 8.03482 7.45829C7.8521 7.66111 7.67432 7.86392 7.50148 8.07688C7.34346 8.26956 7.22 8.25942 7.05704 8.07688C6.43482 7.37716 5.81753 6.67237 5.17556 5.99294C3.75333 4.48195 2.32617 2.98111 0.894075 1.48026C0.815063 1.39406 0.716297 1.33322 0.62247 1.25716C0.642223 1.22674 0.661976 1.19632 0.681729 1.1659C1.5558 1.01885 2.42988 0.876881 3.30395 0.729839C3.5163 0.694346 3.62988 0.8566 3.73852 0.98336C4.34593 1.67801 4.93852 2.39294 5.54593 3.08759C6.05951 3.67575 6.59284 4.24871 7.1163 4.83181C7.28914 5.02449 7.40766 4.88251 7.5163 4.75575L7.5163 4.75068Z" fill="#29161B"/>
</g>
<defs>
<clipPath id="clip0_21_7068">
<rect width="9" height="16" fill="white" transform="translate(0 9) rotate(-90)"/>
</clipPath>
</defs>
</svg> 