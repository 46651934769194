import type { Component } from "~/types"

import Navbar from "../Navbar/Navbar"
import CSSBaseline from "~/styles/CSSBaseline"
import { Toaster } from "solid-toast"

import classes from "./Layout.module.css"
import Footer from "../Footer/Footer"
import FooterSection from "~/sections/FooterSection/FooterSection"
import TopBanner from "../TopBanner"

const Layout: Component = (props) => {
	return (
		<>
			<CSSBaseline />
			<TopBanner />
			<Navbar />
			{props.children}
			<Toaster
				toastOptions={{
					position: "bottom-center",
					className: classes["toast"]
				}}
			/>
			<FooterSection />
			<Footer />
		</>
	)
}

export default Layout