import { JSX, Show, createEffect, createSignal, onCleanup, splitProps } from "solid-js"
import type { Component } from "~/types"

import clsx from "clsx"

import classes from "./TransactionStatusModal.module.css"
import Modal, { IModalProps } from "../Modal"
import Spinner from "../Spinner/Spinner"
import OptimizedImage from "../OptimizedImage/OptimizedImage"
import Button from "../Button/Button"
import FormLabel from "../FormLabel/FormLabel"
import Input from "../Input/Input"
import ContactForm from "../ContactForm/ContactForm"

import CloseIcon from "~/svg/close-2.svg?component-solid"

export type ITransactionStatusModalProps = IModalProps & {
	status: "pending" | "success" | "failed",
	onTryAgain?: () => void,
	onContinue?: () => void,
}

const TransactionStatusModal: Component<ITransactionStatusModalProps> = (_props) => {
	const [ props, others ] = splitProps(_props, [
		"status", "onTryAgain", "onContinue",
		"onClose"
	])

	const [ showingForm, setShowingForm ] = createSignal(false)

	createEffect(() => {
		if (others.open) return
		const timeout = setTimeout(() => {
			setShowingForm(false)
		}, 500);
		onCleanup(() => clearTimeout(timeout))
	})

	return (
		<Modal
			{...others}
			class={clsx(classes["modal"], classes[`state-${showingForm() ? "form" : props.status}`], others.class)}
		>
			<Show when={props.status === "failed" || showingForm() && props.status === "success"}>
				<button class={classes["close-button"]} onClick={props.onClose} aria-label="Close modal">
					<CloseIcon />
				</button>
			</Show>
			<Show when={props.status === "pending"}>
				<div class={classes["left"]}>
					<div class={classes["spinner-container"]}>
						<Spinner size={12} />
						Pending
					</div>
					<p class={classes["label"]}>
						Approve the transaction through your wallet
					</p>
				</div>
				<div class={clsx(classes["right"], classes["right-cut"])}>
					<OptimizedImage
						src="/img/elon-cute-sad-waving.png"
						alt="Elon mask in a cute style with a doge mask at the side of his fave, visibly crying and waving his arms cartoonishly"
						class={clsx(classes["img"], classes["img-cut"])}
						width={500}
						height={470}
					/>
				</div>
			</Show>
			<Show when={props.status === "failed"}>
				<div class={classes["left"]}>
					<p class={clsx(classes["status-indicator"], classes["error"])}>Failed</p>
					<p class={classes["label"]}>
						The transaction was not approved
					</p>
					<Button size="small" class={classes["button"]} onClick={props.onTryAgain}>
						Try Again
					</Button>
				</div>
				<div class={clsx(classes["right"], classes["right-cut"])}>
					<OptimizedImage
						src="/img/elon-cute-sad-waving.png"
						alt="Elon mask in a cute style with a doge mask at the side of his fave, visibly crying and waving his arms cartoonishly"
						class={clsx(classes["img"], classes["img-cut"])}
						width={500}
						height={470}
					/>
				</div>
			</Show>
			<Show when={props.status === "success" && !showingForm()}>
				<div class={classes["left"]}>
					<p class={clsx(classes["status-indicator"], classes["success"])}>Success</p>
					<p class={classes["label"]}>
						The transaction is successful
					</p>
					<Button
						size="small"
						class={classes["button"]}
						onClick={() => setShowingForm(true)}
					>
						Continue
					</Button>
				</div>
				<div class={clsx(classes["right"], classes["right-cut"], classes["right-cut-2"])}>
					<OptimizedImage
						src="/img/elon-doge-mask.png"
						alt="Elon mask in a cute style with a doge mask at the side of his fave, visibly crying and waving his arms cartoonishly"
						class={classes["img"]}
						width={1500}
						height={1597}
					/>
				</div>
			</Show>
			<Show when={props.status === "success" && showingForm()}>
				<p class={classes["label"]}>Enter your details</p>
				<ContactForm class={classes["form"]} onSubmitSuccess={props.onClose} />
			</Show>
			
		</Modal>
	)
}

export default TransactionStatusModal