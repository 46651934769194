import { JSX, mergeProps, splitProps } from "solid-js"
import type { Component } from "~/types"

import clsx from "clsx"

import classes from "./Spinner.module.css"


export type ISpinnerProps = JSX.HTMLAttributes<HTMLDivElement> & {
	size?: number
}

const Spinner: Component<ISpinnerProps> = (__props) => {
	const _props = mergeProps({
		size: 16
	}, __props)

	const [ props, others ] = splitProps(_props, ["size"])

	return (
		<div {...others} class={clsx(classes["spinner-root"], others.class)}  style={`--size: ${props.size/4}rem; height: ${props.size/4}rem; width: ${props.size/4}rem`}>
			<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clip-path="url(#clip0_390_15695)">
					<path opacity="0.2" d="M45.7861 20.9396C46.8799 20.7859 47.6504 19.7719 47.4063 18.6947C46.5797 15.0479 44.91 11.635 42.52 8.73532C39.7048 5.31983 36 2.74934 31.8151 1.30806C27.6302 -0.133219 23.1283 -0.389152 18.807 0.568559C14.4857 1.52627 10.5134 3.66032 7.32932 6.73476C4.14522 9.80921 1.87334 13.7043 0.764847 17.9894C-0.343647 22.2745 -0.245572 26.7826 1.04823 31.0154C2.34203 35.2482 4.78116 39.0408 8.09595 41.9739C10.9101 44.464 14.2624 46.2523 17.878 47.2061C18.946 47.4878 19.9865 46.7533 20.1783 45.6655L20.3173 44.8777C20.5092 43.7899 19.7787 42.7627 18.7167 42.4588C15.9716 41.6731 13.4277 40.2824 11.2768 38.3791C8.62493 36.0327 6.67362 32.9986 5.63858 29.6123C4.60354 26.2261 4.52508 22.6196 5.41188 19.1915C6.29867 15.7634 8.11618 12.6474 10.6635 10.1878C13.2107 7.72825 16.3885 6.02101 19.8456 5.25485C23.3026 4.48868 26.9042 4.69342 30.2521 5.84645C33.6 6.99947 36.5639 9.05586 38.816 11.7883C40.6427 14.0046 41.9435 16.5956 42.6325 19.3666C42.8991 20.4385 43.9 21.2045 44.9939 21.0509L45.7861 20.9396Z" fill="black"/>
					<path d="M46 24C47.1046 24 48.0087 24.897 47.9167 25.9977C47.7106 28.465 47.1236 30.8896 46.1731 33.1844C44.967 36.0962 43.1992 38.742 40.9706 40.9706C38.742 43.1992 36.0962 44.967 33.1844 46.1731C30.8896 47.1236 28.465 47.7106 25.9977 47.9167C24.897 48.0087 24 47.1046 24 46L24 45.2C24 44.0954 24.8978 43.2108 25.9964 43.0959C27.8327 42.9039 29.6353 42.4477 31.3475 41.7385C33.677 40.7736 35.7936 39.3593 37.5765 37.5765C39.3593 35.7936 40.7736 33.677 41.7385 31.3475C42.4477 29.6353 42.9039 27.8327 43.0959 25.9964C43.2108 24.8978 44.0954 24 45.2 24H46Z" fill="black"/>
				</g>
				<defs>
					<clipPath id="clip0_390_15695">
						<rect width="48" height="48" rx="24" fill="white"/>
					</clipPath>
				</defs>
			</svg> 
		</div>
	)
}

export default Spinner