import type { Component } from "~/types"

import classes from "./TeamSection.module.css"
import Section from "~/components/Section/Section"
import Heading from "~/components/Heading/Heading"
import OptimizedImage from "~/components/OptimizedImage/OptimizedImage"

import TwitterIcon from "~/svg/twitter.svg?component-solid"
import { JSX, Show, mergeProps, splitProps } from "solid-js"
import clsx from "clsx"
import { useIsMobile } from "~/util"

const TeamSection: Component = () => {
	const isMobile = useIsMobile()

	return (
		<Section class={classes["section"]} innerClass={classes["section-inner"]} id="team-section">
			<Show when={!isMobile()}>
				<OptimizedImage
					src="/img/team-section-bg.png"
					alt="A decorative background"
					aria-hidden
					class={classes["background"]}
					width={1920}
					height={2804}
				/>
			</Show>
			<Show when={isMobile()}>
				<OptimizedImage
					src="/img/team-section-bg-mobile.png"
					alt="A decorative background"
					aria-hidden
					class={classes["background-mobile"]}
					width={540}
					height={1869}
				/>
				<OptimizedImage
					src="/img/team-section-bg-mobile-2.png"
					alt="A decorative background"
					aria-hidden
					class={classes["background-mobile-2"]}
					width={540}
					height={1869}
				/>
			</Show>
			<Heading
				topLabel={isMobile() ?  "The uprising's vanguard" : "Meet the uprising's vanguard"}
				heading="Team of Pilots"
				bottomLabel="Our team of leaders, visionaries and pilots steering the revolution"
				headingColor="white"
			/>
			<div class={classes["row"]}>
				<div class={classes["mech-img-container"]}>
					<OptimizedImage
						src="/img/japanese-exclamation.png"
						alt="Japanese exclamation"
						class={classes["japanese-exclamation"]}
						width={502}
						height={571}
					/>
					<OptimizedImage
						src="/img/doge-mecha-2.png"
						alt="A doge mech"
						width={1000}
						height={903}
						hasSizes
					/>
				</div>
				<a class={classes["ceo-label-container"]} href="https://twitter.com/akira_ethx" target="_blank" rel="noopener noreferrer">
					<p class={classes["label"]}>Akira.eth</p>
					<p class={classes["caption"]}>Ceo <TwitterIcon /></p>
				</a>
			</div>
			<div class={classes["team-cards"]}>
				<TeamCard
					imgSrc="/img/pilot-zero.png"
					name="Zero"
					position="Head of Marketing"
					clipDirection={isMobile() ? "left" : "right"}
					imgSize={[454, 535]}
				/>
				<TeamCard
					imgSrc="/img/pilot-yasuke.png"
					name="0xPepe"
					position="Head of mecha Engineering"
					clipDirection="left"
					imgSize={[437, 428]}
				/>
				<TeamCard
					imgSrc="/img/pilot-oxyuki.png"
					name="Yasuke"
					position="Lead Mecha Pilot"
					clipDirection="right"
					imgSize={[703, 760]}
				/>
				<TeamCard
					imgSrc="/img/pilot-bob.png"
					name="B.o.b"
					position="AI Doge Mecha"
					clipDirection={isMobile() ? "left" : "right"}
					imgSize={[431, 445]}
				/>
			</div>
		</Section>
	)
}

export default TeamSection

export type ITeamCardProps = JSX.HTMLAttributes<HTMLDivElement> & {
	imgSrc: string,
	imgSize: [number, number]
	name: string,
	position: string,
	clipDirection?: "left" | "right"
}

export const TeamCard: Component<ITeamCardProps> = (__props) => {
	const _props = mergeProps({
		clipDirection: "left"
	}, __props)

	const [ props, others ] = splitProps(_props, [
		"imgSrc", "imgSize", "name", "position",
		"clipDirection"
	])

	return (
		<div
			{...others}
			class={clsx(classes["team-card"], others.class, classes[`clip-${props.clipDirection}`])}
		>
			<div class={classes["team-card-bg-container"]}>
				<div class={classes["team-card-border"]} />
				<OptimizedImage
					class={classes["team-card-beamed-bg"]}
					src="/img/beamed-grainy-background-portrait.png"
					alt="A decorative background"
					aria-hidden
					width={625}
					height={828}
					hasSizes
				/>
			</div>
			<OptimizedImage
				class={classes["team-card-img"]}
				src={props.imgSrc}
				alt={`Team member ${props.name}`}
				width={props.imgSize[0]}
				height={props.imgSize[1]}
				hasSizes
			/>
			<div class={classes["team-card-text-container"]}>
				<p class={classes["team-name"]}>{props.name}</p>
				<p class={classes["team-title"]}>{props.position}</p>
			</div>
		</div>
	)
}