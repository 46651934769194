import { JSX, Show, splitProps } from "solid-js"
import type { ChildrenType, Component, ComponentType, GetPropsFromComponent } from "~/types"

import clsx from "clsx"

import classes from "./TextStroke.module.css"
import { Dynamic } from "solid-js/web"


export type ITextStrokeProps<
	Comp extends ComponentType
> = GetPropsFromComponent<Comp> & {
	children?: ChildrenType,
	component?: Comp,
	strokeWidth?: number
	strokeColor?: string,
	shadow?: boolean
}

const TextStroke = <Comp extends ComponentType = "p">(_props: ITextStrokeProps<Comp>) => {
	const [ props, others ] = splitProps(_props, [
		"children", "component", "strokeWidth",
		"strokeColor", "shadow"
	])

	return (
		<Dynamic
			component={props.component ?? "p"}
			{...others}
			class={clsx(classes["container"], others.class)}
			style={{
				"--stroke-width": `${props.strokeWidth ?? 6}px`,
				"--stroke-color": props.strokeColor ?? "#000"
			}}
		>
			{props.children}
			<span aria-hidden class={classes["stroke"]}>
				{props.children}
			</span>
			<Show when={props.shadow}>
				<span aria-hidden class={classes["shadow"]}>
					{props.children}
				</span>
			</Show>
		</Dynamic>
	)
}

export default TextStroke