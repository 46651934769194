import type { Component } from "~/types"

import classes from "./TermsSection.module.css"
import Section from "~/components/Section"
import { Link, NavLink, useLocation } from "@solidjs/router"
import { Show } from "solid-js"

const TermsSection: Component = () => {
	const location = useLocation()

	return (
		<Section class={classes["section"]} innerClass={classes["section-inner"]}>
			<div class={classes["left"]}>
				<NavLink href="/terms-conditions" activeClass={classes["bold"]}>Terms of Use</NavLink>
				<NavLink href="/privacy-policy" activeClass={classes["bold"]}>Privacy Policy</NavLink>
			</div>
			<div class={classes["right"]}>
				<Show when={location.pathname === "/terms-conditions"}>
					<TermsAndConditionsContent />
				</Show>
				<Show when={location.pathname === "/privacy-policy"}>
					<PrivacyPolicyContent />
				</Show>
			</div>
		</Section>
	)
}

export default TermsSection

const TermsAndConditionsContent: Component = () => {
	return (
		<>
			<span class={classes["updated-caption"]}>
				Updated July 04, 2023
			</span>
			<h2>1. Introduction</h2>
			<p>Welcome to Doge Uprising. These are the Terms and Conditions governing your access to and use of the www.dogeuprising.co provided by Doge Uprising ("Company", "we", "us", or "our"). By accessing or using our Service, you approve that you have read, understood, and agree to be bound by these Terms.</p>
			<h2>2. Changes to the Terms</h2>
			<p>We reserve the right to modify these terms from time to time at our sole discretion. Therefore, you should review this page periodically. Your continued use of the Website or our service after any such change constitutes your acceptance of the new Terms. If you do not agree to any of these terms or any future version of the Terms, do not use or access (or continue to access) the website or the service.</p>
			<h2>3. User Responsibilities and Obligations</h2>
			<p>As a user of our service you agree to:</p>
			<p>• Be at least 18 years of age or possess legal parental or guardian consent.</p>
			<p>• Be responsible for your use of the Service and any consequences thereof.</p>
			<p>• Comply with all local, state, and national laws applicable to your use of our Service.</p>
			<p>• Not engage in any activity that would constitute a violation of any law, or that infringes the rights of Doge Uprising or others.</p>
			<h2>4. Intellectual Property Rights</h2>
			<p>Unless otherwise indicated, the Service is our proprietary property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Service (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws.</p>
			<h2>5. Prohibited Activities</h2>
			<p>You may not access or use the Service for any purpose other than that for which we make the Service available. Prohibited activity includes:</p>
			<p>• Attempting to interfere with, compromise the system integrity or security, or decipher any transmissions to or from the servers running the Service.</p>
			<p>• Taking any action that imposes, or may impose an unreasonable or disproportionately large load on our infrastructure.</p>
			<p>• Uploading invalid data, viruses, worms, or other software agents through the Service.</p>
			<h2>6. User Generated Content</h2>
			<p>We may, in our sole discretion, permit you to post, upload, publish, submit, or transmit content. By making available any User Contributions on or through the Service, you hereby grant to Doge Uprising a worldwide, irrevocable, perpetual, non-exclusive, transferable, royalty-free license, with the right to sublicense, to use, copy, adapt, modify, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast, access, view, and otherwise exploit such User Contributions.</p>
			<h2>7. Limitation of Liability</h2>
			<p>In no event will Doge Uprising, or its suppliers or licensors, be liable with respect to any subject matter of this agreement under any contract, negligence, strict liability or other legal or equitable theory for: (i) any special, incidental or consequential damages; (ii) the cost of procurement for substitute products or services; (iii) for interruption of use or loss or corruption of data.</p>
			<h2>8. Indemnification</h2>
			<p>You agree to indemnify and hold Doge Uprising harmless from and against any and all losses, damages, liabilities, costs, judgments, charges and expenses, including reasonable attorneys' fees, arising out of or relating to any act or omission by you with respect to (i) your use of the Service or (ii) your breach or violations of this Agreement.</p>
			<h2>9. Governing Law</h2>
			<p>The laws of Your Jurisdiction will govern these Terms, as well as any legal claims that might arise between you and us, without regard to conflict of law provisions.</p>
		</>
	)
}

const PrivacyPolicyContent: Component = () => {
	return (
		<>
			<span class={classes["updated-caption"]}>
				Updated July 01, 2023
			</span>
			<h2>Privacy Policy</h2>
			<p>This privacy notice for Doge Uprising (<span class="bold">"Company"</span>, <span class="bold">"we"</span>, <span class="bold">"us"</span>, or <span class="bold">"our"</span>), describes how and why we might collect, store, use, and/or share (<span class="bold">"process"</span>) your information when you use our services (<span class="bold">"Services"</span>), such as when you:<br /></p>
			<p>• Visit our website at <a href="https://dogeuprising.co">https://dogeuprising.co</a>, or any website of ours that links to this privacy notice</p>
			<p>• Engage with us in other related ways, including any sales, marketing, or events<br /></p>
			<p>Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services.</p>
			<h2>Summary of Key Points</h2>
			<p class="italic">
				This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point.
			</p>
			<p><span class="bold">What personal information do we process?</span> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with Doge Uprising and the Services, the choices you make, and the products and features you use.</p>
			<p><span class="bold">Do we process any sensitive personal information?</span> We do not process sensitive personal information.</p>
			<p><span class="bold">Do we receive any information from third parties?</span> We do not receive any information from third parties.</p>
			<p><span class="bold">How do we process your information?</span> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so.</p>
			<p><span class="bold">In what situations and with which parties do we share personal information?</span> We may share information in specific situations and with specific third parties.</p>
			<p><span class="bold">How do we keep your information safe?</span> We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.</p>
			<h2>1. What information do we collect?</h2>
			<p><span class="bold">Personal information you disclose to use</span></p>
			<p><span class="bold">In Short:</span> We collect personal information that you provide to use</p>
			<p>We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>
			<p><span class="bold">Personal Information Provided by You.</span> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
			<p>
				• Email Addresses<br />
				• Phone numbers<br />
				• Names<br />
			</p>
			<p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>
			<p><span class="bold">Information automatically collected</span></p>
			<p><span class="bold">In Short:</span> Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services</p>
			<p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>
			<p>Like many businesses, we also collect information through cookies and similar technologies.</p>
			<p>The information we collect includes:</p>
			<p>• <span class="italic">Log and Usage Data</span>. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).</p>
			<p>• <span class="italic">Device Data</span>. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</p>
			<p>• <span class="italic">Location Data</span>. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</p>
			<h2>How do we Process your Information?</h2>
			<p><span class="bold">In Short:</span> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</p>
			<p><span class="bold">We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</span></p>
			<p>• <span class="bold">To request feedback:</span> We may process your information when necessary to request feedback and to contact you about your use of our Services.</p>
			<p>• <span class="bold">To send you marketing and promotional communications.</span> We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences.</p>
			<p>• <span class="bold">To protect our services.</span> We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</p>
			<p>• <span class="bold">To identify usage trends.</span> We may process information about how you use our Services to better understand how they are being used so we can improve them.</p>
			<p>• <span class="bold">To determine the effectiveness of our marketing and promotional campaigns.</span> We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.</p>
			<p>• <span class="bold">To save or protect an individual's vital interest.</span> We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.</p>
			<h2>3. What legal bases do we rely on to protect your information?</h2>
			<p><span class="bold">In Short:</span> We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.</p>
			<p><span class="bold underline">If you are located in the EU or UK, this section applies to you.</span></p>
			<p>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</p>
			<p>• <span class="bold">Consent.</span> We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. You can withdraw your consent at any time.</p>
			<p>• <span class="bold">Legitimate Interests.</span> We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:</p>
			<p class={classes["inset"]}>• Send users information about special offers and discounts on our products and services</p>
			<p class={classes["inset"]}>• Analyze how our Services are used so we can improve them to engage and retain users</p>
			<p class={classes["inset"]}>• Support our marketing activities</p>
			<p class={classes["inset"]}>• Diagnose problems and/or prevent fraudulent activities</p>
			<p class={classes["inset"]}>• Understand how our users use our products and services so we can improve user experience</p>
			<p>• <span class="bold">Legal Obligations.</span> We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.</p>
			<p>• <span class="bold">Vital Interests.</span> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</p>
			<h2>4. When and with whom do we share your personal information?</h2>
			<p><span class="bold">In Short:</span> We may share information in specific situations described in this section and/or with the following third parties.</p>
			<p>We may need to share your personal information in the following situations:</p>
			<p>• <span class="bold">Business Transfers.</span> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</p>
			<h2>5. Do we use cookies and other tracking technologies?</h2>
			<p><span class="bold">In Short:</span> We may use cookies and other tracking technologies to collect and store your information.</p>
			<p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</p>
			<h2>6. How long do we keep your information?</h2>
			<p><span class="bold">In Short:</span> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</p>
			<p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).</p>
			<p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
			<h2>7. How do we keep your information safe?</h2>
			<p><span class="bold">In Short:</span> We aim to protect your personal information through a system of organizational and technical security measures.</p>
			<p>We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>
			<h2>8. Do we collect information from minors?</h2>
			<p><span class="bold">In Short:</span> We do not knowingly collect data from or market to children under 18 years of age.</p>
			<p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records.</p>
			<h2>9. What are your privacy rights?</h2>
			<p><span class="bold">In Short:</span>  In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</p>
			<p>In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information.</p>
			<p>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your Member State data protection authority or UK data protection authority.</p>
			<p>If you are located in Switzerland, you may contact the Federal Data Protection and Information Commissioner.</p>
			<p><span class="bold underline">Cookies and similar technologies:</span> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. You may also <span class="underline">opt out of interest-based advertising by advertisers</span> on our Services.</p>
			<h2>10. Controls for do-not-track-features</h2>
			<p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
			<h2>11. Do we make updates to this notice?</h2>
			<p><span class="bold">In Short:</span> Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
			<p>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>
			<br />
			<br />
			<p>Your use of our Services is subject to this Privacy Policy. If you disagree with this Policy, please discontinue use of our Services. Your continued use of our Services following the posting of any changes to this Policy constitutes acceptance of those changes.</p>
		</>
	)
}