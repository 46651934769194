export interface Language {
	label: string,
	code: string,
	imgFormat?: string
}

export const languages: Language[] = [
	{ label: "English", code: "en" },
	{ label: "German", code: "de" },
	{ label: "French", code: "fr" },
	{ label: "Chinese", code: "zh" },
	{ label: "Japanese", code: "ja" },
	{ label: "Korean", code: "ko" },
	{ label: "Russian", code: "ru" },
	{ label: "Portuguese", code: "pt" },
	{ label: "Italian", code: "it" },
	{ label: "Turkish", code: "tr" },
	{ label: "Polish", code: "pl" },
	{ label: "Dutch", code: "nl" },
	{ label: "Norwegian", code: "no" },
	{ label: "Danish", code: "da" },
	{ label: "Finnish", code: "fi" },
	{ label: "Czech", code: "cs" },
	{ label: "Romanian", code: "ro" },
	{ label: "Greek", code: "el" },
	{ label: "Bulgarian", code: "bg" },
	{ label: "Ukrainian", code: "uk" },
	{ label: "Croatian", code: "hr" },
	{ label: "Arabic", code: "ar", imgFormat: "webp" },
	{ label: "Hindi", code: "hi" }
].sort((a, b) => a.label.localeCompare(b.label))

export const languageMap: Record<string, Language> = languages
	.reduce((map, language) => {
		map[language.code] = language
		return map
	}, {} as Record<string, Language>)