import type { Component } from "~/types"

import classes from "./FooterSection.module.css"
import Section from "~/components/Section"
import OptimizedImage from "~/components/OptimizedImage/OptimizedImage"
import { For, Show, createSignal, useContext } from "solid-js"
import { socials } from "~/constants"
import { Dynamic } from "solid-js/web"
import FormLabel from "~/components/FormLabel/FormLabel"
import Input from "~/components/Input/Input"
import { PhoneValue } from "~/components/PhoneInput"
import PhoneInput from "~/components/PhoneInput/PhoneInput"
import Button from "~/components/Button/Button"
import { Link } from "@solidjs/router"
import TextStroke from "~/components/TextStroke/TextStroke"
import { getApiErrorMessage, postLeads, useIsMobile } from "~/util"
import { isEmailValid } from "~/util/validation.util"
import toast from "solid-toast"
import { Web3Context } from "~/context/Web3Context"
import { NavContext, SectionKey } from "~/context/NavContext"
import OptimizedVideo from "~/components/OptimizedVideo/OptimizedVideo"
import { WHITEPAPER_LINK } from "~/constants/links"

const links: {label: string, url: string}[] = [
	{label: "Tokenomics", url: "/?section=tokenomics"},
	{label: "Roadmap", url: "/?section=roadmap"},
	{label: "Whitepaper", url: WHITEPAPER_LINK},
	{label: "Privacy Policy", url: "/privacy-policy"},
	{label: "Terms and Conditions", url: "/terms-conditions"}
]

const FooterSection: Component = () => {
	const [ email, setEmail ] = createSignal("")
	const [ phone, setPhone ] = createSignal<PhoneValue | null>(null)

	const isMobile = useIsMobile()

	const [ web3 ] = useContext(Web3Context)
	const [ detailsSending, setDetailsSending ] = createSignal(false)

	const onSubmit = async (e: Event) => {
		e.preventDefault()

		const data = {
			email: email(),
			mobile: phone()?.number ? `${phone()?.country.dialCode} ${phone()?.number}` : undefined,
			wallet_address: web3.account ?? undefined
		}
		if (!data.email) return toast.error("Email is required")
		if (!isEmailValid(data.email)) return toast.error("Email is invalid")

		setDetailsSending(true)
		try {
			await postLeads(data)
			toast.success("Successfully submitted details")
		} catch(e) {
			toast.error(getApiErrorMessage(e, "Failed to submit details"))
		}
		setDetailsSending(false)
	}

	const [ , navFuncs ] = useContext(NavContext)

	return (
		<Section class={classes["section"]} innerClass={classes["section-inner"]} id="footer-section">
		<Show when={isMobile()}>
			<OptimizedImage
				class={classes["bg-video"]}
				src="/img/footer-section-bg-mobile.jpg"
				aria-hidden
				alt="Decorative background"
				width={540}
				height={2131}
			/>
		</Show>
			<Show when={!isMobile()}>
				<OptimizedImage
					class={classes["bg-video"]}
					src="/img/footer-section-bg.png"
					aria-hidden
					alt="Decorative background"
					width={1440}
					height={1004}
				/>
			</Show>
			<OptimizedImage
				class={classes["logo"]}
				src="/img/logo.png"
				alt="Doge Uprising"
				width={506}
				height={152}
			/>
			<div class={classes["socials"]}>
				<For each={socials}>
					{(social) => (
						<a
							class={classes["social-link"]}
							href={social.link}
							target="_blank"
							rel="noreferrer"
							aria-label="Social media link"
						>
							<Dynamic component={social.icon}/>
						</a>
					)}
				</For>
			</div>
			<div class={classes["form-container"]}>
				<p class={classes["form-title"]}>Get Updates First</p>
				<form class={classes["form"]} onSubmit={onSubmit}>
					<FormLabel label="Email Address*" for="footer-section-email" class={classes["input-container"]}>
						<Input
							id="footer-section-email"
							autocomplete="email"
							autoCapitalize="off"
							value={email()}
							onInput={(e) => setEmail(e.currentTarget.value)}
							placeholder="E.g. name@gmail.com"
							class={classes["input"]}
						/>
					</FormLabel>
					<FormLabel label="Phone Number" for="footer-section-phone" class={classes["input-container"]}>
						<PhoneInput
							id="footer-section-phone"
							value={phone()}
							onChange={(newPhone) => setPhone(newPhone)}
							class={classes["input"]}
						/>
					</FormLabel>
					<Button type="submit" class={classes["button"]}>
						Subscribe
					</Button>
				</form>
			</div>
			<div class={classes["links"]}>
				<For each={links}>
					{(link) => (
						<>
							<Show when={link.url.startsWith("/")}>
								<Link  href={link.url} onClick={(e) => {
									if (link.url.startsWith("/?section=")) {
										e.preventDefault()
										e.stopPropagation()
										navFuncs.scrollToSection(link.url.replace("/?section=", "") as SectionKey)
										window.history.replaceState({}, "", link.url)
									}
								}}>
									<TextStroke>{link.label}</TextStroke>
								</Link>
							</Show>
							<Show when={link.url.startsWith("http")}>
								<a href={link.url} target="__blank">
									<TextStroke>{link.label}</TextStroke>
								</a>
							</Show>
						</>
					)}
				</For>
			</div>
			<OptimizedImage
				class={classes["dog-img"]}
				src="/img/pilot-oxyuki.png"
				alt="A shiba wearing a straw hat and having a sheathed sword by its side"
				width={703}
				height={760}
				hasSizes
			/>
		</Section>
	)
}

export default FooterSection