import toast from "solid-toast"

export const copyText = (str: string, notify = true) => {
	navigator.clipboard.writeText(str)
	if (notify) toast("Copied to clipboard")
}

export const isMobileDevice = (): boolean => {
	return (navigator.userAgent.match(/Android/i)
         || navigator.userAgent.match(/webOS/i)
         || navigator.userAgent.match(/iPhone/i)
         || navigator.userAgent.match(/iPad/i)
         || navigator.userAgent.match(/iPod/i)
         || navigator.userAgent.match(/BlackBerry/i)
         || navigator.userAgent.match(/Windows Phone/i)) !== null;
}