import { createContext } from "solid-js"
import type { Component } from "~/types"
import { createStore } from "solid-js/store"
import { createEventListener, createInterval } from "~/util"

export type IHookContextValue = {
	rem: number,
	isMobile: boolean,
	windowWidth: number,
	windowHeightInner: number,
	windowHeightOuter: number
}

export interface IHookContextFunctions {

}

const defaultValue: IHookContextValue = {
	rem: 16,
	isMobile: window.innerWidth <= 850,
	windowHeightInner: window.innerHeight,
	windowHeightOuter: window.outerHeight,
	windowWidth: window.innerWidth
}

const defaultFunctions: IHookContextFunctions = {}

export const HookContext = createContext<[IHookContextValue, IHookContextFunctions]>([
	defaultValue, defaultFunctions
])

export const HookContextWrapper: Component = (props) => {
	const [ state, setState ] = createStore<IHookContextValue>(defaultValue)

	const HookValue: [IHookContextValue, IHookContextFunctions] = [
		state, defaultFunctions
	]

	const calculateRemMultiplier = () => {
		const fontSize = getComputedStyle(document.documentElement).fontSize
		setState("rem", Number.parseFloat(fontSize.replace("px", "")) ?? 16)
	}
	calculateRemMultiplier()

	createEventListener(window, "resize", () => {
		calculateRemMultiplier()
		setState("isMobile", window.innerWidth <= 850)
		if (state.windowHeightInner !== window.innerHeight) {
			setState("windowHeightInner", window.innerHeight)
		}
		if (state.windowHeightOuter !== window.outerHeight) {
			setState("windowHeightOuter", window.outerHeight)
		}
		if (state.windowWidth !== window.innerWidth) {
			setState("windowWidth", window.innerWidth)
		}
	})

	return (
		<HookContext.Provider value={HookValue}>
			{props.children}
		</HookContext.Provider>
	)
}