import { For, JSX, Show, createEffect, createMemo, createSignal, on, onCleanup, onMount, splitProps, useContext } from "solid-js"
import type { Component } from "~/types"

import clsx from "clsx"

import classes from "./OptimizedImage.module.css"
import { createEventListener, onElementIntersectChange, onElementResize } from "~/util"
import { SettingsContext } from "~/context/SettingsContext"

export type IOptimizedImageProps = Omit<JSX.ImgHTMLAttributes<HTMLImageElement>, "sizes"> & {
	src: string
	alt: string,
	width: number,
	height: number,
	rootClass?: string,
	hasSizes?: boolean,
	loading?: "lazy" | "eager",
	aspectRatio?: number,
	size?: string,
	extension?: string,
	applySize?: boolean,
	applyAspect?: boolean,
	sizes?: (typeof widths)[number]["label"][]
}

const widths = [
	{minWidth: 0, label: "sm", imgWidth: 500},
	{minWidth: 600, label: "md", imgWidth: 800},
	{minWidth: 1400, label: "lg", imgWidth: 1400},
	{minWidth: 2100, label: "xl", imgWidth: 1920}
] as const

const widthsReversed = [...widths].reverse()

const OptimizedImage: Component<IOptimizedImageProps> = (_props) => {
	const [ props, others ] = splitProps(_props, [
		"src", "loading", "class",
		"rootClass", "hasSizes", "loading",
		"aspectRatio", "ref", "size",
		"extension", "width", "height",
		"applySize", "applyAspect",
		"sizes"
	])

	const ext = (): string => props.src.split(".").slice(-1)[0]
	const srcNoExt = (): string => props.src.split(".").slice(0, -1).join(".")
	const imgNameNoExt = (): string => props.src.split("/").slice(-1)[0].split(".").slice(0, -1).join(".")
	const [ settings ] = useContext(SettingsContext)

	const optimizedExt = () => props.extension ?? "webp"

	const optimized = () => ["png", "jpg", "gif"].includes(ext())

	const getSrc = (item: typeof widths[number]) => {
		if (!optimized()) return props.src
		return `${srcNoExt()}/${imgNameNoExt()}${item.label ? `-${item.label}` : ""}.${optimizedExt()}`
	}

	const filteredWidthsReversed = createMemo(() => {
		if (props.sizes) return widthsReversed.filter((item) => props.sizes?.includes(item.label))
		let filtered = props.width ? widthsReversed.filter((size) => {
			const width = widths.find((item) => item.label === size.label)?.imgWidth ?? 0
			return props.width >= width
		}) : widthsReversed
		if (!props.sizes) return filtered
	})

	return (
		<picture
			class={clsx(classes["image"], props.rootClass)}
		>
			{props.hasSizes && optimized() && (
				<For each={filteredWidthsReversed()}>
					{(item) => (
						<source
							srcset={getSrc(item)}
							media={`(min-width: ${item.minWidth}px)`}
						/>
					)}
				</For>
			)}
			{props.size && <source srcset={`${srcNoExt()}/${imgNameNoExt()}-${props.size}.${optimizedExt()}`} />}
			{optimized() && <source srcset={`${srcNoExt()}/${imgNameNoExt()}.${optimizedExt()}`} />}
			<img
				{...others}
				loading={props.loading ?? "lazy"}
				// loading="eager"
				src={props.src}
				class={clsx(props.class)}
				aria-hidden={others["aria-hidden"] ?? (!props.src || !others.alt)}
				alt={others.alt ?? (props.src ? undefined : "No image provided")}
				ref={(el) => {
					(props.ref as Function)?.(el);
				}}
				width={props.applySize ? props.width : undefined}
				height={props.applySize ? props.height : undefined}
				style={{
					"aspect-ratio": props.applyAspect ? `${props.width}/${props.height}` : undefined,
					...(others.style as object)
				}}
			/>
		</picture>
	)
}

export default OptimizedImage