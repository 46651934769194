import { createContext, createEffect, onCleanup, onMount } from "solid-js"
import type { Component } from "~/types"
import { createStore, produce } from "solid-js/store"
import { Language, languageMap, languages } from "~/constants/languages"
import { createEventListener, onPropertyChange } from "~/util"


export type ISettingsContextValue = {
	selectedLanguageCode: string,
	languages: Record<string, Language>,
	imagesLoaded?: boolean
}

export interface ISettingsContextFunctions {
	selectLanguage: (language: string) => void,
}

const defaultValue: ISettingsContextValue = {
	selectedLanguageCode: "en",
	languages: {...languageMap},
	imagesLoaded: false
}

const defaultFunctions: ISettingsContextFunctions = {
	selectLanguage: () => {}
}

export const SettingsContext = createContext<[ISettingsContextValue, ISettingsContextFunctions]>([
	defaultValue, defaultFunctions
])

export const SettingsContextWrapper: Component = (props) => {
	const [ state, setState ] = createStore<ISettingsContextValue>({
		languages: {...languageMap},
		selectedLanguageCode: "en"
	})

	const localLang = localStorage.getItem("selectedLanguageCode")

	const autoTranslate = () => {
		let lang = navigator.language;
		let foundOption = languages.find((option) => {
			return option.code.split("-")[0] == lang.split("-")[0]
		})
		lang = lang.split("-")[0]

		if (localLang) {
			let foundOption2 = languages.find((option) => {
				return option.code.split("-")[0] == localLang
			})
			if (foundOption2) foundOption = foundOption2
			lang = localLang
		}

		if (!foundOption) {
			const newLang = {code: lang, label: lang}
			setState("languages", produce((langs) => (
				langs[lang] = newLang
			)))
			foundOption = newLang
		}
		setState("selectedLanguageCode", foundOption.code)
		doGTranslate(foundOption.code)
	}
	
	const hideTranslateEls = () => {
		const banner = document.querySelector(".skiptranslate") as HTMLDivElement | null
		if (banner) banner.style.display = "none"
		const review = document.getElementById("goog-gt-tt") as HTMLDivElement | null
		if (review) review.style.display = "none"
	}

	
	createEventListener(window, "translateLoaded" as keyof WindowEventMap, () => {
		setTimeout(() => {
			autoTranslate()
		}, 1000)
		const selects = document.querySelectorAll("select.goog-te-combo")
		selects.forEach((select) => {
			createEventListener(select, "change", (e) => {
				const newVal = (e.currentTarget as HTMLSelectElement | null)?.value || "en"
				hideTranslateEls()
				if (state.selectedLanguageCode === newVal) return
				setState( "selectedLanguageCode", newVal)
			})
		})
		
		const reviewEl = document.getElementById("goog-gt-tt")
		const bannerEl = document.getElementById(":1.container")

		if (reviewEl) onPropertyChange(reviewEl, "style", () => {
			reviewEl.style.display = "none"
		})

		if (bannerEl) onPropertyChange(bannerEl, "style", () => {
			bannerEl.style.display = "none"
		})
	})

	createEffect(() => {
		localStorage.setItem("selectedLanguageCode", state.selectedLanguageCode)
	})

	
	onMount(() => {
		const timeout = setTimeout(() => {
			console.log("IMAGES LOADED")
			setState("imagesLoaded", true)
		}, 5000)
		onCleanup(() => {
			clearTimeout(timeout)
		})
	})

	const SettingsValue: [ISettingsContextValue, ISettingsContextFunctions] = [
		state, {
			selectLanguage: (lang) => {
				setState("selectedLanguageCode", lang)
				doGTranslate(state.selectedLanguageCode)
			},
		}
	]

	return (
		<SettingsContext.Provider value={SettingsValue}>
			{props.children}
		</SettingsContext.Provider>
	)
}