export const camelToDisplayCase = (str: string): string => {
	let output = ""
	for (let i = 0; i < str.length; i++) {
		if (str[i] === str[i].toUpperCase()) {
			output += " "
		}
		output += str[i]
	}
	return output
}

export const shortenString = (str: string, maxLength: number): string => {
	if (str.length <= maxLength) return str
	const firstSize = Math.floor((maxLength - 3) / 2)
	const secondSize = (maxLength - 3) - firstSize
	return str.substring(0, firstSize) + "..." + str.substring(str.length - secondSize)
}