import type { Component } from "~/types"

import classes from "./RoadmapSection.module.css"
import Section from "~/components/Section"
import Button from "~/components/Button"
import { For, JSX, Show, createEffect, createSignal, splitProps, useContext } from "solid-js"
import { Web3Context } from "~/context/Web3Context"
import OptimizedImage from "~/components/OptimizedImage"
import TextStroke from "~/components/TextStroke"
import clsx from "clsx"
import { createEventListener, useBounds, useCSSVariable, useIsMobile } from "~/util"
import Heading from "~/components/Heading"
import OptimizedVideo from "~/components/OptimizedVideo/OptimizedVideo"
import { NavContext } from "~/context/NavContext"

const roadmapSections: Omit<IRoadmapCardProps, keyof JSX.HTMLAttributes<HTMLDivElement> | "index">[] = [
	{
		phase: 1,
		phaseLabel: "Genesis",
		img: "/img/phase-1-banner.jpg",
		imgAlt: "The face of a doge mech staring at you",
		imgSize: [985, 319],
		bullets: [
			"Team of top notch mecha pilots assembled",
			"Elon musk onboarded to lead the uprising",
			"Smart contract is audited",
			"Launch social channels",
			"Pre-sale goes live"
		]
	},
	{
		phase: 2,
		phaseLabel: "Uprising",
		img: "/img/phase-2-banner.jpg",
		imgAlt: "The arm of a doge mech with the doge symbol on its shoulder",
		imgSize: [985, 318],
		bullets: [
			"Marketing kicked off",
			"NFT teasers",
			"Social media influencer partnerships"
		]
	},
	{
		phase: 3,
		phaseLabel: "Armageddon",
		img: "/img/phase-3-banner.jpg",
		imgAlt: "The doge mech with it's arms up preparing to fight",
		imgSize: [985, 319],
		bullets: [
			"Uniswap launch",
			"LP tokens burnt",
			"Team tokens vested",
			"NFT Mechas go live powering the uprising",
			"Complete social media take over with Mecha NFTs"
		]
	},
	{
		phase: 4,
		phaseLabel: "Resurrection",
		img: "/img/phase-4-banner.jpg",
		imgAlt: "Eyes through a mask",
		imgSize: [984, 318],
		bullets: [
			"Launch of the Doge Uprising staking platform",
			"Doge Uprising Manga goes live",
			"Merch store launched"
		],
	}
]

const RoadmapSection: Component = () => {
	const [ , navFuncs ] = useContext(NavContext)
	const [ ref, setRef ] = createSignal<HTMLElement | null>(null)
	const [ padding, setPadding ] = createSignal({top: 0, left: 0, bottom: 0, right: 0})
	const bounds = useBounds(ref)

	const calculatePadding = () => {
		const el = ref()
		if (!el) return
		const styles = window.getComputedStyle(el)
		const paddingLeft = Number.parseFloat(styles.paddingLeft.split("px")[0])
		const paddingRight = Number.parseFloat(styles.paddingRight.split("px")[0])
		const paddingTop = Number.parseFloat(styles.paddingTop.split("px")[0])
		const paddingBottom = Number.parseFloat(styles.paddingBottom.split("px")[0])
		setPadding({
			top: paddingTop,
			left: paddingLeft,
			bottom: paddingBottom,
			right: paddingRight
		})

	}

	createEffect(() => {
		calculatePadding()
		createEventListener(window, "resize", calculatePadding)
	})

	const isMobile = useIsMobile()

	const [ headingRef, setHeadingRef ] = createSignal<HTMLElement | null>(null)
	const headingBounds = useBounds(headingRef)
	useCSSVariable("heading-height", () => `${headingBounds().height}px`, ref)

	return (
		<Section
			class={classes["roadmap-section"]}
			innerClass={classes["roadmap-inner"]}
			id="roadmap-section"
			ref={(el) => setRef(el)}
			outerChildren={<>
					<Show when={isMobile()}>
						<OptimizedImage
							class={classes["background"]}
							src="/img/grainy-background-cropped-mobile.png"
							alt="A grainy background"
							aria-hidden
							width={400}
							height={544}
							style={{
								width: `${bounds().width}px`,
								height: `calc(var(--screen-height) + 2rem)`,
								"margin-left": `-${padding().left}px`,
								"margin-bottom": `-${window.innerHeight}px`
							}}
						/>
					</Show>
					<Show when={!isMobile()}>
						<OptimizedImage
							class={classes["background"]}
							src="/img/grainy-background.png"
							alt="A beamed grainy background"
							aria-hidden
							width={1440}
							height={980}
							style={{
								width: `${bounds().width}px`,
								height: `calc(var(--screen-height) + 2rem)`,
								"margin-left": `-${padding().left}px`,
								"margin-bottom": `-${window.innerHeight}px`
							}}
						/>
					</Show>
				</>
			}
		>
			<div class={classes["left"]}>
				<Heading
					heading="Roadmap"
					bottomLabel="Genesis, Uprising, Armageddon, and Resurrection: The Four Stages of the uprising. Each stage will help us get one step closer to the new world order foretold by Elon Musk. Join the presale and pilot a mecha today"
					class={classes["heading-container"]}
					classes={{bottomLabel: classes["bottom-label"]}}
					headingColor="white"
					ref={setHeadingRef}
				/>
				<Button class={classes["button"]} onClick={navFuncs.scrollToBuy}>
					Buy Now
				</Button>
				<OptimizedImage
					class={classes["japanese-text"]}
					src="/img/roadmap-japanese-text.png"
					alt="Japanese text"
					width={72}
					height={368}
				/>
			</div>
			<div class={classes["right"]} style={{"--num-cards": roadmapSections.length}}>
				<For each={roadmapSections}>
					{(section, i) => (
						<RoadmapCard
							{...section}
							index={i()}
						/>
					)}
				</For>
				<div class={classes["right-spacer"]} />
			</div>
		</Section>
	)
}

export default RoadmapSection

export type IRoadmapCardProps = JSX.HTMLAttributes<HTMLDivElement> & {
	phase: number,
	phaseLabel: string,
	img: string,
	imgAlt: string,
	imgSize: [number, number]
	bullets: string[],
	index: number
}

export const RoadmapCard: Component<IRoadmapCardProps> = (_props) => {
	const [ props, others ] = splitProps(_props, [
		"phase", "phaseLabel", "img",
		"imgAlt", "imgSize", "bullets",
		"index"
	])
	const [ imgContainerRef, setImgContainerRef ] = createSignal<HTMLElement | null>(null)
	const imgContainerBounds = useBounds(imgContainerRef)
	useCSSVariable("w-pc", () => `${Math.max(imgContainerBounds().width / 100, 6)}px`, imgContainerRef)

	return (
		<div
			{...others}
			class={clsx(classes["roadmap-card"], others.class)}
			style={{
				"--i": props.index.toString(),
				...(others.style as object)
			}}
		>
			<div class={classes["bullets"]}>
				{props.bullets.map((bullet) => (
					<p class={classes["bullet-item"]}>
						{bullet}
					</p>
				))}
			</div>
			<div class={classes["img-container"]} ref={(el) => setImgContainerRef(el)}>
				<OptimizedImage
					class={classes["banner-img"]}
					src={props.img}
					alt={props.imgAlt}
					width={props.imgSize[0]}
					height={props.imgSize[1]}
					hasSizes
					sizes={["sm", "md"]}
				/>
				<svg preserveAspectRatio="none" width="665" height="220" viewBox="0 0 665 220" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M4.41943 3.88265V215.83H660.58L660.58 40.8082L4.41943 3.88265ZM0.749634 0L664.25 37.3366V219.5H0.749634V0Z" fill="black"/>
				</svg>
				<div class={classes["bubble-container"]}>
					<OptimizedImage
						src="/img/spiky-bubble.svg"
						alt="A speech bubble"
						class={classes["bubble-img"]}
						width={187}
						height={157}
					/>
					<div class={classes["bubble-text"]}>
						<TextStroke class={classes["bubble-title"]} strokeWidth={3} shadow>
							Phase {props.phase.toString()}
						</TextStroke>
						<TextStroke class={classes["bubble-label"]} strokeWidth={3}>
							{props.phaseLabel}
						</TextStroke>
					</div>
				</div>
			</div>
		</div>
	)
}