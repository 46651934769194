import Page from "~/components/Page/Page"
import type { Component } from "~/types"

import TitleSection from "./sections/TitleSection"
import JoinImageSection from "./sections/JoinImageSection"
import RoadmapSection from "./sections/RoadmapSection"
import TokenomicsSection from "./sections/TokenomicsSection"
import SecuritySection from "./sections/SecuritySection"
import TeamSection from "./sections/TeamSection"
import ContactSection from "~/sections/ContactSection"
import FAQSection from "./sections/FAQSection"

const HomePage: Component = () => {
	return (
		<Page path="/">
			<TitleSection />
			<JoinImageSection />
			<RoadmapSection />
			<TokenomicsSection />
			<SecuritySection />
			<TeamSection />
			<ContactSection />
			<FAQSection />
		</Page>
	)
}

export default HomePage