import type { Component } from "~/types"

import classes from "./JoinImageSection.module.css"
import Section from "~/components/Section/Section"
import OptimizedImage from "~/components/OptimizedImage/OptimizedImage"
import { Show } from "solid-js"
import { useIsMobile } from "~/util"
import Heading from "~/components/Heading/Heading"
import OptimizedVideo from "~/components/OptimizedVideo/OptimizedVideo"
import clsx from "clsx"

const JoinImageSection: Component = () => {
	const isMobile = useIsMobile()

	return (
		<Section class={classes["join-section"]} noInner id="join-section">
			<Heading
				heading={isMobile() ? <>Doge<br />Uprising</> : "Doge Uprising"}
				bottomLabel="The Revolution Won't Be Centralized. Participate in the presale for an exclusive opportunity to pilot a Doge mecha and help bring an end to the 'Lizard Boys' reign"
				headingColor="white"
				class={classes["heading-container"]}
				classes={{bottomLabel: classes["bottom-label"]}}
			/>
			<div class={classes["container"]}>
				<Show when={isMobile()}>
					<OptimizedImage
						class={clsx(classes["chair-man-img"], classes["chair-man-img-mobile"])}
						src={"/img/man-chair-doge-mask-mobile.jpg"}
						alt="Elon musk in a chair wearing a doge mask"
						width={900}
						height={1292}
						applyAspect
						hasSizes
					/>
				</Show>
				<Show when={!isMobile()}>
					<OptimizedImage
						class={classes["chair-man-img"]}
						src={"/img/man-chair-doge-mask.jpg"}
						alt="Elon musk in a chair wearing a doge mask"
						width={1898}
						height={1528}
						applyAspect
						hasSizes
					/>
					<OptimizedImage
						src="/img/face-zoom.jpg"
						alt="Zoomed in face with japanese characters"
						class={classes["face-zoom-img"]}
						aria-hidden
						width={798}
						height={445}
					/>
					<OptimizedImage
						src="/img/exclamation-bubble.png"
						alt="A spiky speech bubble containing exclamation marks"
						class={classes["exclamation-bubble"]}
						aria-hidden
						width={314}
						height={269}
					/>
					<div class={classes["speech-container"]}>
						<OptimizedImage
							src="/img/man-chair-doge-mask-bubble.png"
							class={classes["speech-bg"]}
							alt="Speech bubble"
							aria-hidden
							width={1066}
							height={496}
							
						/>
						<span class={classes["speech-1"]}>
							I tried to warn you, but you
							didn’t listen. The facts are
							simple. there can only be one
							-- one token to reign
							supreme. 
						</span>
						<span class={classes["speech-2"]}>
							We’re entering the year of
							the doge. resistance is
							futile. join the uprising. or
							else...
						</span>
					</div>
				</Show>
			</div>
		</Section>
	)
}

export default JoinImageSection