import { JSX, splitProps } from "solid-js"
import type { Component } from "~/types"

import clsx from "clsx"

import classes from "./FormLabel.module.css"


export type IFormLabelProps = JSX.HTMLAttributes<HTMLDivElement> & {
	label: string,
	for: string
}

const FormLabel: Component<IFormLabelProps> = (_props) => {
	const [ props, others ] = splitProps(_props, [
		"label", "children", "for"
	])

	return (
		<div {...others} class={clsx(classes["container"], others.class)}>
			<label class={classes["label-text"]} for={props.for}>{props.label}</label>
			{props.children}
		</div>
	)
}

export default FormLabel