import type { Component } from 'solid-js';
import Layout from './components/Layout';
import { GlobalWrapper } from './context/GlobalWrapper';
import HomePage from './pages/HomePage';
import { Route, Router, Routes } from '@solidjs/router';
import { MetaProvider, Title } from '@solidjs/meta';
import TermsConditionsPage from './pages/TermsConditionsPage/TermsConditionsPage';
import ReferralPage from './pages/ReferralPage/ReferralPage';

const App: Component = () => {
	return (
		<Router>
			<MetaProvider>
				<GlobalWrapper>
					<Layout>
						<Routes>
							<Route path="/" component={HomePage} />
							<Route path="/refer" component={ReferralPage} />
							<Route path="/terms-conditions" component={TermsConditionsPage} />
							<Route path="/privacy-policy" component={TermsConditionsPage} />
						</Routes>
					</Layout>
				</GlobalWrapper>
			</MetaProvider>
		</Router>
	);
};

export default App;
