import { For, JSX, Show, createEffect, createSignal, onMount, splitProps, useContext } from "solid-js"
import type { Component } from "~/types"

import clsx from "clsx"

import classes from "./PresaleBox.module.css"
import { StageContext } from "~/context/StageContext"
import { formatDollar, formatLargeNumber, formatNumber, minMax, useIsMobile } from "~/util"
import TextStroke from "../TextStroke"
import ProgressBar from "../ProgressBar"
import Button from "../Button"
import { Web3Context } from "~/context/Web3Context"
import OptimizedImage from "../OptimizedImage/OptimizedImage"
import FormLabel from "../FormLabel/FormLabel"
import Input from "../Input/Input"
import toast from "solid-toast"

import ERC20 from "~/abi/erc20.json"
import type { InterfaceAbi } from "ethers"
import { CONTRACT_ADDRESS, CONTRACT_PAYMENT_ADDRESS } from "~/constants"
import { PriceContext } from "~/context/PriceContext"
import TransactionStatusModal from "../TransactionStatusModal/TransactionStatusModal"
import { BuyContext } from "~/context/BuyContext"
import { UserContext } from "~/context/UserContext"

import * as api from "~/util/api.util"
import Spinner from "../Spinner/Spinner"

import InputBase from "../InputBase/InputBase"
import ReferIcon from "~/svg/refer.svg?component-solid"
import { Link } from "@solidjs/router"

export type IPresaleBoxProps = JSX.HTMLAttributes<HTMLDivElement> & {

}

const PresaleBox: Component<IPresaleBoxProps> = (_props) => {
	const [ props, others ] = splitProps(_props, [])
	const isMobile = useIsMobile()

	const [ stageData ] = useContext(StageContext)
	const [ web3, web3Funcs ] = useContext(Web3Context)

	const [ state, setState ] = createSignal<"presale" | "buy">("presale")
	const [ buyValue, buyFuncs ] = useContext(BuyContext)

	createEffect(() => {
		if (buyValue.buying) setState("buy")
	})

	createEffect(() => {
		if (!web3.connected) setState("presale")
	})

	const target = (): number => {
		return Number.parseFloat(stageData.stage?.next_stage_target_usd ?? "0")
	}
	const usdRaised = (): number => {
		return Number.parseFloat(stageData.stage?.cumulative_usd_raised ?? "0")
	}

	return (
		<div {...others} class={clsx(classes["presale-box"], classes[`state-${state()}`], others.class)}>
			<p class={classes["presale-stage-label"]}>Stage Selling Fast 🔥</p>
			<p class={classes["presale-stage-value"]}>
				Raised <span class={classes["bold"]}>{formatDollar(usdRaised())}</span> / {formatDollar(target())}
			</p>
			<ProgressBar
				class={classes["progress"]}
				fraction={minMax(usdRaised() / target(), 0, 1)}
			/>
			<div class={classes["buy-container"]}>
				<BuyContents />
			</div>
		</div>
	)
}

export default PresaleBox

const buyTokens = [
	{ name: "Ethereum", symbol: "ETH",  icon: "/img/token-eth.svg", chainId: 1, decimals: 18 } as const,
	{ name: "Tether", symbol: "USDT",  icon: "/img/token-usdt.svg", chainId: 1, decimals: 6, contractAddress: "0xdAC17F958D2ee523a2206206994597C13D831ec7", abi: ERC20 } as const,
	{ name: "Binance Coin", symbol: "BNB",  icon: "/img/token-bnb.svg", chainId: 56, decimals: 18, contractAddress: "0x6fE8424Af31a422e3088b4D08637E5A193279D6F", abi: ERC20 } as const,
] satisfies {name: string, symbol: string, icon: string, chainId: number, decimals: number, contractAddress?: string, abi?: InterfaceAbi}[]

const BuyContents: Component = () => {
	const [ selectedTokenSymbol, setSelectedTokenSymbol ] = createSignal<typeof buyTokens[number]["symbol"]>(buyTokens[0].symbol)
	const selectedToken = () => buyTokens.find((token) => token.symbol === selectedTokenSymbol())!

	const [ buyUsdStr, setBuyUsdStr ] = createSignal("")
	const [ buyUsdNum, setBuyUsdNum ] = createSignal(0)
	const [ buyTokensStr, setBuyTokensStr ] = createSignal("")
	const [ buyTokensNum, setBuyTokensNum ] = createSignal(0)

	const [ stageData ] = useContext(StageContext)

	const usdPerToken = () => Number.parseFloat(stageData.stage?.token_price ?? "0")
	const tokenPerUsd = () => usdPerToken() === 0 ? 0 : (1 / usdPerToken())

	const [ referralCodeVisible, setReferralCodeVisible ] = createSignal(false)
	const [ referralCode, setReferralCode ] = createSignal("")
	const [ transactionStatus, _setTransactionStatus ] = createSignal<"not_started" | "pending" | "success" | "failed">("not_started")
	const [ previousTransactionStatus, setPreviousTransactionStatus ] = createSignal<"not_started" | "pending" | "success" | "failed">("not_started")

	const setTransactionStatus = (status: "not_started" | "pending" | "success" | "failed") => {
		setPreviousTransactionStatus(transactionStatus())
		_setTransactionStatus(status)
	}

	const partialNumberRegex = /^\d*\.?\d*$/
	const partialDollarRegex = /^\d*\.?\d?\d?$/

	const [ web3, web3Funcs ] = useContext(Web3Context)
	const [ prices ] = useContext(PriceContext)
	const [ buying, setBuying ] = createSignal(false)

	createEffect(() => {
		if (!web3.connected && !web3.connectModalOpen) setBuying(false)
		if (buying() && web3.connected) {
			setBuying(false)
			buy()
		}
	})

	const buy = async () => {
		if (buyUsdNum() <= 0) return toast.error("Must be pay more than $0")
		if (!prices.loaded) return toast.error("Prices not yet loaded, please wait a second")
		if (transactionStatus() === "pending") return toast.error("Transaction already in progress")
		if (!web3.connected) {
			web3Funcs.showConnectModal()
			setBuying(true)
			return
		}
		const amount = (buyUsdNum() / prices.prices[selectedToken().symbol]).toString()
		try {
			setTransactionStatus("pending")
			await web3Funcs.sendTransaction({
				chainId: selectedToken().chainId,
				native: ["ETH", "BNB"].includes(selectedToken().symbol),
				to: CONTRACT_PAYMENT_ADDRESS,
				value: amount,
				abi: selectedToken().abi,
				contractAddress: selectedToken().contractAddress,
				decimals: selectedToken().decimals
			})
			setTransactionStatus("success")
		} catch(err) {
			toast.error(typeof(err) === "string" ? err : "Transaction failed")
			console.error(err)
			setTransactionStatus("failed")
		}
	}

	const [ user, userFuncs ] = useContext(UserContext)
	const [ referralApplied, setReferralApplied ] = createSignal(false)
	const [ referralApplying, setReferralApplying ] = createSignal(false)

	createEffect(async () => {
		const bonuses = user.bonuses
		if (!bonuses) return
		if (bonuses.is_referred) {
			setReferralApplied(true)
		}
	})

	const [ connectedReferralApplying, setConnectedReferralApplying ] = createSignal(false)

	createEffect(async () => {
		if (!web3.connected && !web3.connectModalOpen) return setConnectedReferralApplying(false)
		if (connectedReferralApplying() && web3.connected) {
			applyReferralCode()
			setConnectedReferralApplying(false)
		}
	})

	const applyReferralCode = async () => {
		if (referralApplying()) return toast.error("Bonus code already applied")
		if (!referralCode()) return toast.error("No bonus code entered")
		if (transactionStatus() === "pending") return toast.error("Transaction already in progress")
		if (!web3.connected) {
			web3Funcs.showConnectModal()
			return setConnectedReferralApplying(true)
		}
		if (!web3.account) return toast.error("No account connected")
		
		setReferralApplied(false)
		setReferralApplying(true)
		try {
			const token = await userFuncs.getToken();
			await api.updateReferralCode(token.token, web3.account, referralCode())
			const bonuses = await api.getReferralBonuses(web3.account)
			await userFuncs.updateUser()
			if (!bonuses.is_referred) throw "Bonus code not valid"
			userFuncs.setBonuses(bonuses)
			setReferralApplied(true)
			toast.success(`${bonuses.referral_token_bonus_percentage}% activated when you spend ${formatDollar(bonuses.min_referral_spend)} or more`, {duration: 8000})
		} catch(err) {
			userFuncs.setBonuses(null)
			setReferralApplied(false)
			if (typeof(err) === "string") return toast.error(err)
			toast.error(api.getApiErrorMessage(err, "Failed to apply bonus code"))
		}
		setReferralApplying(false)
	}

	const [ triedUrlCode, setTriedUrlCode ] = createSignal(false)
	createEffect(() => {
		const params = new URLSearchParams(window.location.search)
		const code = params.get("code")
		if (!code || referralApplying() || triedUrlCode()) return;
		setTriedUrlCode(true)
		setReferralCodeVisible(true)
		setReferralCode(code)
		applyReferralCode()
	})

	return (
		<>
			<div class={classes["tokens"]}>
				<For each={buyTokens}>
					{(token) => {
						const selected = () => token.symbol === selectedTokenSymbol()
						return (
							<Button
								class={clsx(
									classes["token-button"],
									{[classes["selected"]]: selected()}
								)}
								color={selected() ? "primary" : "white"}
								onClick={() => setSelectedTokenSymbol(token.symbol)}
							>
								<OptimizedImage src={token.icon} alt={token.name} width={32} height={32} />
								{token.symbol}
							</Button>
						)
					}}
				</For>
			</div>
			<div class={classes["price-labels"]}>
				<p>Current Price: <span class="bold">{formatDollar(stageData.stage?.token_price ?? 0)}</span> </p>
				<p>Next Price: <span class="bold">{formatDollar((stageData.stage && stageData.stage.token_price ? +stageData.stage.token_price : 0) + 0.00000290486)}</span></p>
			</div>
			<div class={classes["divider"]} />
			<div class={classes["buy-inputs-container"]}>
				<FormLabel label="Amount in USD you pay" for="presale-box-buy-usd">
					<Input
						class={classes["buy-input"]}
						id="presale-box-buy-usd"
						placeholder="0.0"
						value={buyUsdStr()}
						units="USD"
						onFocus={(e) => {
							if (e.currentTarget.value === "0") e.currentTarget.value = ""
						}}
						onInput={(e) => {
							let val = e.currentTarget.value
							if (partialDollarRegex.test(e.currentTarget.value)) {
								if (val === "") val = "0"
								setBuyUsdStr(e.currentTarget.value)
								setBuyUsdNum(Number.parseFloat(val))
								const tokensNum = Math.floor(buyUsdNum() * tokenPerUsd())
								setBuyTokensStr(tokensNum.toString())
								setBuyTokensNum(tokensNum)
							} else {
								setBuyUsdStr(buyUsdNum().toString())
								e.currentTarget.value = buyUsdNum().toString()
							}
						}}
						onBlur={() => setBuyUsdStr(buyUsdNum().toString())}
					/>
				</FormLabel>
				<FormLabel label="Amount you receive" for="presale-box-buy-tokens">
					<Input
						class={classes["buy-input"]}
						id="presale-box-buy-tokens"
						value={buyTokensStr()}
						placeholder="0.0"
						units={<OptimizedImage src="/img/doge-logo.png" alt="Doge" width={75} height={67} style={{height: "1.9rem"}} />}
						onFocus={(e) => {
							if (e.currentTarget.value === "0") e.currentTarget.value = ""
						}}
						onInput={(e) => {
							let val = e.currentTarget.value
							if (partialNumberRegex.test(e.currentTarget.value)) {
								if (val === "") val = "0"
								setBuyTokensStr(e.currentTarget.value)
								setBuyTokensNum(Number.parseFloat(val))
								const usdNum = Math.floor((buyTokensNum() * usdPerToken()) * 100) / 100
								setBuyUsdStr(usdNum.toString())
								setBuyUsdNum(usdNum)
							} else {
								setBuyTokensStr(buyTokensStr().toString())
								e.currentTarget.value = buyTokensNum().toString()
							}
						}}
						onBlur={() => setBuyTokensStr(buyTokensNum().toString())}
					/>
				</FormLabel>
			</div>
			<Show when={referralApplied()}>
				<p class={clsx(classes["referral-applied-label"], {
					[classes["active"]]: buyUsdNum() >= (user.bonuses?.min_referral_spend ?? 0),
					[classes["referral-open"]]: referralCodeVisible()
				})}>
					<Show when={buyUsdNum() >= (user.bonuses?.min_referral_spend ?? 0)}>
						{user.bonuses?.referral_payout_percentage}% bonus activated
					</Show>
					<Show when={buyUsdNum() < (user.bonuses?.min_referral_spend ?? 0)}>
						Spend {formatDollar((user.bonuses?.min_referral_spend ?? 0) - buyUsdNum())} more for {user.bonuses?.referral_payout_percentage}% bonus
					</Show>
				</p>
			</Show>
			<div class={clsx(classes["divider"], classes["referral-divider"])} />
			<button class={clsx(classes["referral-code-toggle"], {[classes["referral-open"]]: referralCodeVisible()})} onClick={() => setReferralCodeVisible((vis) => !vis)}>
				{referralCodeVisible() ? "-" : "+"} {referralApplied() ? "Change" : "Add"} Bonus Code
			</button>
			<Show when={referralCodeVisible()}>
				<div class={classes["row"]}>
					<Input
						class={clsx(classes["buy-input"], classes["referral-input"])}
						id="presale-box-referral-code"
						value={referralCode()}
						onInput={(e) => setReferralCode(e.currentTarget.value)}
						placeholder="Bonus Code"
					/>
					<Button color="secondary" class={classes["apply-button"]} onClick={applyReferralCode} disabled={referralApplying()}>
						<Show when={!referralApplying()}>Apply</Show>
						<Show when={referralApplying()}>
							<Spinner class={classes["apply-spinner"]} size={8} />
						</Show>
					</Button>
				</div>
			</Show>
			<Button class={classes["buy-button"]} onClick={buy}>
				Buy Now
			</Button>
			<div class={classes["refer-link-container"]}>
				<OptimizedImage src="/img/link.svg" alt="Link icon" width={20} height={20} />
				<Link href="/refer" class={classes["refer-link"]}>
					Become an affiliate
				</Link>
			</div>
			<TransactionStatusModal
				open={transactionStatus() !== "not_started"}
				status={transactionStatus() === "not_started" ? previousTransactionStatus() as "pending" : transactionStatus() as "pending"}
				onClose={() => setTransactionStatus("not_started")}
				onTryAgain={buy}
			/>
		</>
	)
}