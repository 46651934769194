import { createContext, createEffect, createSignal, onMount, useContext } from "solid-js"
import type { Component } from "~/types"
import { createStore } from "solid-js/store"
import { useIsMobile } from "~/util"
import { useLocation, useNavigate } from "@solidjs/router"
import { Web3Context } from "./Web3Context"
import { BuyContext } from "./BuyContext"

export type INavContextValue = {

}

export type SectionKey = "home" | "join" | "roadmap" | "tokenomics" | "security" | "team" | "contact" | "faq" | "footer"

export interface INavContextFunctions {
	scrollToSection: (section: SectionKey, smooth?: boolean) => void,
	scrollToBuy: () => void,
}

const defaultValue: INavContextValue = {}

const defaultFunctions: INavContextFunctions = {
	scrollToSection: () => {},
	scrollToBuy: () => {}
}

export const NavContext = createContext<[INavContextValue, INavContextFunctions]>([
	defaultValue, defaultFunctions
])

export const NavContextWrapper: Component = (props) => {
	const [ state, setState ] = createStore<INavContextValue>(defaultValue)
	const isMobile = useIsMobile()
	const location = useLocation()
	const navigate = useNavigate()
	const [ searchingSection, setSearchingSection ] = createSignal<SectionKey | null>(null)
	const [ searchSectionY, setSearchSectionY ] = createSignal<number | null>(null)

	const scrollToSection = (section: SectionKey, smooth = true) => {
		if (location.pathname !== "/") {
			navigate(`/?section=${section}`)
			setTimeout(() => {
				setSearchingSection(section)
			}, 100)
		}

		const element = document.getElementById(`${section}-section`)
		if (!element) return
		const { top } = element.getBoundingClientRect()
		window.scrollTo({
			top: top + window.scrollY - (isMobile() ? 64 : 90) + 10,
			behavior: smooth ? "smooth" : "instant"
		})
	}
	
	createEffect(() => {
		const section = searchingSection()
		if (!section) return;
		const element = document.getElementById(`${section}-section`)
		if (!element) return
		const scroll = (): void => {
			const bounds = element.getBoundingClientRect()
			const scrolledToSection = bounds.top >= -100 && bounds.top <= 100
			if (scrolledToSection) {
				setSearchingSection(null);
				setSearchSectionY(null)
				return
			}
			const newY = window.scrollY + bounds.y
			if (Math.abs((searchSectionY() ?? 0) - newY) < 10) {
				setTimeout(scroll, 50)
				return
			};
			setSearchSectionY(newY)
			scrollToSection(section)
			setTimeout(scroll, 50)
		}
		scroll()
	})

	onMount(() => {
		const urlParams = new URLSearchParams(window.location.search)
		const section = urlParams.get("section") as SectionKey | null
		if (!section) return;
		scrollToSection(section, false)
	})

	const [ , buyFuncs ] = useContext(BuyContext)
	const scrollToBuy = () => {
		scrollToSection("home")
		buyFuncs.showBuy()
	}

	const NavValue: [INavContextValue, INavContextFunctions] = [
		state, {
			scrollToSection,
			scrollToBuy
		}
	]

	return (
		<NavContext.Provider value={NavValue}>
			{props.children}
		</NavContext.Provider>
	)
}