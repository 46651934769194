import { JSX, Show, createSignal, splitProps } from "solid-js"
import type { ChildrenType, Component } from "~/types"

import clsx from "clsx"

import classes from "./Input.module.css"
import InputBase from "../InputBase/InputBase"


export type IInputProps = JSX.InputHTMLAttributes<HTMLInputElement> & {
	outerChildren?: ChildrenType,
	units?: JSX.Element | string
}

const Input: Component<IInputProps> = (_props) => {
	const [ props, others ] = splitProps(_props, [
		"class", "outerChildren", "units"
	])
	const [ active, setActive ] = createSignal(false)

	return (
		<InputBase
			class={clsx(classes["base"], props.class, {[classes["active"]]: active()})}
			outerChildren={props.outerChildren}
		>
			<input
				{...others}
				class={clsx(classes["input"], "notranslate")}
				size={0}
				onFocus={(e) => {
					setActive(true);
					(others.onFocus as Function)?.(e)
				}}
				onBlur={(e) => {
					setActive(false);
					(others.onBlur as Function)?.(e)
				}}
			/>
			<Show when={props.units}>
				<div class={classes["units"]}>
					{props.units}
				</div>
			</Show>
		</InputBase>
	)
}

export default Input