import { For, JSX, Show, createEffect, createSignal, mergeProps, on, onCleanup, splitProps } from "solid-js"
import type { ChildrenType, Component, ComponentType, GetPropsFromComponent } from "~/types"

import clsx from "clsx"

import classes from "./Dropdown.module.css"
import { createEventListener, minMax, onElementResize, useBounds, useSize } from "~/util"
import { Portal } from "solid-js/web"

export type IDropdownProps = JSX.HTMLAttributes<HTMLDivElement> & {
	open?: boolean
	onClose?: () => void,
	containerRef: HTMLElement | null
}

const Dropdown: Component<IDropdownProps> = (_props) => {

	const [ props, others ] = splitProps(_props, [
		"open", "onClose", "containerRef", "children"
	])

	const [ dropdownRef, setDropdownRef ] = createSignal<HTMLElement | null>(null)

	const dropdownSize = useSize(dropdownRef)

	const [ position, setPosition ] = createSignal({x: 0, y: 0})

	const calculatePosition = () => {
		if (!props.containerRef) return;
		const { left: containerLeft, bottom: containerBottom } = props.containerRef.getBoundingClientRect()
		const { width: dropdownWidth, height: dropdownHeight } = dropdownSize()
		setPosition({
			x: minMax(containerLeft, 8, window.innerWidth - dropdownWidth - 8),
			y: minMax(containerBottom, 8, window.innerHeight - dropdownHeight - 8)
		})
	}

	createEffect(on(
		() => [dropdownSize().width, dropdownSize().height],
		calculatePosition
	))
	calculatePosition()
	createEventListener(window, "scroll", calculatePosition)
	onElementResize(props.containerRef, calculatePosition)
	createEffect(() => {
		onElementResize(dropdownRef(), calculatePosition)
	})

	const [ hidden, setHidden ] = createSignal(true)

	let timeoutRef: number | null = null
	createEffect(() => {
		if (props.open) return setHidden(false)
		else timeoutRef = window.setTimeout(() => setHidden(true), 400)
		onCleanup(() => clearTimeout(timeoutRef!))
	})

	return (
		<Portal>
			<div
				{...others}
				class={clsx(
					classes["dropdown"],
					others.class,
					{[classes["open"]]: props.open}
				)}
				ref={(el) => setDropdownRef(el)}
				style={{
					left: `${position().x}px`,
					top: `${position().y}px`,
				}}
			>
				<Show when={!hidden()}>
					{props.children}
				</Show>
			</div>
		</Portal>
	)
}

export default Dropdown