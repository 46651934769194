import { JSX, Show, splitProps } from "solid-js"
import type { ChildrenType, Component } from "~/types"

import clsx from "clsx"

import classes from "./Section.module.css"
import { SectionKey } from "~/context/NavContext"

export type ISectionProps = JSX.HTMLAttributes<HTMLElement> & {
	noInner?: boolean
	innerClass?: string
	outerChildren?: ChildrenType,
	id?: `${SectionKey}-section`
}

const Section: Component<ISectionProps> = (_props) => {
	const [ props, others ] = splitProps(_props, [
		"children", "noInner", "innerClass",
		"outerChildren"
	])

	return (
		<section {...others} class={clsx(classes["section"], others.class)}>
			<Show when={props.noInner}>
				{props.children}
				{props.outerChildren}
			</Show>
			<Show when={!props.noInner}>
				{props.outerChildren}
				<div class={clsx(classes["inner"], props.innerClass)}>
					{props.children}
				</div>
			</Show>
		</section>
	)
}

export default Section