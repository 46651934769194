import { For, JSX, Show, createEffect, createSignal, splitProps } from "solid-js"
import type { Component } from "~/types"

import clsx from "clsx"

import classes from "./ConnectWalletModal.module.css"
import Modal, { IModalProps } from "../Modal"
import { SupportedConnection, supportedConnections } from "~/context/Web3Context"
import OptimizedImage from "../OptimizedImage/OptimizedImage"
import Spinner from "../Spinner"
import Button from "../Button"
import Heading from "../Heading/Heading"

import CloseIcon from "~/svg/close-2.svg?component-solid"
import TextStroke from "../TextStroke/TextStroke"

export type IConnectWalletModalProps = IModalProps & {
	onConnect?: (key: SupportedConnection) => Promise<void>
}

const ConnectWalletModal: Component<IConnectWalletModalProps> = (_props) => {
	const [ connectionLoading, setConnectionLoading ] = createSignal(false)

	const [ props, others ] = splitProps(_props, [
		"onConnect"
	])

	const connect = async (key: SupportedConnection) => {
		setConnectionLoading(true)
		await props.onConnect?.(key)
			.catch(() => {})
		setConnectionLoading(false)
		others.onClose?.()
	}

	createEffect(() => {
		if (!others.open) setConnectionLoading(false)
	})

	return (
		<Modal
			{...others}
			class={clsx(classes["modal-root"], others.class)}

		>
			<div class={classes["modal"]}>
				<button class={classes["close-button"]} onClick={others.onClose} aria-label="Close modal">
					<CloseIcon />
				</button>
				<TextStroke
					class={classes["heading"]}
					component="h1"
					shadow
					strokeWidth={4}
				>
					{connectionLoading() ? "Approve via Wallet" : "Connect Your Wallet"}
				</TextStroke>
				<Show when={!connectionLoading()}>
					<div class={classes["items-container"]}>
						<For each={supportedConnections.filter((item) => !item.hide?.())}>
							{(connection) => (
								<Button
									color="white"
									class={classes["connect-item"]}
									onClick={() => connect(connection.key)}
								>
									<OptimizedImage
										class={classes["connect-img"]}
										src={connection.iconSrc}
										width={100}
										height={100}
										alt={`${connection.label} icon`}
									/>
									<span>
									{connection.label}
									</span>
								</Button>
							)}
						</For>
					</div>
				</Show>
				<Show when={connectionLoading()}>
					<Spinner class={classes["spinner"]} size={24} />
				</Show>
			</div>
		</Modal>
	)
}

export default ConnectWalletModal