import { For, JSX, Show, createSignal, splitProps, useContext } from "solid-js"
import type { Component } from "~/types"

import classes from "./Navbar.module.css"
import OptimizedImage from "../OptimizedImage"
import Button from "../Button"
import { useCSSVariable, useIsMobile, shortenString, useClickAway, formatLargeNumber, formatDollar, useScrolled } from "~/util"
import { Link } from "@solidjs/router"
import MenuIcon from "~/svg/menu.svg?component-solid"
import clsx from "clsx"
import TextStroke from "../TextStroke"
import { Web3Context } from "~/context/Web3Context"

import CloseIcon from "~/svg/close.svg?component-solid"
import ReferIcon from "~/svg/refer.svg?component-solid"
import LogoutIcon from "~/svg/logout.svg?component-solid"
import { Dynamic } from "solid-js/web"
import Dropdown from "../Dropdown/Dropdown"
import DropdownIcon from "~/svg/dropdown-shadowed.svg?component-solid"

import WhitepaperIcon from "~/svg/whitepaper-outline.svg?component-solid"
import SecurityIcon from "~/svg/security-outline.svg?component-solid"
import { UserContext } from "~/context/UserContext"
import { StageContext } from "~/context/StageContext"
import { BuyContext } from "~/context/BuyContext"
import { SECURITY_AUDIT_LINK, WHITEPAPER_LINK } from "~/constants/links"
import { NavContext, SectionKey } from "~/context/NavContext"
import LanguageSelect from "../LanguageSelect/LanguageSelect"

export const links: {
	link: string,
	label: string,
	hideMobile?: boolean,
	mobileOnly?: boolean,
	nested?: {link: string, label: string, icon: Component<JSX.SvgSVGAttributes<SVGSVGElement>>}[]
}[] = [
	{link: "/?section=home", label: "Home"},
	{link: "/?section=roadmap", label: "Roadmap"},
	{link: "/?section=tokenomics", label: "Tokenomics"},
	{link: "/?section=team", label: "Team"},
	{link: "/?section=security", label: "Resources", hideMobile: true, nested: [
		{link: WHITEPAPER_LINK, label: "Whitepaper", icon: WhitepaperIcon},
		{link: SECURITY_AUDIT_LINK, label: "Security Audit", icon: SecurityIcon},
	]},
	{link: WHITEPAPER_LINK, label: "Whitepaper", mobileOnly: true},
	{link: SECURITY_AUDIT_LINK, label: "Security Audit", mobileOnly: true},
]

const Navbar: Component = () => {
	const [ mobileOverlayOpen, setMobileOverlayOpen ] = createSignal(false)
	const isMobile = useIsMobile()

	const [ web3, web3Funcs ] = useContext(Web3Context)
	const [ , navFuncs ] = useContext(NavContext)
	const [ userData ] = useContext(UserContext)
	const [ stageData ] = useContext(StageContext)
	const [ buyValue, buyFuncs ] = useContext(BuyContext)

	useCSSVariable("nav-height", () => `calc(${isMobile() ? "4rem" : "5.625rem"} + var(--banner-height))`)
	const scrolled = useScrolled()

	return (
		<nav class={clsx(classes["nav"], {[classes["scrolled"]]: scrolled()})}>
			<Link href="/" aria-label="Home" class={classes["nav-logo-link"]}>
				<OptimizedImage
					class={classes["nav-logo"]}
					src="/img/logo.png"
					alt="Doge Uprising"
					width={506}
					height={152}
				/>
			</Link>
			<Show when={!isMobile()}>
				<div class={classes["nav-links"]}>
					<For each={links.filter((item) => !item.mobileOnly)}>
						{(item) => {
							const [ nestedOpen, setNestedOpen ] = createSignal(false)
							const [ linkRef, setLinkRef ] = createSignal<HTMLElement | null>(null)
							const [ dropdownRef, setDropdownRef ] = createSignal<HTMLElement | null>(null)
							useClickAway(linkRef, () => setNestedOpen(false), () => [dropdownRef()])
							return (
								<>
									<Dynamic
										component={item.nested ? "button" : Link}
										href={item.nested ? undefined : item.link}
										class={classes["nav-link"]}
										onClick={(e: Event) => {
											setNestedOpen((open) => !open)
											if (item.nested) {
												e.preventDefault()
												e.stopPropagation()
												return;
											}
											if (item.link.startsWith("/?section=")) {
												e.preventDefault()
												e.stopPropagation()
												navFuncs.scrollToSection(item.link.replace("/?section=", "") as SectionKey)
												window.history.replaceState({}, "", item.link)
											}
										}}
										ref={(el: HTMLElement) => setLinkRef(el)}
										{...(item.nested ? {} : {target: item.link.startsWith("http") ? "_blank" : undefined})}
									>
										{item.label}
										<Show when={item.nested}>
											<DropdownIcon class={clsx(classes["dropdown-icon"], {[classes["rotated"]]: nestedOpen()})} />
										</Show>
									</Dynamic>
									<Show when={item.nested}>
										<Dropdown
											containerRef={linkRef()}
											open={nestedOpen()}
											onClose={() => setNestedOpen(false)}
											class={classes["dropdown"]}
											ref={(el) => setDropdownRef(el)}
										>
											<For each={item.nested}>
												{(nestedItem) => (
													<Button
														component={Link}
														href={nestedItem.link}
														size="small"
														class={classes["dropdown-button"]}
														target={nestedItem.link.startsWith("http") ? "_blank" : undefined}
													>
														<Dynamic component={nestedItem.icon} />
														{nestedItem.label}
													</Button>
												)}
											</For>
										</Dropdown>
									</Show>
								</>
							)
						}}
					</For>
				</div>
			</Show>
			<div class={classes["buttons"]}>
				<Show when={web3.connected && !isMobile()}>
					<div class={clsx(classes["user-text"], classes["user-text-desktop"])}>
						<OptimizedImage
							class={classes["profile-logo"]}
							src="/img/doge-logo.png"
							alt="A doge face"
							width={506}
							height={152}
						/>
						<p class={classes["user-tokens"]}>{formatLargeNumber(Number.parseFloat(userData.user?.total_tokens ?? "0"))} $DUP</p>
						<p class={classes["user-usd"]}>{formatDollar(Number.parseFloat(userData.user?.total_tokens ?? "0") * Number.parseFloat(stageData.stage?.token_price ?? "0"))}</p>
					</div>
				</Show>
				<Button size="small" component={Link} href="/refer">
					{isMobile() ? "Earn $" : "Earn $"}
				</Button>
				<Button size="small" color="secondary" onClick={() => buyFuncs.showBuy(true)}>
					{isMobile() ? "Buy" : "Buy Now"}
				</Button>
				<Show when={web3.connected && !isMobile()}>
					<Button
						size="small"
						color="tertiary"
						aria-label="Disconnect"
						class={classes["logout-button-desktop"]}
						onClick={web3Funcs.disconnect}
					>
						<LogoutIcon />
					</Button>
				</Show>
			</div>
			<Show when={web3.connected && isMobile()}>
				<div class={clsx(classes["user-text"], classes["user-text-mobile"])}>
					<OptimizedImage
						class={classes["profile-logo"]}
						src="/img/doge-logo.png"
						alt="A doge face"
						width={506}
						height={152}
					/>
					<p class={classes["user-tokens"]}>{formatLargeNumber(Number.parseFloat(userData.user?.total_tokens ?? "0"))} $DUP</p>
					<p class={classes["user-usd"]}>{formatDollar(Number.parseFloat(userData.user?.total_tokens ?? "0") * Number.parseFloat(stageData.stage?.token_price ?? "0"))}</p>
				</div>
			</Show>
			<Button
				size="square"
				variant="styless"
				class={classes["menu-button"]}
				onClick={() => setMobileOverlayOpen(true)}
				aria-label={mobileOverlayOpen() ? "Close menu" : "Open menu"}
			>
				<MenuIcon />
			</Button>
			<NavMobileOverlay
				open={mobileOverlayOpen()}
				onClose={() => setMobileOverlayOpen(false)}
			/>
		</nav>
	)
}

export default Navbar

export interface INavMobileOverlayProps {
	open: boolean
	onClose: () => void
}

export const NavMobileOverlay: Component<INavMobileOverlayProps> = (props) => {
	const [ web3, web3Funcs ] = useContext(Web3Context)
	const [ buyValue, buyFuncs ] = useContext(BuyContext)
	const [ , navFuncs ] = useContext(NavContext)
	const [ userData ] = useContext(UserContext)
	const [ stageData ] = useContext(StageContext)

	return (
		<div
			class={clsx(
				classes["nav-mobile-overlay"],
				{[classes["open"]]: props.open}
			)}
		>
			<div class={classes["nav-mobile-overlay-inner"]}>
				<div class={classes["overlay-top"]}>
					<div class={classes["user-text"]}>
						<Show when={web3.connected}>
							<OptimizedImage
								class={classes["profile-logo"]}
								src="/img/doge-logo.png"
								alt="A doge face"
								width={75}
								height={67}
							/>
							<p class={classes["user-tokens"]}>{formatLargeNumber(Number.parseFloat(userData.user?.total_tokens ?? "0"))} $DUP</p>
							<p class={classes["user-usd"]}>{formatDollar(Number.parseFloat(userData.user?.total_tokens ?? "0") * Number.parseFloat(stageData.stage?.token_price ?? "0"))}</p>
						</Show>
					</div>
					<Button
						size="square"
						color="tertiary"
						onClick={props.onClose}
						class={classes["close-button"]}
						aria-label="Close menu"
					>
						<CloseIcon />
					</Button>
				</div>
				<div class={classes["overlay-link-box"]}>
					<For each={links.filter((item) => !item.hideMobile)}>
						{(item) => (
							<Link
								href={item.link}
								class={classes["overlay-link"]}
								onClick={(e) => {
									if (item.link.startsWith("/?section=")) {
										e.preventDefault()
										e.stopPropagation()
										navFuncs.scrollToSection(item.link.replace("/?section=", "") as SectionKey)
										window.history.replaceState({}, "", item.link)
									}
									props.onClose?.()
								}}
								target={item.link.startsWith("http") ? "_blank" : undefined}
							>
								<TextStroke strokeWidth={2}>
									{item.label}
								</TextStroke>
							</Link>
						)}
					</For>
					<div class={classes["divider"]} />
					<For each={links.filter((item) => item.mobileOnly)}>
						{(item) => (
							<Link
								href={item.link}
								class={classes["overlay-sublink"]}
								onClick={(e) => {
									if (item.link.startsWith("/?section=")) {
										e.preventDefault()
										e.stopPropagation()
										navFuncs.scrollToSection(item.link.replace("/?section=", "") as SectionKey)
										window.history.replaceState({}, "", item.link)
									}
									props.onClose?.()
								}}
								target={item.link.startsWith("http") ? "_blank" : undefined}
							>
								{item.label}
							</Link>
						)}
					</For>
					<Button
						class={classes["big-buy-button"]}
						color="white"
						onClick={() => {
							buyFuncs.showBuy(true)
							props.onClose()
						}}
					>
						Buy Now
					</Button>
					<Button
						class={classes["refer-button"]}
						color="secondary"
						component={Link}
						href="/refer"
						onClick={props.onClose}
					>
						<ReferIcon />
						Refer a Friend
					</Button>
				</div>
				<Show when={web3.connected}>
					<Button
						size="small"
						color="tertiary"
						onClick={() => {
							web3Funcs.disconnect()
							props.onClose()
						}}
						class={classes["logout-button"]}
					>
						<LogoutIcon />
						{shortenString(web3.account ?? "", 13)}
					</Button>
				</Show>
			</div>
		</div>
	)
}