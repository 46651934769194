import clsx from "clsx"
import { createContext, createEffect, For, JSX, Match, mergeProps, Show, splitProps, Switch, useContext } from "solid-js"
import { createStore } from "solid-js/store"
import { Dynamic } from "solid-js/web"
import { createRandom, createRandoms } from "~/util"
import classes from "./Loader.module.css"
import { ChildrenType, Component, ComponentType, GetPropsFromComponent } from "~/types"

export interface LoaderProps {
	loading: boolean
}

export interface LoaderContext {
	loading: boolean
}

const defaultValue = { loading: false }

export const LoaderContext = createContext<LoaderContext>(defaultValue)

export const Loader: Component<LoaderProps> = (props) => {
	let [ loadingState, setLoadingState ] = createStore<LoaderContext>(defaultValue)

	createEffect(() => {
		setLoadingState("loading", props.loading)
	})
	
	return (
		<LoaderContext.Provider value={loadingState}>
			{props.children}
		</LoaderContext.Provider>
	)
}

export interface IBaseLoadableProps {
	variant?: "text" | "block" | "none",
	loadStyles?: Record<string, any>,
	loadElement?: JSX.Element,
	loadClass?: string,
	full?: boolean,
	length?: number,
	invisible?: boolean,
	error?: boolean
	errorElement?: JSX.Element
}

export type LoadableProps<Comp extends ComponentType> =
	Omit<GetPropsFromComponent<Comp>, keyof IBaseLoadableProps | "component"> &
	IBaseLoadableProps & {
		component?: Comp,
		children?: ChildrenType,
		class?: string
	}

export const Loadable = <Comp extends ComponentType = "div">(__props: LoadableProps<Comp>) => {
	const _props = mergeProps({
		variant: "text", loadStyles: {}
	}, __props)

	const [ props, others ] = splitProps(_props, [
		"component", "children", "loadStyles",
		"loadElement", "variant", "loadClass",
		"full", "length", "invisible", "error",
		"errorElement"
	])
	const loadValue = useContext(LoaderContext)

	const allowedLoaderElements: (keyof JSX.IntrinsicElements)[] = [
		"a", "p", "h1", "h2", "h3", "h4", "h5", "h6", "label",
		"span"
	]

	const loadingComponent: () => keyof JSX.IntrinsicElements = () =>
		(allowedLoaderElements.includes(props.component as keyof JSX.IntrinsicElements) ?
			props.component : "div") as keyof JSX.IntrinsicElements

	const random = createRandom(3, 7)

	return (
		<>
			<Switch>
				<Match when={props.loadElement !== undefined && loadValue.loading}>
					<Dynamic
						component={loadingComponent()}
						class={clsx(classes["loadable"], classes[props.variant], props.loadClass, others.class)}
						classList={{[classes["full"]]: props.full, [classes["invisible"]]: props.invisible}}
						style={{
							...props.loadStyles,
							"--length": `${props.length || random()}em`
						}}
					>
						{props.loadElement}
					</Dynamic>
				</Match>
				<Match when={loadValue.loading}>
					<Dynamic
						component={loadingComponent()}
						class={clsx(classes["loadable"], classes[props.variant], props.loadClass, others.class)}
						classList={{[classes["full"]]: props.full, [classes["invisible"]]: props.invisible}}
						style={{
							...props.loadStyles,
							"--length": `${props.length || random()}em`
						}}
					>
					</Dynamic>
				</Match>
				<Match when={props.error}>
					{props.errorElement}
				</Match>
				<Match when={!props.component}>
					{props.children}
				</Match>
				<Match when={true}>
					<Dynamic
						{...others}
						component={props.component ?? "div"}
					>
						{props.children}
					</Dynamic>
				</Match>
			</Switch>
		</>
	)
}