import { JSX, createEffect, createMemo, createSignal, splitProps } from "solid-js"
import type { Component } from "~/types"

import clsx from "clsx"

import classes from "./TopBanner.module.css"
import { useBounds, useCSSVariable } from "~/util"
import { Link } from "@solidjs/router"

import CloseIcon from "../../svg/close-2.svg"

export type ITopBannerProps = JSX.HTMLAttributes<HTMLDivElement> & {

}

const TopBanner: Component<ITopBannerProps> = (_props) => {
	const [ props, others ] = splitProps(_props, [])
	const [ ref, setRef ] = createSignal<HTMLElement | null>(null)
	const [ containerRef, setContainerRef ] = createSignal<HTMLElement | null>(null)
	const size = useBounds(ref)
	const containerSize = useBounds(containerRef)

	const [ closed, setClosed ] = createSignal(false)

	useCSSVariable("banner-height", () => `${closed() ? 0 : containerSize().height}px`)

	return (
		<div {...others} class={clsx(classes["banner"], others.class, {[classes["closed"]]: closed()})} ref={setContainerRef}>
			<div
				class={classes["banner-inner"]}
				style={{"--offset": `${size().width}px`}}
			>
				<TopBannerText ref={setRef} />
				<TopBannerText />
				<TopBannerText />
				<TopBannerText />
				<TopBannerText />
				<TopBannerText />
				<TopBannerText />
			</div>
			<button class={classes["close-button"]} onClick={() => setClosed(true)}>
				<CloseIcon />
			</button>
		</div>
	)
}

export default TopBanner

export type ITopBannerTextProps = JSX.HTMLAttributes<HTMLDivElement> & {
	ref?: (el: HTMLDivElement) => void
}

export const TopBannerText: Component<ITopBannerTextProps> = (_props) => {
	const [ props, others ] = splitProps(_props, ["ref"])

	return (
		<div {...others} class={clsx(classes["banner-text"], others.class)} ref={props.ref}>
			<div>
				NOW LAUNCHING ON <span class={classes["highlight"]}>SOLANA!</span>
			</div>
			<div>
				USE CODE <span class={classes["highlight"]}>MEMESEASON</span> FOR BONUS!
			</div>
		</div>
	)
}
