import { JSX, splitProps } from "solid-js"
import type { Component } from "~/types"

import clsx from "clsx"

import classes from "./Modal.module.css"
import { Portal } from "solid-js/web"

export interface IModalClasses {
	root?: string,
	modal?: string
}

export type IModalProps = JSX.HTMLAttributes<HTMLDivElement> & {
	classes?: IModalClasses,
	open?: boolean,
	onClose?: () => void,
}

const Modal: Component<IModalProps> = (_props) => {
	const [ props, others ] = splitProps(_props, [
		"open", "onClose", "children", "classes"
	])

	return (
		<Portal>
			<div
				{...others}
				class={clsx(
					classes["modal-container"],
					props.classes?.root,
					{[classes["open"]]: props.open}
				)}
			>
				<div class={classes["overlay"]} onClick={props.onClose} />
				<div class={clsx(classes["modal"], props.classes?.modal, others.class)}>
					{props.children}
				</div>
			</div>
		</Portal>
	)
}

export default Modal