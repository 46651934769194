import { JSX, createSignal, splitProps, useContext } from "solid-js"
import type { Component } from "~/types"

import clsx from "clsx"

import classes from "./ContactForm.module.css"
import FormLabel from "../FormLabel/FormLabel"
import Input from "../Input/Input"
import PhoneInput, { PhoneValue } from "../PhoneInput/PhoneInput"
import Button from "../Button/Button"
import { isEmailValid } from "~/util/validation.util"
import { Web3Context } from "~/context/Web3Context"
import toast from "solid-toast"
import { getApiErrorMessage, postLeads } from "~/util"


export type IContactFormProps = JSX.HTMLAttributes<HTMLFormElement> & {
	onSubmitSuccess?: () => void
}

let globalId = 0

const ContactForm: Component<IContactFormProps> = (_props) => {
	const [ props, others ] = splitProps(_props, ["onSubmitSuccess"])

	const [ name, setName ] = createSignal("")
	const [ email, setEmail ] = createSignal("")
	const [ phone, setPhone ] = createSignal<PhoneValue | null>(null)
	const [ web3 ] = useContext(Web3Context)

	let id = globalId++;
	
	const [ detailsSending, setDetailsSending ] = createSignal(false)

	const onSubmit = async (e: Event) => {
		e.preventDefault()

		const data = {
			name: name(),
			email: email(),
			mobile: phone()?.number ? `${phone()?.country.dialCode} ${phone()?.number}` : undefined,
			wallet_address: web3.account ?? undefined
		}
		if (!data.name) return toast.error("Name is required")
		if (!data.email) return toast.error("Email is required")
		if (!isEmailValid(data.email)) return toast.error("Email is invalid")

		setDetailsSending(true)
		try {
			await postLeads(data)
			toast.success("Successfully submitted details")
		} catch(e) {
			toast.error(getApiErrorMessage(e, "Failed to submit details"))
		}
		setDetailsSending(false)
		props.onSubmitSuccess?.()
	}

	return (
		<form
			{...others}
			class={clsx(classes["contact-form"], others.class)}
			onSubmit={onSubmit}
		>
			<FormLabel label="Full Name*" for={`contact-form-${id}-name`}>
				<Input
					autocomplete="name"
					autoCapitalize="words"
					value={name()}
					onInput={(e) => setName(e.currentTarget.value)}
					id={`contact-form-${id}-name`}
					placeholder="E.g. John Doe"
				/>
			</FormLabel>
			<FormLabel label="Email*" for={`contact-form-${id}-email`}>
				<Input
					autocomplete="email"
					autoCapitalize="off"
					value={email()}
					onInput={(e) => setEmail(e.currentTarget.value)}
					id={`contact-form-${id}-email`}
					placeholder="Email"
				/>
			</FormLabel>
			<FormLabel label="Phone Number" for={`contact-form-${id}-phone`}>
				<PhoneInput
					value={phone()}
					onChange={(newPhone) => setPhone(newPhone)}
					id={`contact-form-${id}-phone`}
				/>
			</FormLabel>
			<Button class={classes["button"]} type="submit" disabled={detailsSending()}>
				Subscribe
			</Button>
		</form>
	)
}

export default ContactForm