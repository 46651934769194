import type { Component } from "~/types"

import classes from "./SecuritySection.module.css"
import Section from "~/components/Section"
import Button from "~/components/Button"
import OptimizedImage from "~/components/OptimizedImage"
import { JSX, Show, splitProps } from "solid-js"
import clsx from "clsx"
import CopyIcon from "~/svg/copy.svg?component-solid"
import { CONTRACT_ADDRESS } from "~/constants"
import Heading from "~/components/Heading"
import { copyText, useIsMobile } from "~/util"
import { Link } from "@solidjs/router"
import { SECURITY_AUDIT_LINK, WHITEPAPER_LINK } from "~/constants/links"
import OptimizedVideo from "~/components/OptimizedVideo/OptimizedVideo"

const SecuritySection: Component = () => {
	const isMobile = useIsMobile()
	const copyAddress = () => copyText(CONTRACT_ADDRESS)

	return (
		<Section class={classes["section"]} innerClass={classes["section-inner"]} id="security-section">
			<Heading
				topLabel="Buy with confidence"
				heading="Security"
				headingColor="white"
				class={classes["heading-container"]}
			/>
			<Show when={isMobile()}>
				<OptimizedImage
					class={classes["bg-video"]}
					src="/img/grainy-background-cropped-mobile.png"
					aria-hidden
					alt="Grainy background"
					width={400}
					height={544}
				/>
			</Show>
			<Show when={!isMobile()}>
				<OptimizedImage
					class={classes["bg-video"]}
					src="/img/grainy-background.png"
					aria-hidden
					alt="Grainy background"
					width={2162}
					height={1306}
				/>
			</Show>
			<div class={classes["cards-container"]}>
				<SecurityCard
					title={<>Security<br />Audit</>}
					buttonText="Read"
					buttonLink={SECURITY_AUDIT_LINK}
					buttonAlt="Security Audit"
					imgAlt="A padlock"
					imgSrc="/img/lock.png"
					imgClass={classes["lock-img"]}
					imgSize={[458, 519]}
				/>
				<SecurityCard
					title={<>White<br />Paper</>}
					buttonText="Read"
					buttonLink={WHITEPAPER_LINK}
					imgAlt="A piece of paper"
					imgSrc="/img/crane.png"
					imgClass={classes["crane-img"]}
					imgContainerClass={classes["crane-img-container"]}
					imgSize={[370, 331]}
				/>
			</div>
			<Button size="small" class={classes["contract-button"]} onClick={copyAddress}>
				$DUP Token Address
				<span>{CONTRACT_ADDRESS} <CopyIcon style={{"margin-left": "0.5rem"}} /></span>
			</Button>
		</Section>
	)
}

export default SecuritySection

export type ISecurityCardProps = Omit<JSX.HTMLAttributes<HTMLDivElement>, "title"> & {
	imgSrc: string,
	imgSize: [number, number],
	imgAlt: string,
	title: JSX.Element,
	buttonText: string,
	imgClass?: string,
	imgContainerClass?: string,
	buttonLink?: string,
	buttonAlt?: string,
}

export const SecurityCard: Component<ISecurityCardProps> = (_props) => {
	const [ props, others ] = splitProps(_props, [
		"imgSrc", "imgSize", "imgAlt", "title",
		"buttonText", "imgClass", "imgContainerClass",
		"buttonLink", "buttonAlt"
	])

	return (
		<div class={classes["card"]}>
			<div class={classes["card-text-container"]}>
				<h2 class={classes["card-heading"]}>
					{props.title}
				</h2>
				<Button
					component={props.buttonLink ? Link : "button"}
					size="small"
					class={classes["card-button"]}
					aria-label={props.buttonAlt}
					{...(props.buttonLink ? {
						href: props.buttonLink,
						target: "_blank",
						rel: "noopener noreferrer"
					} : {})}
				>
					{props.buttonText}
				</Button>
			</div>
			<div class={clsx(classes["card-img-container"], props.imgContainerClass)}>
				<OptimizedImage
					class={clsx(classes["card-img"], props.imgClass)}
					src={props.imgSrc}
					alt={props.imgAlt}
					width={props.imgSize[0]}
					height={props.imgSize[1]}
				/>
			</div>
		</div>
	)
}