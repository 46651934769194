import { JSX, splitProps, useContext } from "solid-js"
import type { Component } from "~/types"

import clsx from "clsx"

import classes from "./ReferralBox.module.css"
import { UserContext } from "~/context/UserContext"
import { copyText, formatDollar, formatNumber } from "~/util"
import TextStroke from "../TextStroke"
import Button from "../Button/Button"
import CopyIcon from "~/svg/copy-tall.svg?component-solid"
import { ShareContext } from "~/context/ShareContext"
import OptimizedImage from "../OptimizedImage"


export type IReferralBoxProps = JSX.HTMLAttributes<HTMLDivElement> & {

}

const ReferralBox: Component<IReferralBoxProps> = (_props) => {
	const [ props, others ] = splitProps(_props, [])

	const [ user ] = useContext(UserContext)
	const [ , shareFuncs ] = useContext(ShareContext)

	const shareCode = () => {
		if (!user.user) return;
		shareFuncs.share({
			copyText: user.user.referral_code ?? "",
			shareText: [
				{text: "Hey there! 👋 Use my code ", lowercase: false},
				{text: user.user.referral_code ?? "", lowercase: true},
				{text: " to buy $DUP tokens and join the uprising!", lowercase: false}
			],
			title: "Share Bonus Code",
			subheading: "Climb the leaderboard and earn USD paid straight to your wallet!",
			copyLabel: "Copy Code",
			shareUrl: import.meta.env.APP_HOST_URL + "/?code=" + (user.user.referral_code ?? "")
		})
	}

	return (
		<div {...others} class={clsx(classes["referral-box"], others.class)}>
			<div class={classes["top-container"]}>
				<div class={classes["col"]}>
					<TextStroke shadow class={classes["num-value"]}>{formatNumber(user.user?.referral_count ?? 0)}</TextStroke>
					<p class={classes["label"]}>Your Referrals</p>
				</div>
				<div class={classes["col"]}>
					<TextStroke shadow class={classes["num-value"]}>$ {formatDollar(user.user?.total_referral_usd_rewards ?? 0, false)}</TextStroke>
					<p class={classes["label"]}>Earnings from Referrals</p>
				</div>
			</div>
			<div class={classes["bottom-container"]}>
				<div class={classes["code-col"]}>
					<p class={classes["label"]}>Bonus Code</p>
					<button class={classes["code-button"]} onClick={() => copyText(user.user?.referral_code ?? "")}>
						<TextStroke component="p" strokeWidth={5}>
							{user.user?.referral_code ?? "XXXXXXX"}
						</TextStroke>
						<CopyIcon />
					</button>
				</div>
				<Button class={classes["share-button"]} onClick={shareCode}>
					Share Code
				</Button>
			</div>
			<OptimizedImage
				class={classes["coin-1"]}
				src="/img/coin.svg"
				alt="A coin"
				aria-hidden
				width={74}
				height={47}
			/>
			<OptimizedImage
				class={classes["coin-2"]}
				src="/img/coin-2.svg"
				alt="A coin"
				aria-hidden
				width={102}
				height={96}
			/>
		</div>
	)
}

export default ReferralBox