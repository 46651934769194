import { createContext, createEffect, createSignal, onMount } from "solid-js"
import type { Component } from "~/types"
import { createStore } from "solid-js/store"


import type { InterfaceAbi, ethers as ethersType } from "ethers"

import ConnectWalletModal from "~/components/ConnectWalletModal"

import toast from "solid-toast"
import { BrowserCrossProvider, CrossProvider, WalletConnectCrossProvider } from "~/types/CrossProvider.types"
import type { WalletConnectModalSign } from "@walletconnect/modal-sign-html"

import { isMobileDevice, limitDecimals, useTimeout } from "~/util"
export type Connection = {
	label: string,
	key: SupportedConnection,
	iconSrc: string,
	hide?: () => boolean
}

export type SupportedConnection = "metamask" | "walletconnect"

export const supportedConnections = [
	{
		label: "Wallet Connect",
		key: "walletconnect",
		iconSrc: "/img/wallet-connect.svg"
	},
	{
		label: "Metamask",
		key: "metamask",
		iconSrc: "/img/metamask.svg",
		hide: () => !window.ethereum
	},
] satisfies Connection[]


export type IWeb3ContextValue = {
	connected: boolean,
	account: string | null,
	connectModalOpen: boolean,
	connectedConnection: SupportedConnection | null
}

export interface IWeb3ContextFunctions {
	showConnectModal: () => void,
	connect: (connection: SupportedConnection) => Promise<void>,
	disconnect: () => Promise<void>,
	sendTransaction: (args: TransactionArgs) => Promise<void>,
	signMessage: (message: string) => Promise<string>
}

const defaultValue: IWeb3ContextValue = {
	connected: false,
	account: null,
	connectModalOpen: false,
	connectedConnection: null
}

const defaultFunctions: IWeb3ContextFunctions = {
	connect: () => Promise.reject("No context provided"),
	showConnectModal: () => Promise.reject("No context provided"),
	disconnect: () => Promise.reject("No context provided"),
	sendTransaction: () => Promise.reject("No context provided"),
	signMessage: () => Promise.reject("No context provided"),
}

export type TransactionArgs = {
	to: string,
	value: string,
	chainId: number,
	native: boolean,
	contractAddress?: string,
	abi?: InterfaceAbi,
	decimals?: number
}

export const Web3Context = createContext<[IWeb3ContextValue, IWeb3ContextFunctions]>([
	defaultValue, defaultFunctions
])

export const Web3ContextWrapper: Component = (props) => {
	const [ state, setState ] = createStore<IWeb3ContextValue>(defaultValue)
	const [ ethersSignal, setEthersSignal ] = createSignal<typeof ethersType | null>(null)
	const [ providerSignal, setProviderSignal ] = createSignal<CrossProvider | null>(null)
	const localConnected = localStorage.getItem("connectedWalletType") as SupportedConnection | null
	const [ modalSignal, setModalSignal ] = createSignal<WalletConnectModalSign | null>(null)
	const [ loadedData, setLoadedData ] = createSignal(false)

	createEffect(() => {
		if (state.connectedConnection) localStorage.setItem("connectedWalletType", state.connectedConnection)
		else localStorage.removeItem("connectedWalletType")
	})

	const getModal = async (): Promise<WalletConnectModalSign> => {
		const { ethers } = await import("ethers")
		setEthersSignal(ethers)
		const { WalletConnectModalSign } = await import("@walletconnect/modal-sign-html")
		const modal = new WalletConnectModalSign({
			projectId: import.meta.env.APP_WALLET_CONNECT_PROJECT_ID,
			metadata: {
				name: "Doge Uprising",
				description: "Doge Uprising",
				url: "https://dogeuprising.co",
				icons: ["https://dogeuprising.com/img/doge-logo.png"],
			},
		})
		setModalSignal(modal)
		return modal
	}

	createEffect(async () => {
		if (!state.connectModalOpen || loadedData()) return;
		setLoadedData(true)
		getModal()
	})

	const detectMetamaskConnection = async () => {
		if (!window.ethereum) return;
		const accounts = await window.ethereum.request({ method: "eth_accounts" })
		if (accounts.length > 0) {
			await disconnect()
			await connectMetamask()
		}
	}

	const detectWalletConnectConnection = async () => {
		const modal = await getModal()
		const session = await modal.getSession()
		if (session) {
			const crossProvider = await WalletConnectCrossProvider.from(modal as any)
			const account = await crossProvider.getAccount()
			
			setProviderSignal(crossProvider)
			setState("connected", true)
			setState("account", account)
			setState("connectedConnection", "walletconnect")
		}
	}

	const setConnectedConnection = (connectedConnection: SupportedConnection | null) => {
		setState("connectedConnection", connectedConnection)
	}

	createEffect(() => {
		if (localConnected === "metamask") detectMetamaskConnection()
		else if (localConnected === "walletconnect") detectWalletConnectConnection()
	})

	const connectMetamask = async () => {
		if (!window.ethereum) return;

		const crossProvider = await BrowserCrossProvider.from(window.ethereum)

		const account = await crossProvider.getAccount()

		setState("account", account)
		setProviderSignal(crossProvider)
		setConnectedConnection("metamask")
		setState("connected", true)
		window.ethereum.on("accountsChanged", (accounts: string[]) => {
			if (accounts.length === 0) return disconnect()
			setState("account", accounts[0])
		})
	}

	const connectWalletConnect = async () => {
		let modal = modalSignal()
		if (!modal) modal = await getModal()
		setState("connectedConnection", "walletconnect")
		await modal.connect({
			requiredNamespaces: {
				eip155: {
					methods: ["eth_sendTransaction", "eth_sign", "personal_sign"],
					chains: ["eip155:1"],
					events: ["accountsChanged", "chainChanged"]
				}
			},
			optionalNamespaces: {
				eip155: {
					methods: ["eth_sendTransaction", "eth_sign", "personal_sign", "eth_chainId", "wallet_switchEthereumChain"],
					chains: ["eip155:56"],
					events: ["accountsChanged", "chainChanged"]
				}
			}
		})
		const crossProvider = await WalletConnectCrossProvider.from(modal as any)
		const account = await crossProvider.getAccount()
		
		setProviderSignal(crossProvider)
		setState("connected", true)
		setState("account", account)
	}

	const connectFunctions: Record<SupportedConnection, () => Promise<void>> = {
		metamask: connectMetamask,
		walletconnect: connectWalletConnect
	}

	const connect = async (connection: SupportedConnection) => {
		await connectFunctions[connection]()
		const provider = providerSignal()
		if (!provider) return;
		provider.onDisconnect(disconnect)
		setState("connectModalOpen", false)
	}

	const disconnect = async () => {
		const provider = providerSignal()
		if (provider) await provider.disconnect()
		setProviderSignal(null)
		setState("account", null)
		setState("connected", false)
		setConnectedConnection(null)
	}

	const handleErrors = (e: unknown) => {
		if (typeof e === "object" && e !== null && "code" in e) {
			if (e.code === "INSUFFICIENT_FUNDS") {
				toast.error("Insufficient funds")
			}
		}
	}

	const sendTransaction = async (args: TransactionArgs) => {
		args.value = limitDecimals(Number.parseFloat(args.value), (args.decimals ?? 18) - 1)
		if (args.value.includes("e-")) throw "Value too small"
		else if (args.value.includes("e")) throw "Value too large"
		try {
			const provider = providerSignal()
			if (!provider) throw "No provider"
			const chainId = await provider.getChainId()
			const IS_MULTICHAIN = chainId === null
			if (!IS_MULTICHAIN && args.chainId !== chainId) {
				await provider.switchChain(args.chainId)
			}

			if (args.native) {
				await provider.sendNativeTransaction({
					chainId: args.chainId,
					to: args.to,
					value: args.value,
				})
			} else {
				if (!args.abi) throw "No abi provided"
				if (!args.contractAddress) throw "No contract address provided"
				await provider.transferToContract({
					abi: args.abi,
					contractAddress: args.contractAddress,
					data: {
						to: args.to,
						value: args.value
					},
					decimals: args.decimals ?? 18,
					chainId: args.chainId
				})
			}
			
		} catch(e: unknown) {
			handleErrors(e)
			throw e;
		}
	}

	const signMessage = async (data: string): Promise<string> => {
		if (!state.connected) throw "Not connected";
		const provider = providerSignal()
		if (!provider) throw "No provider"
		return provider.signMessage(data)
	}

	const Web3Value: [IWeb3ContextValue, IWeb3ContextFunctions] = [
		state, {
			connect,
			showConnectModal: () => {
				setState("connectModalOpen", true)
			},
			disconnect,
			sendTransaction,
			signMessage
		}
	]

	return (
		<Web3Context.Provider value={Web3Value}>
			<ConnectWalletModal
				open={state.connectModalOpen}
				onClose={() => setState("connectModalOpen", false)}
				onConnect={(key) => connect(key)}
			/>
			{props.children}
		</Web3Context.Provider>
	)
}