import { API } from "~/types"

const baseUrl = import.meta.env.APP_API_BASE_URL
const leadsBaseUrl = import.meta.env.APP_LEADS_API_BASE_URL
const project = import.meta.env.APP_API_PROJECT_ID

export const apiFetch = async <T>(url: string, options?: RequestInit): Promise<T> => {
	if (!url.startsWith("http")) url = `${baseUrl}${url}`
	const res = await fetch(url, options)
	try {
		const json: T = await res.json()
		const apiResponse: API.Res<T> =  {...res, body: json as T}
		if (!res.ok) throw apiResponse
		return json
	} catch(err) {
		throw err;
	}
}

export const getUserIPLookup = () => {
	return apiFetch<API.IPLookup>("https://api.countryin.fo/me")
}

export const getActiveStage = () => {
	return apiFetch<API.Stage>(`/projects/${project}/stages/current`)
}

export const getUser = (address: string) => {
	return apiFetch<API.User>(`/projects/${project}/wallet/${address}`)
}

export const getLeaderboard = () => {
	return apiFetch<API.LeaderboardEntry[]>(`/projects/${project}/leaderboard`)
}

export const postLeads = (args: {
	name?: string,
	email: string,
	mobile?: string | null,
	wallet_address?: string,
	preferred_contact_time?: string,
	investment_amount?: string,
}) => {
	return apiFetch<API.User>(`${leadsBaseUrl}/projects/${project}/leads`, {
		method: "POST",
		body: JSON.stringify(args),
		headers: {
			"Content-Type": 'application/json'
		}
	})
}

export const getPrices = () => {
	return apiFetch<API.Price[]>(`/projects/${project}/payment-tokens`)
}

export const getApiErrorMessage = (e: unknown, defaultMsg?: string) => {
	if (typeof(e) === "string") return e;
	if (e && typeof(e) === "object" && "body" in e) {
		if (e.body && typeof(e.body) === "object" && "message" in e.body && typeof(e.body.message) === "string") {
			const errors = e.body.message.split(" | ")
			const error = errors[0]
			if (typeof(error) === "string" && !error.includes("Message: ")) return error
			const msg = error.split("Message: ")[1] ?? defaultMsg
			let path = (error.split("Path: ")[1] ?? "").split(" ~ ")[0]
			path = path.split("body.")[1] ?? path
			path = path.split("_").map((str) => str ? str[0].toUpperCase() + str.slice(1) : "").join(" ")

			if (path) return `${path} - ${msg}`
			return msg ?? defaultMsg
		}
	}
	return defaultMsg ?? "Internal server error"
}

export const updateReferralCode = (token: string, address: string, referralCode: string) => {
	return apiFetch<API.User>(`/projects/${project}/wallet/${address}`, {
		method: "PATCH",
		body: JSON.stringify({
			referral_code: referralCode
		}),
		headers: {
			"Content-Type": 'application/json',
			"Authorization": `Bearer ${token}`
		}
	})
}

export const getNonce = (walletAddress: string) => {
	return apiFetch<{nonce: string}>(`/projects/${project}/wallet/${walletAddress}/nonce`)
}

export const postSignedMessage = (walletAddress: string, signedMessage: string) => {
	return apiFetch<{access: API.Token}>(`/projects/${project}/wallet/${walletAddress}/signature`, {
		method: "POST",
		body: JSON.stringify({signature: signedMessage}),
		headers: {
			"Content-Type": 'application/json'
		}
	})
}

export const getReferralBonuses = (walletAddress: string) => {
	return apiFetch<API.ReferralBonuses>(`/projects/${project}/wallet/${walletAddress}/referrals/bonuses`)
}