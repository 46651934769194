import { createContext, createEffect, createSignal, useContext } from "solid-js"
import type { Component } from "~/types"
import { createStore } from "solid-js/store"
import { Web3Context } from "./Web3Context"
import { useLocation, useNavigate } from "@solidjs/router"

export type IBuyContextValue = {
	buying: boolean
}

export interface IBuyContextFunctions {
	showBuy: (scroll?: boolean) => void,
}

const defaultValue: IBuyContextValue = {
	buying: false
}

const defaultFunctions: IBuyContextFunctions = {
	showBuy: () => {}
}

export const BuyContext = createContext<[IBuyContextValue, IBuyContextFunctions]>([
	defaultValue, defaultFunctions
])

export const BuyContextWrapper: Component = (props) => {
	const [ state, setState ] = createStore<IBuyContextValue>(defaultValue)

	const [ web3, web3Funcs ] = useContext(Web3Context)
	const [ settingBuy, setSettingBuy ] = createSignal(false)
	const [ scrollingToBuy, setScrollingToBuy ] = createSignal(false)

	createEffect(() => {
		if (state.buying && scrollingToBuy()) {
			window.scrollTo({ top: 0, behavior: "smooth" })
			setScrollingToBuy(false)
		}
	})

	createEffect(() => {
		if (!web3.connectModalOpen && !web3.connected) {
			setSettingBuy(false)
		} else if (settingBuy() && web3.connected) {
			setState("buying", true)
			setSettingBuy(false)
		}
	})

	createEffect(() => {
		if (state.buying && !web3.connected) setState("buying", false)
	})

	const location = useLocation()
	const navigate = useNavigate()

	const showBuy = (scroll = false) => {
		if (!web3.connected) {
			web3Funcs.showConnectModal()
			setSettingBuy(true)
		} else setState("buying", true)
		if (location.pathname !== "/") navigate("/")
		setScrollingToBuy(scroll)
	}

	const BuyValue: [IBuyContextValue, IBuyContextFunctions] = [
		state, {
			showBuy
		}
	]

	return (
		<BuyContext.Provider value={BuyValue}>
			{props.children}
		</BuyContext.Provider>
	)
}