import baseTheme, { SubTheme, Theme } from "~/styles/themes/base.theme"
import deepMerge from "deepmerge"

export const createTheme = <T extends SubTheme>(themeObj: T): Omit<Theme, "key"> & {key: T["key"]} => {
	return deepMerge(
		baseTheme,
		themeObj
	) as Theme
}

export const getValueStr = (key: string, val: unknown) => {
	if (key.includes("zindex") && typeof(val) === "number") return val.toString()
	if (typeof(val) === "number") return `${val}${key.includes("ani") ? "ms" : "px"}`
	return val
}

export const addKeyStr = (key: string, val: unknown) => {
	const suffix = (typeof(val) === "string" && val.endsWith(";")) ? "" : ";"
	return `--${key}: ${getValueStr(key, val)}${suffix}`
}

export const getStyleString = (key: string, val: unknown): string => {
	if (Array.isArray(val)) {
		return val.map((v, i) => {
			return `--${key}-${i}: ${getValueStr(key, v)};`
		}).join("\n")
	} else if (typeof(val) === "object") {
		let newStr = ""
		Object.entries(val as object).forEach((entry, i) => {
			const [ k, v ] = entry
			newStr = newStr + getStyleString(`${key}-${k}`, v)
		})
		return newStr
	}
	return addKeyStr(key, val) + "\n"
}

export const getThemeStyleCSS = (themeObj: Record<string, any>) => {
	const str = `:root {\n${getStyleString("theme", themeObj)}}`
	return str
}