import { For, JSX, createEffect, createSignal, onMount, splitProps } from "solid-js"
import type { API, Component } from "~/types"

import clsx from "clsx"

import classes from "./ReferralLeaderboard.module.css"
import { DEFAULT_LEADERBOARD_ENTRY } from "~/constants/defaults"
import { Loadable, Loader } from "../Loader"
import { formatDollar, formatNumber, getLeaderboard, shortenString } from "~/util"
import OptimizedImage from "../OptimizedImage/OptimizedImage"


export type IReferralLeaderboardProps = JSX.HTMLAttributes<HTMLDivElement> & {

}

const ReferralLeaderboard: Component<IReferralLeaderboardProps> = (_props) => {
	const [ props, others ] = splitProps(_props, [])
	const [ leaderboardFetching, setLeaderboardFetching ] = createSignal(false)
	const [ leaderboardEntries, setLeaderboardEntries ] = createSignal<API.LeaderboardEntry[]>(new Array(10).fill(DEFAULT_LEADERBOARD_ENTRY))

	onMount(async () => {
		setLeaderboardFetching(true)
		const leaderboard = await getLeaderboard()
		setLeaderboardEntries(
			leaderboard.slice(0, 10)
				.sort((a, b) => Number.parseInt(a.rank) - Number.parseInt(b.rank))
		)
		setLeaderboardFetching(false)
	})

	return (
		<Loader loading={leaderboardFetching()}>
			<div {...others} class={clsx(classes["leaderboard"], others.class, {[classes["loading"]]: leaderboardFetching()})}>
				<div class={classes["heading"]}>
					<p>User</p>
					<p>Rewards $USD</p>
				</div>
				<div class={classes["entries"]}>
					<For each={leaderboardEntries()}>
						{(entry) => (
							<div class={classes["entry"]}>
								<Loadable component="p" class={classes["rank-label"]} length={1}>{entry.rank}</Loadable>
								<Loadable
									variant="block"
									component={OptimizedImage}
									src="/img/default-avatar.png"
									alt="Avatar"
									class={classes["avatar"]}
									width={59}
									height={59}
								/>
								<Loadable component="p" class={classes["address-label"]}>{shortenString(entry.wallet_address, 13)}</Loadable>
								<Loadable component="p" class={classes["referrals-label"]}>{formatDollar(Number.parseInt(entry.total_referral_usd_rewards))}</Loadable>
							</div>
						)}
					</For>
				</div>
			</div>
		</Loader>
	)
}

export default ReferralLeaderboard