import { JSX, Show, splitProps, useContext } from "solid-js"
import type { Component } from "~/types"

import clsx from "clsx"

import classes from "./ReferralPage.module.css"
import Page from "~/components/Page/Page"
import Heading from "~/components/Heading/Heading"
import TextStroke from "~/components/TextStroke/TextStroke"
import { Web3Context } from "~/context/Web3Context"
import Button from "~/components/Button/Button"
import ReferralLeaderboard from "~/components/ReferralLeaderboard"
import ReferralBox from "~/components/ReferralBox/ReferralBox"

const ReferralPage: Component = () => {
	const [ web3, web3Funcs ] = useContext(Web3Context)

	return (
		<Page path="/refer" title="Refer" class={classes["refer-page"]}>
			<div class={classes["refer-inner"]}>
				<div class={classes["heading-container"]}>
					<TextStroke component="h1" shadow class={classes["heading"]}>Refer and Earn</TextStroke>
					<p class={classes["label"]}>Every time someone uses your code to purchase $DUP tokens, you'll earn a <span class={classes["bold"]}>generous 10%</span> commission on their investment, automatically sent to your crypto wallet</p>
				</div>
				<Show when={!web3.connected}>
					<Button onClick={web3Funcs.showConnectModal} class={classes["connect-button"]}>
						Connect Wallet
					</Button>
				</Show>
				<Show when={web3.connected}>
					<ReferralBox />
				</Show>
				<ReferralLeaderboard />
			</div>
		</Page>
	)
}

export default ReferralPage