import { For, JSX, createEffect, createSignal, splitProps } from "solid-js"
import type { Component } from "~/types"

import clsx from "clsx"

import classes from "./ProgressBar.module.css"
import { useBounds } from "~/util"

import FuelPumpIcon from "~/svg/fuel-pump.svg"

export type IProgressBarProps = JSX.HTMLAttributes<HTMLDivElement> & {
	fraction: number
}

const ProgressBar: Component<IProgressBarProps> = (_props) => {
	const [ props, others ] = splitProps(_props, ["fraction"])
	const [ ref, setRef ] = createSignal<HTMLElement | null>(null)
	const bounds = useBounds(ref)
	const numBars = () => Math.floor(bounds().width / 16)

	return (
		<div 
			{...others}
			style={{"--frac": props.fraction}}
			class={clsx(classes["progress"], others.class)}
		>
			<div class={classes["progress-bar"]} />
		</div>
	)
}

export default ProgressBar