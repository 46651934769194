import type { Component } from "~/types"

import classes from "./TitleSection.module.css"
import Section from "~/components/Section/Section"
import OptimizedImage from "~/components/OptimizedImage/OptimizedImage"
import clsx from "clsx"
import PresaleBox from "~/components/PresaleBox/PresaleBox"

const TitleSection: Component = () => {

	return (
		<Section class={classes["title-section"]} noInner id="home-section">
			<div class={classes["backgrounds"]} aria-hidden>
				<div 
					class={clsx(classes["bg"], classes["bg-grainy"])}
				/>
				<OptimizedImage
					src="/img/beamed-grainy-background.png"
					alt="Beamed grainy background"
					class={clsx(classes["bg"], classes["bg-beamed"])}
					width={1362}
					height={990}
					hasSizes
				/>
			</div>
			<div class={classes["title-section-inner"]}>
				<div class={classes["left"]}>
					<PresaleBox />
					<div class={classes["buy-until-text"]}>
						<p class={classes["buy-until-label"]}>Buy in before price increases!</p>
					</div>
				</div>
				<div class={classes["right"]}>
					<div class={classes["right-inner"]}>
						<OptimizedImage
							class={classes["mech-img"]}
							src="/img/doge-mecha-1.png"
							alt="A giant mecha resembling a doge, bearing it's metallic fangs."
							loading="eager"
							width={864}
							height={1189}
						/>
						<OptimizedImage
							class={classes["screech-decal"]}
							src="/img/decal-screech.png"
							alt="A decal signalling the doge mecha is screeching."
							aria-hidden
							width={123}
							height={153}
						/>
						<OptimizedImage
							class={classes["screech-decal-2"]}
							src="/img/decal-screech-2.png"
							alt="A decal signalling the doge mecha is screeching."
							aria-hidden
							width={316}
							height={325}
						/>
						<OptimizedImage
							class={classes["screech-decal-3"]}
							src="/img/decal-screech-3.png"
							alt="A decal signalling the doge mecha is screeching."
							aria-hidden
							width={148}
							height={186}
						/>
					</div>
				</div>
			</div>
		</Section>
	)
}

export default TitleSection