import { createContext, onMount } from "solid-js"
import type { Component } from "~/types"
import { createStore } from "solid-js/store"
import { createInterval } from "~/util"

import * as api from "~/util/api.util"

const supportedCoingeckoTokens = ["ETH", "BNB", "USDT"] as const
export type ITokenKey = typeof supportedCoingeckoTokens[number]

export type IPriceContextValue = {
	prices: Record<ITokenKey, number>,
	loaded: boolean
}

export interface IPriceContextFunctions {

}

const defaultValue: IPriceContextValue = {
	prices: {
		USDT: 1,
		ETH: 1,
		BNB: 1
	},
	loaded: false
}

const defaultFunctions: IPriceContextFunctions = {}

export const PriceContext = createContext<[IPriceContextValue, IPriceContextFunctions]>([
	defaultValue, defaultFunctions
])

export const PriceContextWrapper: Component = (props) => {
	const [ state, setState ] = createStore<IPriceContextValue>(defaultValue)

	const PriceValue: [IPriceContextValue, IPriceContextFunctions] = [
		state, defaultFunctions
	]

	const getPrices = async () => {
		const prices = await api.getPrices()
		localStorage.setItem("prices", JSON.stringify(prices))
		setState("prices", prices.reduce((acc, item) => ({...acc, [item.symbol.toUpperCase()]: Number.parseFloat(item.price)}), {} as Record<ITokenKey, number>))
		setState("loaded", true)
	}

	onMount(getPrices)
	createInterval(getPrices, 1000 * 60 )

	return (
		<PriceContext.Provider value={PriceValue}>
			{props.children}
		</PriceContext.Provider>
	)
}