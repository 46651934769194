import { For, JSX, Show, splitProps, useContext } from "solid-js"
import type { Component } from "~/types"

import clsx from "clsx"

import classes from "./ShareModal.module.css"
import Modal, { IModalProps } from "../Modal/Modal"
import { ShareContext, ShareItem, getShareText } from "~/context/ShareContext"
import { Link } from "@solidjs/router"
import OptimizedImage from "../OptimizedImage/OptimizedImage"
import TextStroke from "../TextStroke/TextStroke"
import { copyText } from "~/util"

import CopyIcon from "~/svg/copy-tall.svg?component-solid"
import Button from "../Button/Button"

const shareButtons: {label: string, iconSrc: string, getShareUrl: (item: ShareItem | null) => string}[] = [
	{
		label: "Facebook",
		iconSrc: "/img/facebook-col.svg",
		getShareUrl: (item) => {
			if (!item) return ""
			if (item.shareUrl?.startsWith("/")) item.shareUrl = import.meta.env.APP_HOST_URL + item.shareUrl
			return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(item.shareUrl ?? import.meta.env.APP_HOST_URL)}`
		}
	},
	{
		label: "Twitter",
		iconSrc: "/img/twitter-col.svg",
		getShareUrl: (item) => {
			if (!item) return ""
			if (item.shareUrl?.startsWith("/")) item.shareUrl = import.meta.env.APP_HOST_URL + item.shareUrl
			return `https://www.twitter.com/intent/tweet?url=${encodeURIComponent(item.shareUrl ?? import.meta.env.APP_HOST_URL)}&text=${encodeURIComponent(getShareText(item))}`
		}
	},
	{
		label: "Telegram",
		iconSrc: "/img/telegram-col.svg",
		getShareUrl: (item) => {
			if (!item) return ""
			if (item.shareUrl?.startsWith("/")) item.shareUrl = import.meta.env.APP_HOST_URL + item.shareUrl
			return `https://t.me/share/url?url=${encodeURIComponent(item.shareUrl ?? import.meta.env.APP_HOST_URL)}&text=${encodeURIComponent(getShareText(item))}`
		}
	},
	{
		label: "WhatsApp",
		iconSrc: "/img/whatsapp-col.svg",
		getShareUrl: (item) => {
			if (!item) return ""
			if (item.shareUrl?.startsWith("/")) item.shareUrl = import.meta.env.APP_HOST_URL + item.shareUrl
			return `https://api.whatsapp.com/send?url=${encodeURIComponent(item.shareUrl ?? import.meta.env.APP_HOST_URL)}&text=${encodeURIComponent(getShareText(item))}`
		}
	}
]

export type IShareModalProps = IModalProps

const ShareModal: Component<IShareModalProps> = (_props) => {
	const [ props, others ] = splitProps(_props, [])
	const [ shareData, shareFuncs ] = useContext(ShareContext)

	return (
		<Modal {...others} class={clsx(classes["modal"], others.class)}>
			<TextStroke component="h1" shadow class={classes["title"]}>{shareData.currentShare?.title}</TextStroke>
			<Show when={shareData.currentShare?.subheading}>
				<p class={classes["subheading"]}>{shareData.currentShare?.subheading}</p>
			</Show>
			<div class={classes["inner-box"]}>
				<button class={classes["share-text"]} onClick={() => shareData.currentShare && copyText(getShareText(shareData.currentShare))}>
					{Array.isArray(shareData.currentShare?.shareText)
						? (
						<For each={shareData.currentShare?.shareText}>
							{({text, lowercase}) => <>
								<Show when={lowercase}>
									<span class="lowercase">{text}</span>
								</Show>
								<Show when={!lowercase}>
									{text}
								</Show>
							</>}
						</For>
					) : shareData.currentShare?.shareText}
					<CopyIcon class={classes["copy-icon"]} />
				</button>
				<div class={classes["share-buttons"]}>
					<For each={shareButtons}>
						{(button) => (
							<Link
								href={button.getShareUrl(shareData.currentShare)}
								rel="noreferrer"
								aria-label={button.label}
								target="_blank"
								class={classes["share-button"]}
							>
								<OptimizedImage
									src={button.iconSrc}
									alt={button.label}
									width={30}
									height={30}
								/>
							</Link>
						)}
					</For>
				</div>
			</div>
			<div class={classes["copy-container"]}>
				<TextStroke strokeWidth={4} class={classes["copy-text"]}>{shareData.currentShare?.copyText}</TextStroke>
				<Button class={classes["copy-button"]} color="secondary" onClick={() => copyText(shareData.currentShare?.copyText ?? "")}>
					{shareData.currentShare?.copyLabel ?? "Copy Text"}
				</Button>
			</div>
		</Modal>
	)
}

export default ShareModal