import { DeepPartial, RelaxConst } from "../../types"

const base = {
	key: "base",
	label: "Light",
	type: "light",
	primary: {
		light: "#f2e872",
		main: "#fff579",
		dark: "#f2e872",
		contrastText: "#000"
	},
	secondary: {
		light: "#90cff5",
		main: "#7aafcf",
		dark: "#72a3c2",
		contrastText: "#000"
	},
	tertiary: {
		light: "#ff75b5",
		main: "#DE66A6",
		dark: "#d15f9b",
		contrastText: "#000"
	},
	action: {
		outline: "rgb(8, 144, 255)",
		hover: "rgba(0,0,0,0.125)",
		unselected: "#ccc",
		loadingFrom: "rgba(0,0,0,0.3)",
		loadingTo: "rgba(0,0,0,0.4)",
	},
	background: {
		default: "#fff",
		overlay: "rgba(0,0,0,0.625)"
	},
	success: {
		contrastText: "#000000",
		light: "#86cb53",
		main: "#6da544",
		dark: "#64983d",
		transparent: "#357a3866"
	},
	error: {
		contrastText: "#000",
		light: "#ff7e75",
		main: "#de6666",
		dark: "#ab4e4e",
		transparent: "#bb444466"
	},
	info: {
		contrastText: "#ffffff",
		light: "#4791db",
		main: "#1976d2",
		dark: "#115293",
		transparent: "#11529366"
	},
	warning: {
		contrastText: "#ffffff",
		light: "#ffb333",
		main: "#ffa000",
		dark: "#b27000",
		transparent: "#ffa00066"
	},
	text: {
		primary: "#000",
		secondary: "#646565"
	},
	font: {
		primary: "Might Makes Right Pro BB, Arial, sans-serif",
		heading: "Evangelion, Arial, sans-serif",
		lowercase: "SF Cartoonist Hand"
	},
	duration: {
		short: "200ms"
	},
	ease: {
		component: "ease-out"
	},
	shadow: {
		card: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
	},
	radius: {
		card: "8px",
		button: "8px",
		frame: "3rem"
	},
	component: {
		border: {
			default: "#444"
		},
		disabled: "#aaa",
		scrollbar: "#666",
		scrollbarThumb: "#999",
		scrollbarThumbHover: "#aaa",
		loading: "rgba(0,0,0,0.25)",
		loadingTo: "rgba(0,0,0,0.35)"
	},
	border: {
		divider: "rgba(0,0,0,0.4)",
		card: "#3b0022"
	},
	zindex: {
		navbar: 100,
		navOverlay: 101,
		modal: 110,
		dropdown: 120,
	},
} as const

export type Theme = RelaxConst<typeof base>
export type SubTheme = DeepPartial<Omit<Theme, "label" | "key">> & Pick<Theme, "label" | "key">

export default base