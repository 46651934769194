import type { Component } from "~/types"

import classes from "./ContactSection.module.css"
import Section from "~/components/Section"
import Heading from "~/components/Heading"
import Input from "~/components/Input"
import Select from "~/components/Select"
import FormLabel from "~/components/FormLabel"
import Button from "~/components/Button"
import { Show, createSignal, useContext } from "solid-js"
import OptimizedImage from "~/components/OptimizedImage/OptimizedImage"
import PhoneInput, { PhoneValue } from "~/components/PhoneInput/PhoneInput"
import toast from "solid-toast"
import { Web3Context } from "~/context/Web3Context"
import { isEmailValid } from "~/util/validation.util"
import { getApiErrorMessage, postLeads, useIsMobile } from "~/util"
import OptimizedVideo from "~/components/OptimizedVideo/OptimizedVideo"

const ContactSection: Component = () => {
	const [ name, setName ] = createSignal("")
	const [ email, setEmail ] = createSignal("")
	const [ phone, setPhone ] = createSignal<PhoneValue | null>(null)

	const timeContactOptions = [
		null,
		"Morning",
		"Afternoon",
		"Evening"
	] as const
	const [ preferredContactTime, setPreferredContactTime ] = createSignal<typeof timeContactOptions[number] | null>(null)

	const investmentAmountOptions = [
		null,
		"0-10k",
		"10k-50k",
		"50k-100k",
		"100k+"
	] as const
	const [ investmentAmount, setInvestmentAmount ] = createSignal<typeof investmentAmountOptions[number] | null>(null)

	const [ web3 ] = useContext(Web3Context)
	const [ detailsSending, setDetailsSending ] = createSignal(false)

	const onSubmit = async (e: Event) => {
		e.preventDefault()

		const data = {
			name: name(),
			email: email(),
			mobile: phone()?.number ? `${phone()?.country.dialCode} ${phone()?.number}` : null,
			preferred_contact_time: preferredContactTime()?.toLowerCase() ?? undefined,
			investment_amount: investmentAmount() ?? undefined,
			wallet_address: web3.account ?? undefined
		}
		if (!data.name) return toast.error("Name is required")
		if (!data.email) return toast.error("Email is required")
		if (!isEmailValid(data.email)) return toast.error("Email is invalid")
		if (!data.mobile) return toast.error("Phone number is required")
		if (!data.preferred_contact_time) return toast.error("Contact time is required")
		if (!data.investment_amount) return toast.error("Investment amount is required")

		setDetailsSending(true)
		try {
			await postLeads(data)
			toast.success("Successfully submitted details")
		} catch(e) {
			toast.error(getApiErrorMessage(e, "Failed to submit details"))
		}
		setDetailsSending(false)
	}

	const isMobile = useIsMobile()

	return (
		<Section
			class={classes["section"]}
			innerClass={classes["section-inner"]}
			id="contact-section"
			outerChildren={
				<>
					<Show when={!isMobile()}>
						<OptimizedImage
							src="/img/grainy-background.png"
							aria-hidden
							alt="Grainy background"
							width={1440}
							height={980}
							class={classes["bg-video"]}
						/>
						<OptimizedImage
							src="/img/beamed-grainy-background.png"
							aria-hidden
							alt="Beamed grainy background"
							width={1362}
							height={990}
							class={classes["bg-video"]}
						/>
					</Show>
					<Show when={isMobile()}>
						<OptimizedImage
							src="/img/grainy-background-cropped-mobile.png"
							aria-hidden
							alt="Grainy background"
							width={400}
							height={644}
							class={classes["bg-video"]}
						/>
						<OptimizedImage
							src="/img/beamed-grainy-background-centered.png"
							aria-hidden
							alt="Beamed grainy background"
							width={640}
							height={739}
							class={classes["bg-video"]}
						/>
					</Show>
				</>
			}
		>
			<div class={classes["contact-container"]}>
				<Heading
					class={classes["heading-container"]}
					topLabel="Contact us"
					heading="Let's Chat!"
					headingColor="primary"
					bottomLabel={<>
						Got a burning question, a brilliant idea or need help investing?<br />
						Reach out and make your voice heard in the uprising!
					</>}
				/>
				<form class={classes["contact-form"]} onSubmit={onSubmit}>
					<FormLabel label="Full Name*" for="contact-full-name">
						<Input
							id="contact-full-name"
							placeholder="E.G John Doe"
							autocomplete="name"
							autocapitalize="words"
							value={name()}
							onInput={(e) => setName(e.currentTarget.value)}
						/>
					</FormLabel>
					<FormLabel label="Email*" for="contact-full-name">
						<Input
							id="contact-email"
							placeholder="E.G John.Doe@gmail.com"
							autocomplete="email"
							value={email()}
							onInput={(e) => setEmail(e.currentTarget.value)}
						/>
					</FormLabel>
					<FormLabel label="Phone Number*" for="contact-phone">
						<PhoneInput
							id="contact-phone"
							placeholder="07872 972832"
							value={phone()}
							onChange={(val) => setPhone(val)}
						/>
					</FormLabel>
					<FormLabel label="Preferred Contact Time*" for="contact-preferred-contact-time">
						<Select
							id="contact-preferred-contact-time"
							placeholder="Preferred contact time"
							value={preferredContactTime()}
							options={timeContactOptions}
							onChange={(val) => setPreferredContactTime(val)}
							buttonValueFormatter={(val) => val === null ? "Unspecified" : val}
						/>
					</FormLabel>
					<FormLabel label="Investment Amount*" for="contact-investment-amount">
						<Select
							id="contact-investment-amount"
							placeholder="Investment amount"
							value={investmentAmount()}
							onChange={(val) => setInvestmentAmount(val)}
							options={investmentAmountOptions}
							buttonValueFormatter={(val) => val === null ? "Unspecified" : val}
						/>
					</FormLabel>
					<Button
						class={classes["submit-button"]}
						type="submit"
						disabled={detailsSending()}
					>
						Submit
					</Button>
				</form>
			</div>
			<OptimizedImage
				src="/img/elon-doge-mask.png"
				alt="Elon Musk wearing a doge mask"
				class={classes["elon-img"]}
				width={1500}
				height={1597}
				hasSizes
			/>
		</Section>
	)
}

export default ContactSection