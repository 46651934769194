import { JSX, Show, splitProps } from "solid-js"
import type { ChildrenType, Component } from "~/types"

import clsx from "clsx"

import classes from "./InputBase.module.css"


export type IInputBaseProps = JSX.HTMLAttributes<HTMLDivElement> & {
	outerChildren?: ChildrenType,
}

const InputBase: Component<IInputBaseProps> = (_props) => {
	const [ props, others ] = splitProps(_props, ["children", "outerChildren"])

	return (
		<div {...others} class={clsx(classes["input-base"], others.class)}>
			<Show when={props.outerChildren}>
				<div class={classes["outer-children"]}>{props.outerChildren}</div>
			</Show>
			{props.children}
		</div>
	)
}

export default InputBase