import { createSignal, onMount } from "solid-js"

import { createEventListener, getThemeStyleCSS, useCSSVariable } from "../util";
import themes from "./themes";

import "./css"
import { Component } from "../types";

const CSSBaseline: Component = () => {
	const [ themeStyle, setThemeStyle ] = createSignal(getThemeStyleCSS(themes.base))
	const [ windowSize, setWindowSize ] = createSignal({width: window.innerWidth, height: window.innerHeight})
	createEventListener(window, "resize", () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight
		})
	})
	useCSSVariable("vh", () => `${windowSize().height / 100}px`)
	useCSSVariable("screen-height", () => `${windowSize().height}px`)

	return (
		<style>
			{themeStyle()}
		</style>
	)
}

export default CSSBaseline;