import { Show, splitProps } from "solid-js"
import { Title, Link, Meta } from "@solidjs/meta"
import { website } from "~/constants"
import { ChildrenType, ComponentType, GetPropsFromComponent } from "~/types"
import { Dynamic } from "solid-js/web"

interface IDefaultPageProps {
	path: string,
	title?: string,
	description?: string,
	keywords?: string[],
	structuredData?: string,
	children?: ChildrenType
}

export type IPageProps<
	Comp extends ComponentType
> = Omit<GetPropsFromComponent<Comp>, "component"> & IDefaultPageProps & {
	component?: Comp
}

const Page = <Comp extends ComponentType = "main">(_props: IPageProps<Comp>) => {
	const [ props, others ] = splitProps(_props, [
		"path", "title", "description",
		"keywords", "structuredData", "component",
		"children"
	])

	return (
		<>
			<Link rel="canonical">{import.meta.env.APP_HOST_URL + props.path}</Link>
			<Title>{props.title ? `${props.title} · ${website.title}` : website.title}</Title>
			<Meta name="description" content={props.description ?? website.description} />
			<Meta name="keywords" content={[...(props.keywords || []), ...website.keywords].join(",")} />
			<Show when={props.structuredData !== undefined}>
				<script type="application/ld+json" innerHTML={props.structuredData} />
			</Show>
			<Dynamic component={props.component ?? "main"} {...others}>
				{props.children}
			</Dynamic>
		</>
	)
}

export default Page