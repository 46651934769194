import type { Component } from "~/types"

import classes from "./TokenomicsSection.module.css"
import Section from "~/components/Section/Section"
import OptimizedImage from "~/components/OptimizedImage/OptimizedImage"
import TextStroke from "~/components/TextStroke/TextStroke"
import { JSX, Show, createSignal, splitProps } from "solid-js"
import clsx from "clsx"
import { useBounds, useCSSVariable, useIsMobile, useWindowWidth } from "~/util"
import Heading from "~/components/Heading/Heading"

const TokenomicsSection: Component = () => {
	const isMobile = useIsMobile()
	const width = useWindowWidth()

	return (
		<Section class={classes["section"]} noInner id="tokenomics-section">
			<OptimizedImage
				class={classes["background"]}
				src={width() <= 800 ? "/img/tokenomics-background-cropped.jpg" : "/img/tokenomics-background.jpg"}
				alt="A decorative background"
				hasSizes
				aria-hidden
				width={1424}
				height={1602}
			/>
			<Heading
				topLabel={isMobile() ? undefined : "The war chest"}
				heading="Tokenomics"
				bottomLabel="Doge Uprising's $DUP, An ERC20 blockchain token, is the key to our revolution"
				headingColor="primary"
				class={classes["heading-container"]}
			/>
			<div class={classes["img-grid"]}>
				<TokenomicsItem
					imgSrc={isMobile() ? "/img/tokenomics-bg-1-mobile.jpg" : "/img/tokenomics-bg-1.jpg"}
					imgAlt="A doge mech spreading its arms ready to fight"
					imgWidth={isMobile() ? 301 : 1512}
					imgHeight={isMobile() ? 237 : 451}
					topLabel="presale"
					percentage={60}
					numMilTokens={270}
					bubbleType={isMobile() ? "spiky" : "round"}
				/>
				<TokenomicsItem
					imgSrc="/img/tokenomics-bg-2.jpg"
					imgAlt="A decorative background"
					imgWidth={590}
					imgHeight={521}
					topLabel="Exchanges"
					percentage={20}
					numMilTokens={90}
					bubbleType="spiky"
				/>
				<TokenomicsItem
					imgSrc="/img/tokenomics-bg-3.jpg"
					imgAlt="A decorative background"
					imgWidth={492}
					imgHeight={435}
					topLabel="Exchanges"
					percentage={5}
					numMilTokens={22.5}
					bubbleType={"spiky"}
				/>
				<TokenomicsItem
					imgSrc="/img/tokenomics-bg-4.jpg"
					imgAlt="A decorative background"
					imgWidth={491}
					imgHeight={435}
					topLabel="Development"
					percentage={5}
					numMilTokens={22.5}
					bubbleType="spiky"
				/>
				<TokenomicsItem
					imgSrc={isMobile() ? "/img/tokenomics-bg-5-mobile.jpg" : "/img/tokenomics-bg-5.jpg"}
					imgAlt="A decorative background"
					imgWidth={isMobile() ? 301 : 1511}
					imgHeight={isMobile() ? 237 : 451}
					topLabel="Airdrops"
					percentage={10}
					numMilTokens={45}
					bubbleType={isMobile() ? "spiky" : "round"}
				>
					<Show when={!isMobile()}>
						<div class={classes["bottom-mech-container"]}>
							<OptimizedImage
								class={classes["bottom-mech"]}
								src="/img/doge-mecha-1.png"
								alt="A doge mecha"
								width={864}
								height={1189}
							/>
						</div>
					</Show>
				</TokenomicsItem>
			</div>
		</Section>
	)
}

export default TokenomicsSection

export type ITokenomicsItemProps = JSX.HTMLAttributes<HTMLDivElement> & {
	imgSrc: string
	imgAlt: string,
	imgWidth: number,
	imgHeight: number,
	percentage: number,
	topLabel: string,
	numMilTokens: number,
	bubbleType: "spiky" | "round-square" | "round"
}

const bubbleTypeMap: Record<ITokenomicsItemProps["bubbleType"], string> = {
	"spiky": "/img/spiky-bubble-2.svg",
	"round": "/img/round-bubble.png",
	"round-square": "/img/round-square-bubble.svg"
}

const bubbleSizeMap: Record<ITokenomicsItemProps["bubbleType"], [number, number]> = {
	"spiky": [337, 278],
	"round": [620, 419],
	"round-square": [489, 253]
}

export const TokenomicsItem: Component<ITokenomicsItemProps> = (_props) => {
	const [ props, others ] = splitProps(_props, [
		"imgSrc", "imgAlt", "percentage",
		"topLabel", "numMilTokens", "bubbleType",
		"children", "imgWidth", "imgHeight"
	])
	const [ containerRef, setContainerRef ] = createSignal<HTMLDivElement | null>(null)
	const containerBounds = useBounds(containerRef)
	useCSSVariable("h-pc", () => `${containerBounds().height / 100}px`)
	return (
		<div
			{...others}
			class={clsx(classes["img-item"], others.class)}
			ref={(el) => setContainerRef(el)}
		>
			<OptimizedImage
				alt={props.imgAlt}
				src={props.imgSrc}
				class={classes["img-bg"]}
				width={props.imgWidth}
				height={props.imgHeight}
			/>
			{props.children}
			<div class={clsx(classes["bubble-container"], classes[`type-${props.bubbleType}`])}>
				<OptimizedImage
					src={bubbleTypeMap[props.bubbleType]}
					alt="A speech bubble"
					class={classes["bubble-img"]}
					width={bubbleSizeMap[props.bubbleType][0]}
					height={bubbleSizeMap[props.bubbleType][1]}
				/>
				<div class={classes["bubble-text"]}>
					<p class={classes["bubble-top-label"]}>{props.topLabel}</p>
					<p class={classes["bubble-percentage"]}>{props.percentage}%</p>
					<p class={classes["bubble-bottom-label"]}>{props.numMilTokens} Billion $DUP</p>
				</div>
			</div>
		</div>
	)
}