import { For, JSX, Show, createEffect, createSignal, mergeProps, splitProps } from "solid-js"
import type { Component } from "~/types"

import clsx from "clsx"

import classes from "./Select.module.css"
import InputBase, { IInputBaseProps } from "../InputBase"

import ChevronDown from "~/svg/chevron-down.svg?component-solid"
import Dropdown from "../Dropdown"
import Button from "../Button/Button"
import { useClickAway } from "~/util"

import TickIcon from "~/svg/tick-circle.svg?component-solid"

export type ISelectProps<Value> = Omit<IInputBaseProps, "onChange"> & ({
	multiple: true,
	value: readonly Value[],
	onChange?: (value: Value[]) => void,
	valueEquator?: (a: Readonly<Value>, list: Readonly<Value[]>) => boolean,
} | {
	multiple?: false,
	value: Value,
	onChange?: (value: Value) => void,
	valueEquator?: (a: Readonly<Value>, b: Readonly<Value>) => boolean,
}) & {
	valueFormatter?: (value: Readonly<Exclude<Value, null | undefined>>) => JSX.Element | string,
	buttonValueFormatter?: (value: Readonly<Exclude<Value, null | undefined>>) => JSX.Element | string
	placeholder?: string
	options: readonly Value[],
}

const Select = <Value,>(__props: ISelectProps<Value>) => {
	const _props = mergeProps({
		multiple: false,
		valueFormatter: (val: Value) => {
			if (val !== undefined && val !== null) return val.toString()
			return ""
		},
		valueEquator: (a: Readonly<Value>, b: Readonly<Value>) => a === b
	}, __props)

	const [ props, others ] = splitProps(_props, [
		"multiple", "value", "onChange",
		"valueFormatter", "buttonValueFormatter", "placeholder",
		"options", "valueEquator"
	])

	const [ menuOpen, setMenuOpen ] = createSignal(false)

	const hasValue = () => {
		if (Array.isArray(props.value)) return props.value.length > 0
		return props.value !== undefined && props.value !== null
	}

	const [ rootRef, setRootRef ] = createSignal<HTMLDivElement | null>(null)

	useClickAway(rootRef, () => setMenuOpen(false))

	return (
		<InputBase
			{...others}
			class={clsx(
				classes["select"],
				others.class,
				{[classes["open"]]: menuOpen()}
			)}
			onClick={(e) => {
				setMenuOpen((open) => !open)
			}}
			ref={(el) => setRootRef(el)}
		>
			<div class={classes["select-value-container"]}>
				<Show when={hasValue()}>
					{Array.isArray(props.value)
						? props.value.map(props.valueFormatter).join(", ")
						: props.valueFormatter(props.value as any)}
				</Show>
				<Show when={!hasValue()}>
					<p class={classes["placeholder"]}>{props.placeholder}</p>
				</Show>
			</div>
			<ChevronDown class={classes["down-icon"]} />
			<Dropdown
				containerRef={rootRef()}
				open={menuOpen()}
				onClose={() => setMenuOpen(false)}
				class={classes["dropdown"]}
			>
				<For each={props.options}>
					{(option) => (
						<Button
							variant="styless"
							class={classes["dropdown-item"]}
							onClick={(e) => {
								e.stopPropagation()
								setMenuOpen(false)
								props.onChange?.(
									(Array.isArray(props.value)
										? props.value.includes(option)
											? [...props.value, option]
											: props.value.filter((val) => val !== option)
										: option) as (Value & Value[])
								)
							}}
						>
							{(props.buttonValueFormatter ?? props.valueFormatter)(option as any)}
							<Show when={(props.valueEquator as any)(option, props.value)}>
								<TickIcon class={classes["dropdown-item-tick"]} />
							</Show>
						</Button>
					)}
				</For>
			</Dropdown>
		</InputBase>
	)
}

export default Select